/*
  Rise.jsx
  @Component - Rise Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './Rise.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/Rise/square-four.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Rise/square-five.jpg'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/Rise/square-three.png'),
    background_size: 'cover',
  }

]

const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/Rise/square-one.png'),
    width:'25%',
    borderRadius: '32px',
    className:'special-stat-image'
  },
  {
    id: 2,
    image: cdn('/Case_studies/Rise/square-two.png'),
    width:'30%',
    borderRadius: '32px',
    className:'special-stat-image'
  },
  {
    id: 3,
    image: cdn('/Case_studies/Rise/rise-purple-campaign-ad.jpeg'),
    width:'25%',
    borderRadius: '32px',
    className:'special-stat-image'
  },

];


export default class Rise extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="rise-wrapper">
      <MetaTags>
        <title>Rise - Internet Service Provider - OKMG</title>
        <meta name="description" content="Rise - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/lever-property'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="RISE" role="heading"  
        sub_content="Digital | Creative | Marketing"
        service_info="RISE is a premium internet service provider operating in the Philippines. With operations in Cebu and Manila, RISE provides a superior service offering however required marketing to boost the brand’s profile within the market. "
        // image={cdn('/Case_studies/Rise/IPHONE-MOCKUP.png')}
        hover_video_one={cdn('/Case_studies/Rise/RiseLanding-rz.mp4')}
        hover_videobg_one={cdn('/websiteScrolls/iMac_Mockup_v2.png')}
        // hover_image_one={cdn('/Case_studies/Rise/191209_RISE_EMAIL+HEADER_GIF.gif')}
        // hover_image_two={cdn('/websiteScrolls/iPhone+X+Mockup.png')}
        // hover_videobg_two={cdn('/websiteScrolls/iPhone+X+Mockup.png')}
        image_width="105%"
        foot_image={cdn('/Case_studies/Rise/RISE_Illustration-1.svg')}
        main_image_position_right="-50px"
        foot_image_width='200px'
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>RISE offers high end internet connection within the Philippines, however they were competing against several well established ISP companies who had ownership over most of the market. However an advantageous market opening presented an opportunity, due to the somewhat lackluster internet connectivity and low customer satisfaction amongst the major telcos offerings there was room for an internet provider to take the lead.</p>
            <p>Engaged to structure a marketing strategy, OKMG created a brand identity that would be instantly recognisable within the market. Launched over various digital platforms and reinforced by working closely with the RISE marketing and sales team, we were able to establish their presence as a leading internet provider within the community.</p>
             <ComponentReadMore
             content={
              <span>
                <p>OKMG was approached to develop a digital marketing strategy that connected businesses with the best enterprise internet service provider in the Philippines, RISE.</p>
                <p>Several challenges emerged throughout this project requiring strategic pivoting on our part. The main challenges being, the current internet infrastructure in Philippines being relatively low causing customer dissatisfaction, competing against several well established companies within the market and lastly COVID-19 Pandemic which severely reduced network and sales teams in reaching potential clients due to quarantine.</p>
                <p>We tailored our marketing strategy to target each of these areas. Creating a brand identity for RISE that would create a strong and distinct voice, standing out from competitors.  We developed the messaging and tone of voice to have maximum impact by engaging with the community, ensuring that it was aspirational and uplifting. </p>
                <p>Whilst RISE focused primarily on enterprise business connections, there was significant interest in their services from the residential sector. This required an active lead pipeline setup through HubSpot CRM automation, and by updating the lead forms throughout both social and web campaigns to better qualify incoming leads.</p>
                <p>From our omni-channel marketing strategy we delivered digital campaigns across Google, Facebook and Email, securing RISE a strong customer base. Our account management team continues to work closely with RISE’s marketing and sales teams to optimise conversions and continually refine the customer experience; including training for our digital platforms including Hubspot CRM tools and HIVO digital asset management.</p>
                <p>Through OKMG’s engagement with RISE, they were successfully able to attain a position amongst the Phillipine’s top internet providers. </p>
              </span>
            }
             />
          </div>}
        />

           {/* <ComponentFullBleedImage
          image={cdn('/Case_studies/Rise/ALL PLANS-HORIZONTAL_ALL+PLANS+-+HORIZONTAL_ALL+PLANS+-+HORIZONTAL.png')}
          width="100%"
          className="special-img-vid"
          marginTop="0px"
          marginTop="100px"
          /> */}
          <div class="special-vid">
          <ComponentVideo
          video_url={cdn('/Case_studies/Rise/AnimatedTiles-rz-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          className="special-vid"
          marginTop="100px"
          />
          </div>

          <ComponentVideo
          video_url={cdn('/Case_studies/Rise/rise-animation-video.min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
          <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="100px"
          />

           <ComponentFullBleedImage
          image={cdn('/Case_studies/Rise/square-six.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="150px"
          />
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}