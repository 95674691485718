/*
  HBF.jsx
  @Component - HBF Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './HBF.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/HBF/[www.hbf.com.au][140]girl-in-tutu-standing-on-dads-toes.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/HBF/[www.hbf.com.au][677]covid-homepage-promo.jpg'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/HBF/HBF-Hero-Image.png'),
    background_size: 'cover',
  }

]


const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/HBF/HBF-1.gif'),
    width:'30%',
    className:'bouncing-teeth'
  },
  {
    id: 2,
    image: cdn('/Case_studies/HBF/HBF-Infographic .png'),
    width:'45%',
    borderRadius: '32px',
    className:'special'
  },
  

];

const images_phones = [
   {
    id: 1,
    image: cdn('/Case_studies/HBF/hbf-gif-1.gif'),
    width:'33.33%',
    className:'phone-gif'
  },
  {
    id: 2,
    image: cdn('/Case_studies/HBF/hbf-gif-2.gif'),
    width:'33.33%',
    className:'phone-gif'
  },
   {
    id: 3,
    image: cdn('/Case_studies/HBF/hbf-gif-3.gif'),
    width:'33.33%',
    className:'phone-gif'
  },

];



export default class HBF extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="HBF-wrapper">
      <MetaTags>
        <title>HBF - Flu Season  - OKMG</title>
        <meta name="description" content="HBF - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/hbf'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="HBF" role="heading" 
        sub_content="Creative"
        service_info="With flu season round the corner, OKMG were engaged to help educate HBF members on how to avoid illness and explain the intricacies of their health insurance."
        image={cdn('/Case_studies/HBF/hbf-iphones.jpg')}
        image_width="110%"
        className="special"
        image_class="header-image"
        foot_image_width="350px"
        foot_image={cdn('/Case_studies/HBF/HBF_Logo asset.svg')}
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>The objective was to create digital content that resonated with members during flu season. As content around the flu often involves negative messaging, we instead opted for a more entertaining yet still educational, placing HBF as a more progressive health provider.</p>
            <p>We designed a series of engaging branded animations that used relatable human behaviours, encouraging our audience to learn more about their cover. This messaging was supported through simple and informative infographics, empowering HBF members to make the most of their health insurance.</p>
            <p>Through the creative and engaging content created we successfully distributed these pieces across numerous digital platforms. The content covered areas from Flu Season, Scooter Safety to Kids Dental Limits.</p>
            <p>This content provided for a far more relatable and effective format of distributing health content to a broader audience, as consumers actively enquired about membership cover in social media comments prompted by educational content.</p>
          </div>}
        />

         <ComponentFullBleedImage
          image={cdn('/Case_studies/HBF/Scooter Saftey - Ipad.gif')}
          width="80%"
          marginTop="0px"
          marginTop="100px"
          />  

          <ComponentCaseImageGifs 
          stats={images_phones}
          special_class_wrapper="gif-phone-wrapper"
          marginTop="100px"
          />

          <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="100px"
          />

          <ComponentVideo
          video_url={cdn('/Case_studies/HBF/tooth-rz-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
           <ComponentFullBleedImage
          image={cdn('/Case_studies/HBF/HBF_FLYER MOCKUP-min.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />
  
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}