/*
  RevoFitnessGymSimple.jsx
  @Component - RevoFitnessGymSimple Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './RevoFitnessGymSimple.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';


// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/RevoFitnessGymSimple/revo-box1.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/RevoFitnessGymSimple/revo-box2.png'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/RevoFitnessGymSimple/revo-box3.png'),
    background_size: 'cover',
  }

]

const images_gifs = [
  {
   id: 1,
   image: cdn('/Case_studies/RevoFitnessGymSimple/revo_v2_gif1.gif'),
   width:'30%',
 },
 {
   id: 2,
   image: cdn('/Case_studies/RevoFitnessGymSimple/revo_v2_gif2.gif'),
   width:'30%',
 }

]

export default class ScotchCollege extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="RevoFitnessGymSimple-wrapper twin-fin-wrapper">
      <MetaTags>
        <title>Revo Fitness Gym Simple - OKMG</title>
        <meta name="description" content="Founded in 1897, Scotch College is one of Australia's leading independent private schools." />
        <link rel='canonical' href='https://okmg.com/work/scotch-college'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Revo Fitness" role="heading"
        sub_content="Creative | Strategy | Marketing"
        service_info="2021 was shaping up to be a massive year of growth for Revo. To capture the brand’s evolution and current position, Revo required a strategic-led brand campaign to establish their new direction and welcome new members."
        hover_video_one={cdn('/Case_studies/RevoFitnessGymSimple/revo-homepage-cast-web-v2.mp4')}
        hover_videobg_one={cdn('/websiteScrolls/iMac_Mockup_v2.png')}

        // foot_image={cdn('/Case_studies/FunkyMonkey/funky_monkey_small_logo.png')}
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>The gym industry is renowned for hidden fees and sneaky lock-in tactics. Revo, however, achieved the impossible; Making the gym simple. </p>
            <p>Having amassed a loyal following, the Revo team wanted to ensure that they didn't lose touch with their existing members, especially with a significant year of growth ahead of them. We know that Revo has managed to grow exponentially and retain members through their simple, no BS membership - OKMG wanted to capture the power of one membership in their new brand campaign. </p>

            <ComponentReadMore
             content={
               <span>
                <p>The signup process is often the most strenuous workout you'll have when joining the gym; Unless your gym is Revo Fitness. Conveying the simplicity of the Revo membership was the core objective of this campaign. Where other gyms hide their agenda in small print and surprise fees, Revo keeps it simple - when we say no catches we mean it, full stop. </p>
                <p>We teased out the full stop, hero'ing it as we refined the Revo visual language. This brand element reflected their flexible offering and the confident yet jovial direction of the Revo brand.</p>
                <p>Working collaboratively with the Revo team, we created a high energy video to walk existing and prospective members through the Revo promise. We incorporated animation with live-action footage, reflective of social media culture, aligning with the online behaviour of the Revo audience. The Revo brand message was reinforced across radio, using this as an opportunity to create an ongoing, bantery type dialogue between Revo and their audience. </p>
                <p>When launching this brand campaign, we ensured that this didn't feel like introducing a new brand but instead a reminder that the Revo membership remains the most affordable, accessible membership. So whether this was your first time hearing of Revo or being a ride or die member from way back, you would clearly understand the Revo ethos.</p>
                <p>The brand campaign reestablished Revo's position as the gym made simple, across WA. </p>
              </span>
            }
             />

          </div>}
        />

        <ComponentFullBleedImage
          image={cdn('/Case_studies/RevoFitnessGymSimple/revo_design_palette.png')}
          marginTop="100px"
          width="100%"
          />

        <ComponentVideo
          video_url={cdn('/Case_studies/RevoFitnessGymSimple/REVO_GYM SIMPLE_WIDE_EDIT V1-V9-web.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/RevoFitnessGymSimple/revo_mobiles.png')}
          marginTop="100px"
          width="80%"
          special_image={cdn('/Case_studies/RevoFitnessGymSimple/revo-fade-img.png')}
          special_image2={cdn('/Case_studies/RevoFitnessGymSimple/revo-red-circle.png')}
          special_class_wrapper="revo-bg-imgs"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/RevoFitnessGymSimple/REVO_BOOKLET MOCKUP 1.png')}
          marginTop="100px"
          width="100%"
          />

          <ComponentCaseImageGifs 
            stats={images_gifs}
            marginTop="60px"
            width="80%"
            className="gif-wrap"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/RevoFitnessGymSimple/REVO_DL FLYER MOCKUP 1.png')}
          marginTop="100px"
          width="90%"
          />


          <ComponentFullBleedImage
          image={cdn('/Case_studies/RevoFitnessGymSimple/revo-fbleed-image.png')}
          marginTop="100px"
          width="100%"
          />
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}