/*
  RevoFitness.jsx
  @Component - Revo Fitness Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './FunkyMonkey.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]


// for images
const boximages = [
   {
    id: 1,
    image: cdn('/Case_studies/FunkyMonkey/funkymonkey_box_image1.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/FunkyMonkey/funkymonkey_box_image2.png'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/FunkyMonkey/funkymonkey_box_image3.png'),
    background_size: 'cover',
  }

]
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/FunkyMonkey/funky_monkey_box_image_1.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/FunkyMonkey/funky_monkey_box_image_2.png'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/FunkyMonkey/funky_monkey_box_image_3.png'),
    background_size: 'cover',
  }

]


export default class HoneyForLife extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="FunkyMonkey-wrapper twin-fin-wrapper">
      <MetaTags>
        <title>Funky Monkey - OKMG</title>
        <meta name="description" content="Funky Monkey Bars have established their position as the go-to equipment for kids who are wild for fitness and gymnastics." />
        <link rel='canonical' href='https://okmg.com/work/funky-monkey'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Funky Monkey Bars" role="heading"
        sub_content="Creative | Digital | Marketing"
        service_info="Funky Monkey Bars, an Australian owned company, has revolutionised the iconic playground favourite. Leading the way in backyard fun with their modular equipment, Funky Monkey Bars were looking for a campaign that would demonstrate the power of a child's imagination, setting them up to be the number one choice when it comes to backyard equipment."
        image={cdn('/Case_studies/FunkyMonkey/funky_monkey_logo.png')}
        image_class="header-image"
        image_width="85%"
        foot_image={cdn('/Case_studies/FunkyMonkey/funky_monkey_small_logo.png')}
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>Funky Monkey Bars have established their position as the go-to equipment for kids who are wild for fitness and gymnastics. Leading into the Spring and Summer season, they required a campaign that showed parents that children of all ages and skill levels could enjoy the outdoor equipment, to wider their existing market. OKMG was engaged in developing and implementing a campaign to get kids off the couch and into their backyards.</p>
            <p>We launched the campaign 'Find Your Amazing' positioning Funky Monkey Bars as the ultimate way to unlock your child's imagination so they can create their own world and experiences.</p>
            <p>We were challenged with targeting two audience groups: those unfamiliar with Funky Monkey Bars and those who knew them but were yet to commit to purchasing the equipment. Splitting our campaign into two varied directions: ' Welcome to the Jungle', introducing parents and children to the Funky Monkey Bars product range and the second 'Level Up', focusing on the add-on accessories so you can grow your equipment along with your children's skills.</p>
            <p>Our production team captured the vision of these two concepts over a two-day shoot and brought the wild world of Funky Monkey Bars to life through animation. Launched as a social media campaign, including a Funky Monkey Bar social filter and challenge, we showed the people of Australia the possibility of a child's imagination, from lava to snakes, snapping crocodiles and more.</p>
          </div>}
        />

        {/* <ComponentVideo
          video_url={cdn('/Case_studies/FunkyMonkey/OKMG_FUNKYMONKEY_CASE_Study_edit_v1.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          /> */}

        <ComponentCaseImageGifs 
          stats={boximages}
          marginTop="60px"
          width="80%"
          className="gif-wrap"
          // special_class_wrapper="special-wrapper"
          // special_image={cdn('/Case_studies/BusseltonSwim/MASTER-LOGO-01-2.png')}
        />

        <ComponentVideo
          video_url={cdn('/Case_studies/FunkyMonkey/FUNKY_level Up_30sec_EDIT_V1-web.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/FunkyMonkey/funky_monkey_full_image.png')}
          marginTop="100px"
          width="100%"
          />
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}