/*
  PLS.jsx
  @Component - PLS Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './PLS.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';
import Reveal from 'react-reveal/Fade';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';


// for images
const header_images = [
   {
    id: 1,
    image: cdn('/Case_studies/PLS/pls-pad1.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/PLS/pls-pad2.png'),
    background_size: 'cover',
  }

]
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/PLS/pls-box1.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/PLS/pls-box2.png'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/PLS/pls-box3.png'),
    background_size: 'cover',
  }

]

const images_gifs = [
  {
   id: 1,
   image: cdn('/Case_studies/RFDS/rec-1-gifs.gif'),
   width:'30%',
 },
 {
   id: 2,
   image: cdn('/Case_studies/RFDS/rec-2-gifs.gif'),
   width:'30%',
 }

]

export default class ScotchCollege extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="PLS-wrapper twin-fin-wrapper">
      <MetaTags>
        <title>Pilbara Minerals - OKMG</title>
        <meta name="description" content="Pilbara Minerals is paving the way for a sustainable energy future through their low-cost lithium production." />
        <link rel='canonical' href='https://okmg.com/work/pilbara-minerals'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Pilbara Minerals" role="heading"
        sub_content="Creative | Digital"
        service_info="Pilbara Minerals is paving the way for a sustainable energy future through their low-cost lithium production. Pilbara Minerals approached OKMG to revitalise their brand identity to create a presence that accurately reflects their progressive position within the energy and resources industry."
        images={header_images}
        image_class="header-image"
        image_width="100%"

        foot_image={cdn('/Case_studies/PLS/pls-side.png')}
        />
        <Reveal up>
        <ComponentCaseCopy
        content={
          <div>
            <p>After successfully rebranding Pilbara Minerals' visual identity to align with their mission statement, 'Powering a Sustainable Energy Future', OKMG was engaged to redesign their website with the same approach. The new website would showcase the refined Pilbara Minerals visual language as a primary tool in communicating their ethos and vision to stakeholders, prospective partners, current and potential employees and the broader community. </p>
            <p>We conducted a comprehensive UX audit resulting in an expansive development of wireframes before starting the design to ensure we communicated to each audience group.</p>
            <p>The clean full-width design was strategically conceptualised with human interaction at its core, designed to work across desktop and mobile devices, facilitating specific user journeys. This created a digital platform that allowed the audience to easily find information specific to their requirements, whether they were looking for investor information, potential employment opportunities, or anything in-between. Our developers also embraced the challenge of integrating both an employment portal from a third-party and styling a live share-price (ASX) widget.</p>
            <p>The refined brand identity was applied throughout the website, creating a consistent visual language accompanied by the environmental colour palette and layered graphic device, creating depth, especially when coupled with carefully crafted parallax animation. To reinforce the refined graphic device, we applied high impact imagery covering the range of Pilbara Mineral's workflow, from the Head Office in Perth to the primary Pilgangoora Pilbara mine site.</p>
            <p>We shifted the core brand assets away from the outdated mining perceptions, allowing for a contemporary brand identity and digital platform representing the business' focus on a clean energy future. The finished website is one the team at OKMG is very proud of and grateful for our relationship with one of Australia's leading sustainable energy companies. </p>
          </div>}
        />

        </Reveal>
        
          <ComponentFullBleedImage
          image={cdn('/Case_studies/PLS/pls-logoblock.png')}
          marginTop="100px"
          width="80%"
          className="logo-block"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/PLS/pls-palette.png')}
          marginTop="100px"
          width="90%"
          className="palette"
          />

          <ComponentVideo
            video_url={cdn('/Case_studies/PLS/webscroll-rz.mp4')}
            autoplay="autoplay"
            loop="loop"
            marginTop="100px"
            autoplay="autoplay"
            loop="loop"
            marginTop="100px"
            controls="true"
            controlsoff
            screenCast
          />
          
          {/* <ComponentFullBleedImage
          image={cdn('/Case_studies/PLS/pls-brochure.png')}
          marginTop="100px"
          width="100%"
          className="big-box"
          /> */}

          <ComponentFullBleedImage
          image={cdn('/Case_studies/PLS/pls-brochure2.png')}
          marginTop="100px"
          width="100%"
          className="big-box"
          />

          {/* <ComponentFullBleedImage
          image={cdn('/Case_studies/PLS/pls-big-box.png')}
          marginTop="100px"
          width="100%"
          className="big-box"
          /> */}
          
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />

          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}