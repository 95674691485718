/*
  ComponentVideo.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentVideo.scss';
import Reveal from 'react-reveal/Reveal';
import LazyLoad from 'react-lazyload';
// images


export default class ComponentVideo extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      autoplayer: true,
      videoHide: false,
      videoShrink: false,
      videoBlow: false
    };
  }
  componentDidMount(){
    let vid =  document.getElementsByTagName('video');
    if(window.innerWidth < 600){
      this.setState({autoplayer: !this.state.autoplayer})
    }
  }

  hideVideo(){
    this.setState({
      videoHide:!this.state.videoHide
    })
  }

  videoShrinker(){
    this.setState({
      videoShrink:!this.state.videoShrink
    })
  }

  videoExpand(){
    this.setState({
      videoBlow:!this.state.videoBlow
    })
  }




  render(){

   const {
      heading,
      video_url,
      autoplay,
      loop,
      marginTop,
      controlsoff,
      className,
      screenCast,
      frameImg
      } = this.props;

  return (


      <div className={screenCast ? 'component-video-wrap screen-cast' : 'component-video-wrap' } style={{marginTop: marginTop}}>
      <span className={this.state.videoHide ? 'video-hide' : 'video-show'}>
      <span className={this.state.videoShrink ? 'video-min' : 'video-min-show'}>
      <span className={this.state.videoBlow ? 'video-blow' : 'video-show'}>
        <h3>{heading}</h3>

          {this.state.autoplayer ? 
            <LazyLoad offset={100}>
            {screenCast ? <div className="laptop-display">
              <div>
                <span className="cross-off" onClick={this.hideVideo.bind(this)}></span>
                <span className="minimise" onClick={this.videoShrinker.bind(this)}></span>
                <span className="expand" onClick={this.videoExpand.bind(this)}></span>
              </div>
              </div>: ""}
              {frameImg ? 
                <div className="header-video">
                  <div className="inner-video">
                    <video width="100%" autoplay={autoplay} loop={loop} muted controls style={{pointerEvents: controlsoff ? 'none' : ''}} className={className}>
                    <source src={video_url} type="video/mp4"/>
                    Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className="video-bg">
                    <img src={frameImg} alt="" style={{width:"100%", height:"100%" }}/>
                  </div>
                </div>
              : 
                <video width="100%" autoplay={autoplay} loop={loop} muted controls style={{pointerEvents: controlsoff ? 'none' : ''}} className={className}>
                <source src={video_url} type="video/mp4"/>
                Your browser does not support the video tag.
                </video>
              }
            </LazyLoad>
           : 
           <LazyLoad offset={100}>
           {screenCast ? <div className="laptop-display">
              <div>
                <span></span>
                <span></span>
                <span></span>
              </div>
              </div>: ""}
            <video width="100%" loop={loop} controls className={className}>
              <source src={video_url} type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          </LazyLoad>

        }
          </span>
          </span>
          </span>
      </div>
    );
  }
}