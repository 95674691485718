/*
  ComponentHeaderBanner.jsx
  @component used for case studies
*/

import React, { Component } from 'react';  
import TextLoop from "react-text-loop";
import './ComponentHeaderBanner.scss';
import BlobOne from '../../../blobOne';
import ComponentSplitSpan from '../../../ComponentSplitSpan';
import Plx from 'react-plx';

const parallaxDataBodyContentOne = [
  {
    start: 0,
    end: 500,
    properties: [
    {
        startValue: 0,
        endValue: 60,
        property: "translateY"
      },

    ],
  },
]

const parallaxDataHeader = [
  {
    start: 0,
    end: 900,
    properties: [
    {
        startValue: 1,
        endValue: 0.1,
        property: "opacity"
      },

    ],
  },
]

export default class ComponentHeaderBanner extends React.Component {


    constructor(props) {
    super(props);
    // State.

    this.state = {

    };
  }


render(){

  const {
      heading,
      sub_content,
      service_info,
      intro_para,
      video,
      hover_video_one,
      hover_videobg_one,
      hover_videobg_two,
      hover_video_two,
      image,
      image_width,
      foot_video,
      foot_image,
      foot_image_width,
      hover_image_one,
      hover_image_two,
      hover_image_three,
      className,
      images,
      main_image_position_right,
      main_image_position_top,
      foot_image_left,
      byline,
      image_icon
   } = this.props;

  //  let displayStyle1 = { backgroundImage: `url(${hover_videobg_one})`}
  //  let displayStyle2 = { backgroundImage: `url(${hover_videobg_two})`}

  return (


     <div className="component-header-inner-case">
      <div className="sub sub-one">
        <Plx
         parallaxData={parallaxDataHeader}
         className="sub-one-sub one"
         >
          <div className='sub-one-sub one'>
            <h1>
              <ComponentSplitSpan
              copy={heading} role="heading"
              />
              {byline ? <span className="byline">{byline}</span> : ""}
            </h1>
            <p>{sub_content}</p>
            <p className="service-info">{service_info}</p>
             {foot_image ?
              <img src={foot_image} alt="header foot image" className="foot-image sux" style={{maxWidth:foot_image_width,width:'100%',left:foot_image_left}} />
              :
              ''
            }
             {foot_video ?
              <video className="inner-video foot-image sux" muted="muted" autoplay="autoplay" loop="loop">
                <source src={foot_video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              :
              ''
            }
          </div>
         
        </Plx>
      </div>
      <Plx
       parallaxData={parallaxDataBodyContentOne}
       className="parallax-one sux"
       >
       <div className="sub-wrap">
        {video ? 
          <video className="header-video" muted="muted" autoplay="autoplay" loop="loop">
            <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
          </video>
          : 
          ''
        }
        {hover_video_one ?
          <div className="header-video" >
            <div className="inner-video">
              <video  muted="muted" autoplay="autoplay" loop="loop">
                <source src={hover_video_one} type="video/mp4" />
              Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-bg">
              <img src={hover_videobg_one} alt="" style={{width:"100%", height:"100%" }}/>
            </div>
          </div> 
          : 
          ''
        }
        {hover_video_two ? 
          <div className="header-video">
            <div className="inner-video">
              <video muted="muted" autoplay="autoplay" loop="loop">
                <source src={hover_video_two} type="video/mp4" />
              Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-bg">
              <img src={hover_videobg_two} alt="" style={{width:"100%", height:"100%" }}/>
            </div>
          </div>
          : 
          ''
        }
        {image_icon && image ? 
          <div className="image-with-icon">
            <img src={image_icon} alt="header image icon" className="header-image-icon"/>
          {image ? 
            <img src={image} alt="header image" className="header-image" style={{width:image_width,right: main_image_position_right,top:main_image_position_top}}/>
            : 
            ''
          }
          </div>
        : ''}
        {image && !image_icon ? 
          <img src={image} alt="header image" className="header-image" style={{width:image_width,right: main_image_position_right,top:main_image_position_top}}/>
          : 
          ''
        }
        { hover_image_one ? 

            <img src={hover_image_one} alt="header image"  style={{width:image_width}} className={className + " header-image hover-back"}/>
          : ""
        }
        { hover_image_two ? 
            <img src={hover_image_two} alt="header image" style={{width:image_width}} className={className + " header-image hover-front"}/>

          : ""
        }
        { hover_image_three ? 
            <img src={hover_image_three} alt="header image" style={{width:image_width}} className={className + " header-image hover-front"}/>

          : ""
        }
        { images ? 
          <div className="multi-image-wrap">
            {
              images.map(images =>
                <img src={images.image} style={{width:images.width}} className={images.className}/>
              )
            }
          </div>

          : ""}
          </div>
      </Plx>
     </div>
    );
  }
}