/*
  CTF.jsx
  @Component - CTF Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './CTF.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/CTF/2018.02.10-CTF-SHOOT-6-web.jpeg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/CTF/2018.02.10-CTF-SHOOT-24-web.jpeg'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/CTF/2018.02.10-CTF-SHOOT-27-web.jpeg'),
    background_size: 'cover',
  }

]


const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/CTF/300-exhibits.png'),
    width:'calc(50% - 30px)',
  },
  {
    id: 2,
    image: cdn('/Case_studies/CTF/2017.11.24-CTF-VR-STUDENT-DAY-15-1-scaled.jpg'),
    width:'calc(50% - 30px)',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/CTF/2017.11.24-CTF-VR-STUDENT-DAY-71-4-scaled.jpg'),
    width:'calc(50% - 30px)',
    borderRadius: '32px',
    className:'special'
  },

{
    id: 4,
    image: cdn('/Case_studies/CTF/48-videos.png'),
    width:'calc(50% - 30px)',
    borderRadius: '32px',
  },

];

const images_three = [
   {
    id: 1,
    image: cdn('/Case_studies/DDC/DDC_LOGO-2-300x154.png'),
    // width:'calc(50% - 30px)',
  },
  {
    id: 2,
    image: cdn('/Case_studies/CTF/hungry sky logo square.png'),
    // width:'calc(50% - 30px)',
    // borderRadius: '32px',
    // className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/CTF/ctf_color.png'),
    // width:'calc(50% - 30px)',
    // borderRadius: '32px',
    // className:'special'
  },

];


export default class CTF extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="CTF-wrapper">
      <MetaTags>
        <title>Construction Futures Centre - Creating a skilled Western Australian workforce  - OKMG</title>
        <meta name="description" content="Construction Training Fund - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/construction-training-fund'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Construction Futures Centre" role="heading"  
        sub_content="Strategy | Creative"
        service_info="Building and construction is one of WA’s largest industries, yet outdated perceptions of a career in construction has deterred young Australian’s from pursuing an apprenticeship."
        image={cdn('/Case_studies/CTF/ctf-ipad.jpg')}
        foot_image={cdn('/Case_studies/CTF/CTF-Device-copy-black-2.png')}
        className="special"
        image_class="header-image"

        foot_image_left="0px"
        />
         <ComponentCaseCopy
         marginTop="100px"
        content={
          <div>
            <p>To address the shortcomings in apprenticeship information and elevate the industry as a whole, The Construction Training Fund began a new initiative: Construction Futures Centre, Australia’s first interactive industry exhibition centre engaging OKMG to produce aspirational content.</p>
            <p>Whilst our key objective was to encourage young adults to pursue a career in construction, it became clear that students rarely have the information required to consider an apprenticeship. Conscious of the younger audience, the content created had to be engaging and relevant but also enticing; revealing the opportunities that come with a career in construction. </p>
            <p>Combining narrative with user experience, OKMG created content that was exciting, whilst providing actionable career information to challenge the perceptions of trade and attracting the next generation of construction in Western Australia.</p>
             <ComponentReadMore
             content={
              <span>
                  <p>As the lead agency contract for the 12 month project, OKMG were tasked to create an immersive experience that exposed students to a multitude of career opportunities, showcasing how construction is set to shape WA’s future. </p>
                  <p>Following an intensive 8 week research and discovery process, the OKMG team put on their hardhats and spent 6 months immersed in the WA construction scene. We connected with the state's most iconic tradespeople, spent time on site with local apprentices and gained exposure to cutting edge-technology putting Perth innovation on the world map. Travelling across WA, we captured content that reflected the true diversity of the industry to weave into a broader narrative about the past, present and future of building and construction.</p>
                  <p>We worked to inspire the next generation of apprentices by creating a space that truly reflects the diversity of opportunity and innovation of WA’s most important industry. Across 48 unique exhibits, we leveraged VR, AR, animation, gamification, film and photography to retell the story of the industry. We focused on self-driven learning, encouraging each visitor to create their own journey through the centre and explore a new perspective of a future in building and construction.</p>
              </span>
            }
             />
          </div>}
        />

        <ComponentVideo
          video_url={cdn('/Case_studies/CTF/ctf-video-one-min-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
          <ComponentFullBleedImage
          image={cdn('/Case_studies/CTF/CTF-DL-Flyer-min.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />
       <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="100px"
          width="60%"
          special_class_wrapper="special-wrapper"
          special_image={cdn('/Case_studies/CTF/2017.10.12_CTF-Video-Device-crop.png')}
          />
          
          {/* This is the animation */}
          {/* <ComponentVideo
          video_url={cdn('/Case_studies/CTF/Enclave-Completion-Video-web-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          /> */}


          <div class="logo-section">
            
           <ComponentCaseImageGifs 
          stats={images_three}
          className="client-logos"
            />
          <div class="logo-right-wrapper">
              <img class="special-wrap" src={cdn('/Case_studies/CTF/2017.10.12_CTF-Video-Device-crop.png')} alt=""/>
            </div>
          </div>

     
          <ComponentFullBleedImage
          image={cdn('/Case_studies/CTF/2018.02.10-CTF-SHOOT-34-web.jpeg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />
  
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}