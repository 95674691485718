import cdn from '../lib/cdn';
import RevoFitness from '../Components/_CaseStudies/RevoFitness';
import IGA from '../Components/_CaseStudies/IGA';
import TwinFin from '../Components/_CaseStudies/TwinFin';
import HIVO from '../Components/_CaseStudies/HIVO';
import HairSupplies from '../Components/_CaseStudies/HairSupplies';
import CottesloeVillage from '../Components/_CaseStudies/CottesloeVillage';
import PilbaraMinerals from '../Components/_CaseStudies/PilbaraMinerals';
import LeverProperty from '../Components/_CaseStudies/LeverProperty';
import PrideWa from '../Components/_CaseStudies/PrideWa';
import Cicerellos from '../Components/_CaseStudies/Cicerellos';
import RevoFitnessInnaloo from '../Components/_CaseStudies/RevoFitnessInnaloo';
import PerthBrainCenter from '../Components/_CaseStudies/PerthBrainCenter';
import ECC from '../Components/_CaseStudies/ECC';
import Rise from '../Components/_CaseStudies/Rise';
import CurtinUni from '../Components/_CaseStudies/CurtinUni';
import MKDC from '../Components/_CaseStudies/MKDC';
import HBF from '../Components/_CaseStudies/HBF';
import WAAC from '../Components/_CaseStudies/WAAC';
import SlipStream from '../Components/_CaseStudies/SlipStreamCyber';
import StirlingCapital from '../Components/_CaseStudies/StirlingCapital';
import BlackBurne from '../Components/_CaseStudies/BlackBurne';
import CTF from '../Components/_CaseStudies/CTF';
import Woodside from '../Components/_CaseStudies/Woodside';
import StudyPerth from '../Components/_CaseStudies/StudyPerth';
import PYC from '../Components/_CaseStudies/PYC';
import SourceEnergy from '../Components/_CaseStudies/SourceEnergy';
import Primero from '../Components/_CaseStudies/Primero';
import DDC from '../Components/_CaseStudies/DDC';
import PerthFestival from '../Components/_CaseStudies/PerthFestival';
import BusseltonSwim from '../Components/_CaseStudies/BusseltonSwim';
import UWASport from '../Components/_CaseStudies/UWASport';
import HoneyForLife from '../Components/_CaseStudies/HoneyForLife';
import FoodiesMarket from '../Components/_CaseStudies/FoodiesMarket';
import MurdochUni from '../Components/_CaseStudies/MurdochUni';
import RonFarris from '../Components/_CaseStudies/RonFarris';
import TheFast800 from '../Components/_CaseStudies/TheFast800';
import HunterCapital from '../Components/_CaseStudies/HunterCapital';
import CapitalPropertyAdvisory from '../Components/_CaseStudies/CapitalPropertyAdvisory';
import ChaseResidential from '../Components/_CaseStudies/ChaseResidential';
import DistinguishedHomes from '../Components/_CaseStudies/DistinguishedHomes';
import CicerellosSeaCritters from '../Components/_CaseStudies/CicerellosSeaCritters';
import CicerellosScentOfSummer from '../Components/_CaseStudies/CicerellosScentOfSummer';
import BoomersProtein from '../Components/_CaseStudies/BoomersProtein';
import CocoonLuxury from '../Components/_CaseStudies/CocoonLuxury';
import AjDiamond from '../Components/_CaseStudies/AjDiamond';
import Farmers from '../Components/_CaseStudies/Farmers';
import Majime from '../Components/_CaseStudies/Majime';
import HeartFoundation from '../Components/_CaseStudies/HeartFoundation';
import Dimattina from '../Components/_CaseStudies/Dimattina';
import FunkyMonkey from '../Components/_CaseStudies/FunkyMonkey';
import ScotchCollege from '../Components/_CaseStudies/ScotchCollege';
import WiTi from '../Components/_CaseStudies/WiTi';
import Ceri from '../Components/_CaseStudies/Ceri';
import RFDS from '../Components/_CaseStudies/RFDS';
import Femma from '../Components/_CaseStudies/Femma';
import Goldfields from '../Components/_CaseStudies/Goldfields';
import PLS from '../Components/_CaseStudies/PLS';
import ChickenTreat from '../Components/_CaseStudies/ChickenTreat';
import RevoFitnessGymSimple from '../Components/_CaseStudies/RevoFitnessGymSimple';
import CurtinUniDestinationAustralia from '../Components/_CaseStudies/CurtinUniDestinationAustralia';
import CurtinGoGreater from '../Components/_CaseStudies/CurtinGoGreater';
import Fast800 from '../Components/_CaseStudies/Fast800';
import BusinessEventsPerth from '../Components/_CaseStudies/BusinessEventsPerth';
const base_url_image = 'https://okmg-web-assets-rc.s3-ap-southeast-2.amazonaws.com/';

export default  [
	{
		id: 48,
				slug: 'business-events-perth',
				image: cdn('/Case_studies/bep/bep-header-banner.jpg'),
				hover_image_one: cdn('/Case_studies/bep/bep-lower-banner.png'),
				hover_image_two: cdn('/Case_studies/bep/bep-footer-image-one.jpg'),
				hover_image_three: cdn('/Case_studies/bep/bep-footer-image-two.jpg'),
				hover_image_four: cdn('/Case_studies/bep/bep-footer-image-one.jpg'),
				color: '#f148FB',
				client: 'Business Events Perth',
				by_line: '',
				logo_width: '200px',
				category: 'community',
				component: BusinessEventsPerth
	},
	{
		id: 46,
				slug: 'curtin-university-go-greater',
				image: cdn('/Case_studies/curtinGoGreater/go-greater-three.jpg'),
				hover_image_one: cdn('/Case_studies/curtinGoGreater/go-greater-two.jpg'),
				hover_image_two: cdn('/Case_studies/curtinGoGreater/go-greater-full-bleed-two.jpg'),
				hover_image_three: cdn('/Case_studies/curtinGoGreater/go-greater-one.jpg'),
				hover_image_four: cdn('/Case_studies/curtinGoGreater/go-greater-full-bleed-one.jpg'),
				color: '#f148FB',
				client: 'Curtin Go Greater',
				by_line: '',
				logo_width: '200px',
				category: 'education',
				component: CurtinGoGreater
	},
	{
		id: 45,
				slug: 'curtin-university-destination-austraia',
				image: cdn('/Case_studies/CurtinDiscover/curtin-discover-bottom-banner-left.jpg'),
				hover_image_one: cdn('/Case_studies/CurtinDiscover/curtin-discover-bottom-banner-middle.jpg'),
				hover_image_two: cdn('/Case_studies/CurtinDiscover/curtin-bottom-banner-right.jpg'),
				hover_image_three: cdn('/Case_studies/CurtinDiscover/curtin-box-2.jpg'),
				hover_image_four: cdn('/Case_studies/CurtinDiscover/curtin-box.jpg'),
				color: '#f148FB',
				client: 'Curtin University',
				by_line: '',
				logo_width: '200px',
				category: 'education',
				component: CurtinUniDestinationAustralia
	},
	{
		id: 44,
				slug: 'revo-gym-simple',
				logo: cdn('/Case_studies/RevoFitnessGymSimple/'),
				image: cdn('/Case_studies/RevoFitnessGymSimple/REVO-GYM-SIMPLE.png'),
				hover_image_one: cdn('/Case_studies/RevoFitnessGymSimple/revo-box1.png'),
				hover_image_two: cdn('/Case_studies/RevoFitnessGymSimple/REVO_BOOKLET MOCKUP 1.png'),
				hover_image_three: cdn('/Case_studies/RevoFitnessGymSimple/revo-box2.png'),
				hover_image_four: cdn('/Case_studies/RevoFitnessGymSimple/revo-box3.png'),
				color: '#f148FB',
				client: 'Revo Fitness',
				by_line: '',
				logo_width: '200px',
				category: 'health',
				component: RevoFitnessGymSimple
	},
	{
		id: 43,
		slug: 'woodside',
		image: cdn('/init_hover/woodside-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/Woodside/wood-hover-2.jpg'),
		hover_image_two: cdn('/Case_studies/Woodside/wood-hover-3.jpg'),
		hover_image_three: cdn('/Case_studies/Woodside/wood-hover-4.jpg'),
		hover_image_four: cdn('/Case_studies/Woodside/wood-hover-5.jpg'),
		color: '#f148FB',
		client: 'Woodside',
		by_line: 'Australia’s Largest Natural Gas Producer',
		category: 'resources',
		component: Woodside
	},
	{
		id: 42,
				slug: 'royal-flying-doctor-service',
				logo: cdn('/Case_studies/RFDS/hover-rfds-logo.png'),
				image: cdn('/Case_studies/RFDS/rfds-v2.png'),
				hover_image_one: cdn('/Case_studies/RFDS/rfds-box-big.png'),
				hover_image_two: cdn('/Case_studies/Ceri/ceri_box_img2.png'),
				hover_image_three: cdn('/Case_studies/Ceri/ceri_box_img3.png'),
				hover_image_four: cdn('/Case_studies/Ceri/ceri_full_image.png'),
				color: '#f148FB',
				client: 'Royal Flying Doctor Service',
				by_line: 'Every moment counts',
				logo_width: '200px',
				category: 'health',
				component: RFDS
	},
	{
		id: 41,
				slug: 'fast-800-product-shop-videos',
				image: cdn('/Case_studies/Fast800/800-bottom-full-bleed.png'),
				hover_image_one: cdn('/Case_studies/Fast800/800-low-carb.jpg'),
				hover_image_two: cdn('/Case_studies/Fast800/800-200-calories.jpg'),
				hover_image_three: cdn('/Case_studies/Fast800/800-header-banner.jpg'),
				hover_image_four: cdn('/Case_studies/Fast800/800-bottom-full-bleed.png'),
				color: '#f148FB',
				client: 'The Fast 800',
				by_line: '',
				logo_width: '200px',
				category: 'community',
				component: Fast800
	},
	{
		id: 40,
		slug: 'the-fast-800',
		image: cdn('/Case_studies/TheFast800/fast800.png'),
		hover_image_one: cdn('/Case_studies/TheFast800/the-fast800-small-image-two.jpg'),
		hover_image_two: cdn('/Case_studies/TheFast800/the-fast800-image-three.jpg'),
		hover_image_three: cdn('/Case_studies/TheFast800/the-fast-800-main-image.jpg'),
		hover_image_four: cdn('/Case_studies/TheFast800/Fast800_Programme_Mockup-webv3.png'),
		color: '#f148FB',
		client: 'The Fast 800',
		by_line: 'Healthy Habits',
		category: 'ecommerce',
		component: TheFast800
	},
	{
		id: 39,
				slug: 'scotch-college',
				logo: cdn('/Case_studies/ScotchCollege/'),
				image: cdn('/Case_studies/ScotchCollege/SCOTCH2.png'),
				hover_image_one: cdn('/Case_studies/ScotchCollege/scotch_box_img1.png'),
				hover_image_two: cdn('/Case_studies/ScotchCollege/scotch_full_bleed.png'),
				hover_image_three: cdn('/Case_studies/ScotchCollege/scotch_box_img2.png'),
				hover_image_four: cdn('/Case_studies/ScotchCollege/scotch_box_img3.png'),
				color: '#f148FB',
				client: 'Scotch College',
				by_line: '',
				logo_width: '200px',
				category: 'education',
				component: ScotchCollege
	},
	{
		id: 41,
				slug: 'majime',
				logo: cdn('/Case_studies/Majime/MAJIME-CONCIERGE-FRAME1-web.jpg'),
				image: cdn('/Case_studies/Majime/Majime_Case_Study-webv2.png'),
				hover_image_one: cdn('/Case_studies/Majime/MAJIME CONCIERGE_STATIONERY-web.jpeg'),
				hover_image_two: cdn('/Case_studies/Majime/MAJIME_PHONE MOCKUP-2 1-web.jpeg'),
				hover_image_three: cdn('/Case_studies/Majime/Frame 5 (1)-web.jpeg'),
				hover_image_four: cdn('/Case_studies/Majime/majime-logo-whitebg.png'),
				color: '#f148FB',
				client: 'Majime',
				by_line: '',
				logo_width: '200px',
				category: 'property',
				component: Majime
	},
	{
		id: 38,
				slug: 'witi',
				logo: cdn('/Case_studies/WiTi/'),
				image: cdn('/Case_studies/WiTi/WITI.png'),
				hover_image_one: cdn('/Case_studies/WiTi/Anti_theft_product.png'),
				hover_image_two: cdn('/Case_studies/WiTi/witi_box_image_1.png'),
				hover_image_three: cdn('/Case_studies/WiTi/WITI_FLYER.png'),
				hover_image_four: cdn('/Case_studies/WiTi/witi_box_image_3.png'),
				color: '#f148FB',
				client: 'WiTi',
				by_line: '',
				logo_width: '200px',
				category: 'retail',
				component: WiTi
	},
	{
		id: 37,
				slug: 'funky-monkey',
				logo: cdn('/Case_studies/FunkyMonkey/'),
				image: cdn('/Case_studies/FunkyMonkey/FUNKY-MONKEY2.png'),
				hover_image_one: cdn('/Case_studies/FunkyMonkey/funky_monkey_logo.png'),
				hover_image_two: cdn('/Case_studies/FunkyMonkey/funkymonkey_box_image1.png'),
				hover_image_three: cdn('/Case_studies/FunkyMonkey/funkymonkey_box_image2.png'),
				hover_image_four: cdn('/Case_studies/FunkyMonkey/funkymonkey_box_image3.png'),
				color: '#f148FB',
				client: 'Funky Monkey Bars',
				by_line: '',
				logo_width: '200px',
				category: 'retail',
				component: FunkyMonkey
	},
	{
		id: 36,
				slug: 'cravable-brands',
				logo: cdn('/Case_studies/ChickenTreat/'),
				image: cdn('/Case_studies/ChickenTreat/chicken_treat_hover.png'),
				hover_image_one: cdn('/Case_studies/ChickenTreat/ct_img_big.png'),
				hover_image_two: cdn('/Case_studies/ChickenTreat/ct_img_box1.png'),
				hover_image_three: cdn('/Case_studies/ChickenTreat/ct_img_box2.png'),
				hover_image_four: cdn('/Case_studies/ChickenTreat/ct_img_box3.png'),
				color: '#f148FB',
				client: 'Cravable Brands',
				by_line: '',
				logo_width: '200px',
				category: 'hospitality',
				component: ChickenTreat
	},
	{
		id: 35,
				slug: 'ceri',
				logo: cdn('/Case_studies/Ceri/'),
				image: cdn('/Case_studies/Ceri/ceri-v2.png'),
				hover_image_one: cdn('/Case_studies/Ceri/ceri_box_img1.png'),
				hover_image_two: cdn('/Case_studies/Ceri/ceri_box_img2.png'),
				hover_image_three: cdn('/Case_studies/Ceri/ceri_box_img3.png'),
				hover_image_four: cdn('/Case_studies/Ceri/ceri_full_image.png'),
				color: '#f148FB',
				client: 'CERI',
				by_line: '',
				logo_width: '200px',
				category: 'health',
				component: Ceri
	},
	
	{
		id: 34,
				slug: 'femma',
				logo: cdn('/Case_studies/Femma/hover-laptop.png'),
				image: cdn('/Case_studies/Femma/femma-v2.png'),
				hover_image_one: cdn('/Case_studies/Femma/femma-box3.png'),
				hover_image_two: cdn('/Case_studies/Femma/femma-box1.png'),
				hover_image_three: cdn('/Case_studies/Femma/femma-box2.png'),
				hover_image_four: cdn('/Case_studies/Femma/femma-side.png'),
				color: '#f148FB',
				client: 'Femma',
				by_line: 'Digital women\'s healthcare service',
				logo_width: '200px',
				category: 'health',
				component: Femma
	},
	{
		id: 49,
				slug: 'goldfields',
				logo: cdn('/Case_studies/Goldfields/oasis-logo.png'),
				image: cdn('/Case_studies/Goldfields/oasis.png'),
				hover_image_one: cdn('/Case_studies/Goldfields/oasis-cards.png'),
				hover_image_two: cdn('/Case_studies/Goldfields/oasis-box1.png'),
				hover_image_three: cdn('/Case_studies/Goldfields/oasis-box2.png'),
				hover_image_four: cdn('/Case_studies/Goldfields/oasis-box3.png'),
				color: '#f148FB',
				client: 'Goldfields Oasis',
				by_line: '',
				logo_width: '200px',
				category: 'health',
				component: Goldfields
	},
	{
		id: 33,
				slug: 'pilbara-minerals',
				logo: cdn('/Case_studies/PLS/pls-logoblock.png'),
				image: cdn('/Case_studies/PLS/pls-v2.png'),
				hover_image_one: cdn('/Case_studies/PLS/pls-brochure2.png'),
				hover_image_two: cdn('/Case_studies/PLS/pls-box1.png'),
				hover_image_three: cdn('/Case_studies/PLS/pls-box2.png'),
				hover_image_four: cdn('/Case_studies/PLS/pls-box3.png'),
				color: '#f148FB',
				client: 'Pilbara Minerals',
				by_line: 'Sustainable energy future',
				logo_width: '200px',
				category: 'resources',
				component: PLS
	},
	{
		id: 32,
		slug: 'revo-fitness',
		logo: cdn('revo-etched.png'),
		image: cdn('/init_hover/revo-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/Revo_fitness/20201006-REVO-OConner-PT&Clients-11-web.jpeg'),
		hover_image_two: cdn('/Case_studies/Revo_fitness/20201006-REVO-OConner-PT&Clients-5-web.jpeg'),
		hover_image_three: cdn('/Case_studies/Revo_fitness/REVO Cockburn Fitout_High_Res-(23 of 33)-web-min.jpeg'),
		hover_image_four: cdn('/Case_studies/Revo_fitness/REVO-Cockburn-Fitout_High-Res-(16 of 33)-web.jpeg'),
		color: '#333',
		client: 'Revo Fitness',
		by_line: 'Own Your Fit',
		category: 'health_care',
		logo_width: '500px',
		component: RevoFitness
	},
	{
		id: 31,
		slug: 'hivo',
		logo: cdn('/Case_studies/HIVO/hivo-logo.svg'),
		image: cdn('/init_hover/hivo-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/HIVO/hivo-hover-banner-2.jpeg'),
		hover_image_two: cdn('/Case_studies/HIVO/hivo-hover-banner-3.jpeg'),
		hover_image_three: cdn('/Case_studies/HIVO/hivo-hover-banner-4.jpeg'),
		hover_image_four: cdn('/Case_studies/HIVO/hivo-hover-banner-5.jpeg'),
		color: '#e8e8e8',
		client: 'HIVO',
		by_line: 'Digital Asset Management',
		category: 'technology',
		logo_width: '200px',
		component: HIVO
	},
	 {
	 	id: 28,
	 	slug: 'perth-festival',
	 	image: cdn('/init_hover/perthfestival-hover-oner.jpeg'),
	 	hover_image_one: cdn('/Case_studies/PerthFestival/Screen-Shot-2021-03-09-at-7.51.32-pm.png'),
	 	hover_image_two: cdn('/Case_studies/PerthFestival/Screen-Shot-2021-03-09-at-7.50.42-pm.png'),
	 	hover_image_three: cdn('/Case_studies/PerthFestival/Screen-Shot-2021-03-09-at-7.51.45-pm.png'),
	 	hover_image_four: cdn('/Case_studies/PerthFestival/Screen-Shot-2021-03-09-at-7.52.35-pm.png'),
	 	color: '#f148FB',
	 	client: 'Perth Festival',
	 	by_line: 'Australia\'s Longest Running Cultural Festival',
	 	logo_width: '200px',
	 	category: 'community',
	 	component: PerthFestival
	 },
	{
		id: 30,
		slug: 'honey-for-life',
		image: cdn('/Case_studies/HoneyForLife/HONEY-FOR-LIFE-FRAME1-web.jpg'),
		hover_image_one: cdn('/Case_studies/HoneyForLife/honey-for-life-thumb-image-one.jpg'),
		hover_image_two: cdn('/Case_studies/HoneyForLife/honey-for-life-thumb-image-two.jpg'),
		hover_image_three: cdn('/Case_studies/HoneyForLife/honey-for-life-image-three.jpg'),
		hover_image_four: cdn('/Case_studies/HoneyForLife/honey-for-life-crumb.jpg'),
		color: '#f148FB',
		client: 'Honey For Life',
		by_line: 'Make Life Sweeter',
		logo_width: '200px',
		category: 'ecommerce',
		component: HoneyForLife
	},
	
	{
		id: 29,
		slug: 'edith-cowan-college',
		image: cdn('/init_hover/ecc-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/ECC/2020 STUDY PERTH FRINGE-50-web-web.jpeg'),
		hover_image_two: cdn('/Case_studies/ECC/2018.04.27-ECC-Perth-City-Shoot-1-of-13-web.jpeg'),
		hover_image_three: cdn('/Case_studies/ECC/2018.02.21-ECC-on-set-photos-31-of-83-web.jpeg'),
		hover_image_four: cdn('/Case_studies/ECC/2018.02.21-ECC-on-set-photos-25-of-83-web.jpeg'),
		color: '#f148FB',
		client: 'Edith Cowan College',
		by_line: 'Your Direct Pathway to ECU',
		category: 'education',
		component: ECC
	},
	{
		id: 28,
		slug: 'rise',
		image: cdn('/init_hover/rise-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/Rise/3rd-Thumbnail-web.jpeg'),
		hover_image_two: cdn('/Case_studies/Rise/5IdeasToUpskill-Header-08282020-web.jpeg'),
		hover_image_three: cdn('/Case_studies/Rise/BuildinganISP-Header-08042020-2X-web.jpeg'),
		hover_image_four: cdn('/Case_studies/Rise/Troubleshooting-Thumbnail-09212020-web.jpeg'),
		color: '#f148FB',
		client: 'RISE',
		by_line: 'Internet Service Provider',
		category: 'telecommunications',
		component: Rise
	},
	{
		id: 27,
		slug: 'curtin-university-virtual-explorer',
		image: cdn('/init_hover/curtin-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/CurtinUni/Curtin VC Render Test 200002-web.jpeg'),
		hover_image_two: cdn('/Case_studies/CurtinUni/Curtin VC Render Test 200003-web.jpeg'),
		hover_image_three: cdn('/Case_studies/CurtinUni/Curtin VC Render Test 200006-web.jpeg'),
		hover_image_four: cdn('/Case_studies/CurtinUni/Curtin VC Render Test 200007-web.jpeg'),
		color: '#f148FB',
		client: 'Curtin University',
		by_line: 'Virtual Explorer',
		category: 'education',
		component: CurtinUni
	},
	{
		id: 26,
		slug: 'mkdc',
		image: cdn('/init_hover/mkdc-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/MKDC/hover-mkdc-two.jpg'),
		hover_image_two: cdn('/Case_studies/MKDC/hover-mkdc-three.jpg'),
		hover_image_three: cdn('/Case_studies/MKDC/hover-mkdc-four.jpg'),
		hover_image_four: cdn('/Case_studies/MKDC/hover-mkdc-five.jpeg'),
		color: '#f148FB',
		client: 'MKDC',
		by_line: 'Interior Design & Aged Care Specialists',
		category: 'property',
		component: MKDC
	},
	{
		id: 25,
		slug: 'study-perth',
		image: cdn('/init_hover/study-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/StudyPerth/st-hover-2.jpg'),
		hover_image_two: cdn('/Case_studies/StudyPerth/st-hover-3.jpg'),
		hover_image_three: cdn('/Case_studies/StudyPerth/st-hover-4.jpg'),
		hover_image_four: cdn('/Case_studies/StudyPerth/st-hover-5.jpg'),
		color: '#f148FB',
		client: 'StudyPerth',
		by_line: 'Your Guide to Education in Perth',
		category: 'education',
		component: StudyPerth
	},
	{
		id: 24,
		slug: 'iga',
		image: cdn('/init_hover/iga-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/IGA/bloom ig ads11-web.jpeg'),
		hover_image_two: cdn('/Case_studies/IGA/iga-img-4@2x.png'),
		hover_image_three: cdn('/Case_studies/IGA/iga-hover-2.jpeg'),
		hover_image_four: cdn('/Case_studies/IGA/pexels-vanessa-loring-5972003-web.jpeg'),
		color: '#eee0ab',
		client: 'IGA',
		by_line: 'Shop Online With IGA',
		category: 'hospitality',
		logo_width: '300px',
		component: IGA
	},
	 {
	 	id: 34,
	 	slug: 'ron-farris-real-estate',
	 	image: cdn('/Case_studies/RonFarris/RON-FARRIS-FRAME1-web.jpg'),
	 	hover_image_one: cdn('/Case_studies/RonFarris/ron-farris-small-image-two.jpg'),
	 	hover_image_two: cdn('/Case_studies/RonFarris/ron-farris-small-image-three.jpg'),
	 	hover_image_three: cdn('/Case_studies/RonFarris/ron-farris-header-image.jpg'),
	 	hover_image_four: cdn('/Case_studies/RonFarris/ron-farris-flyer.jpg'),
	 	color: '#f148FB',
	 	client: 'Ron Farris Real Estate',
	 	by_line: '',
	 	category: 'property',
	 	component: RonFarris
	 },
	{
		id: 23,
		slug: 'twinfin',
		logo: cdn('/Case_studies/TwinFin/twin-fin-logo.svg'),
		image: cdn('/init_hover/twinfin-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/TwinFin/image_4.png'),
		hover_image_two: cdn('/Case_studies/TwinFin/Sept_2020_TwinFin-84-web.jpeg'),
		hover_image_three: cdn('/Case_studies/TwinFin/TwinFin-20210122-014-web.jpeg'),
		hover_image_four: cdn('/Case_studies/TwinFin/Sept_2020_TwinFin-97-web.jpeg'),
		color: '#eee',
		client: 'Twin Fin',
		by_line: 'Fast-Casual Fish & Chips',
		category: 'hospitality',
		logo_width: '200px',
		component: TwinFin
	},
	{
		id: 22,
		slug: 'hair-supplies',
		logo: cdn('/init_hover/hairsupplies-hover-oner.jpeg'),
		image: cdn('/Case_studies/case-initial-hovers/hair-supplies-hover-1.png'),
		hover_image_one: cdn('/Case_studies/HairSupplies/square-one.jpg'),
	    hover_image_two: cdn('/Case_studies/HairSupplies/hair-14.jpeg'),
	    hover_image_three: cdn('/Case_studies/HairSupplies/classic.jpeg'),
	    hover_image_four: cdn('/Case_studies/HairSupplies/wild-heart.jpeg'),
		color: '#88c4b9',
		client: 'Hair Supplies',
		by_line: 'Get The Look You Want',
		logo_width: '200px',
		category: 'retail',
		component: HairSupplies
	},
	 {
	 	id: 6,
	 	slug: 'cottesloe-village',
	 	logo: cdn('pilbara-etched.png'),
	 	image: cdn('/init_hover/cott-hover-oner.jpeg'),
	 	hover_image_one: cdn('/Case_studies/Cottesloe_village/20201203 Cottesloe Village - Creative - Xmas Event Photography-123-web.jpeg'),
	 	hover_image_two: cdn('/Case_studies/Cottesloe_village/20201203 Cottesloe Village - Creative - Xmas Event Photography-135-web.jpeg'),
	 	hover_image_three: cdn('/Case_studies/Cottesloe_village/20201203 Cottesloe Village - Creative - Xmas Event Photography-149-web.jpeg'),
	 	hover_image_four: cdn('/Case_studies/Cottesloe_village/20201203 Cottesloe Village - Creative - Xmas Event Photography-153-web.jpeg'),
	 	color: '#93908c',
	 	client: 'Cottesloe Village',
	 	by_line: 'Relax. You’re in Cottesloe',
	 	logo_width: '100%',
	 	category: 'community',
	 	component: CottesloeVillage
	 },
	 {
	 	id: 7,
	 	slug: 'pilbara-minerals',
	 	logo: cdn('hbf-etched.png'),
	 	image: cdn('/init_hover/pilbara-hover-oner.jpeg'),
	 	hover_image_one: cdn('/Case_studies/Pilbara_minerals/square-two.png'),
	 	hover_image_two: cdn('/Case_studies/Pilbara_minerals/Sept_2020_SiteTrip-52.jpg'),
	 	hover_image_three: cdn('/Case_studies/Pilbara_minerals/PILBARA MINERALS_WINDOWS FROSTING_MOCKUP.jpg'),
	 	hover_image_four: cdn('/Case_studies/Pilbara_minerals/Sept_2020_SiteTrip-523-web.jpeg'),
	 	color: '#0ab4bf',
	 	client: 'Pilbara Minerals',
	 	by_line: 'Sustainable Energy Future',
	 	logo_width: '100%',
	 	category: 'resources',
	 	component: PilbaraMinerals
	 },
	 {
	 	id: 8,
	 	slug: 'lever-property',
	 	logo: cdn('coming-soon-final-v2.png'),
	 	image: cdn('/init_hover/lever-hover-oner.jpeg'),
	 	hover_image_one: cdn('/Case_studies/Lever_property/SIGNAGE_FOR LEASE.png'),
	 	hover_image_two: cdn('/Case_studies/Lever_property/IMAGE_01.png'),
	 	hover_image_three: cdn('/Case_studies/Lever_property/IMAGE_02.png'),
	 	hover_image_four: cdn('/Case_studies/Lever_property/square-two.png'),
	 	color: '#f148FB',
	 	client: 'Lever Property',
	 	by_line: 'Commercial Property Management',
	 	logo_width: '200px',
	 	category: 'property',
	 	component: LeverProperty
	 },
	{
		id: 21,
		slug: 'pride-wa',
		image: cdn('/init_hover/pride-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/case-initial-hovers/pride-hover-1.png'),
		hover_image_two: cdn('/Case_studies/Pride_wa/Naomi_Reed_Photography-3400-web-v22.jpeg'),
		hover_image_three: cdn('/Case_studies/Pride_wa/028_PRIDE-FESTIVAL_20181124_Photo…ecca-Mansell.png'),
		hover_image_four: cdn('/Case_studies/Pride_wa/071_PRIDE-FESTIVAL_20181124_Photo…ecca-Mansell.png'),
		color: '#f148FB',
		client: 'Pride WA',
		by_line: 'Community, Arts and Culture',
		logo_width: '200px',
		category: 'community',
		component: PrideWa
	},
	{
		id: 10,
		slug: 'cicerellos',
		image: cdn('/init_hover/cicerellos-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/case-initial-hovers/cicerellos-hover-1.png'),
		hover_image_two: cdn('/Case_studies/Cicerellos/cicerellos-hover-2.jpeg'),
		hover_image_three: cdn('/Case_studies/Cicerellos/2020.12.14 CRAYFISH DISHES-3-web.jpeg'),
		hover_image_four: cdn('/Case_studies/Cicerellos/2020.12.14 CRAYFISH DISHES-33-web.jpeg'),
		color: '#f148FB',
		client: 'Cicerello’s',
		by_line: 'The Home of Fish ‘n’ Chip',
		category: 'hospitality',
		component: Cicerellos
	},
	 {
	 	id: 11,
	 	slug: 'revo-fitness-innaloo',
	 	image: cdn('/init_hover/revoinnaloo-hover-oner.jpeg'),
	 	hover_image_one: cdn('/Case_studies/Revo_fitness/REVO Client PT_High Res (50 of 83)-web.jpeg'),
	 	hover_image_two: cdn('/Case_studies/Revo_fitness/REVO Client PT_High Res (57 of 83)-web.jpeg'),
	 	hover_image_three: cdn('/Case_studies/Revo_fitness/REVO Client PT_High Res (67 of 83)-web.jpeg'),
	 	hover_image_four: cdn('/Case_studies/Revo_fitness/REVO Client PT_High Res (81 of 83)-web.jpeg'),
	 	color: '#f148FB',
	 	client: 'Revo Fitness',
	 	by_line: 'Innaloo Launch',
	 	category: 'health_care',
	 	component: RevoFitnessInnaloo
	 },
	{
		id: 20,
		slug: 'perth-brain-centre',
		image: cdn('/init_hover/pbc-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/PerthBrainCentre/2017.04.17 PBC WEB CONTENT.  -56.jpg'),
		hover_image_two: cdn('/Case_studies/PerthBrainCentre/2017.04.24 PERTH BRAIN CENTRE WEB CONTENT SHOOT.  3-web.jpeg'),
		hover_image_three: cdn('/Case_studies/PerthBrainCentre/2020.08.28 Currembine-10-web.jpeg'),
		hover_image_four: cdn('/Case_studies/PerthBrainCentre/2020.08.28 Currembine-12-web.jpeg'),
		color: '#f148FB',
		client: 'Perth Brain Centre',
		by_line: 'Neurofeedback Therapy',
		category: 'health_care',
		component: PerthBrainCenter
	},
	{
		id: 19,
		slug: 'hbf',
		image: cdn('/init_hover/hbf-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/HBF/02.BUBBLE_1x1-web.jpeg'),
		hover_image_two: cdn('/Case_studies/HBF/03.GLOVES_1x1-web.jpeg'),
		hover_image_three: cdn('/Case_studies/HBF/Scooter_v01-web.jpeg'),
		hover_image_four: cdn('/Case_studies/HBF/Vader_01-web.jpeg'),
		color: '#f148FB',
		client: 'HBF',
		by_line: 'Flu Season',
		category: 'health_care',
		component: HBF
	},

	{
		id: 18,
		slug: 'waac',
		image: cdn('/Case_studies/case-initial-hovers/waac-hover-1.png'),
		hover_image_one: cdn('/Case_studies/WAAC/waac-hover-two.jpg'),
		hover_image_two: cdn('/Case_studies/WAAC/waac-hover-three.jpg'),
		hover_image_three: cdn('/Case_studies/WAAC/waac-hover-four.jpg'),
		hover_image_four: cdn('/Case_studies/WAAC/WAAC_FLYER MOCKUP.png'),
		color: '#f148FB',
		client: 'WAAC',
		by_line: 'Formerly WA Aids Council',
		category: 'education',
		component: WAAC
	},
	{
		id: 17,
		slug: 'slipstream',
		image: cdn('/Case_studies/SlipStream/SLIPSTREAM.png'),
		hover_image_one: cdn('/Case_studies/SlipStream/slipstream-hover-three.jpeg'),
		hover_image_two: cdn('/Case_studies/SlipStream/slipstream-hover-four.jpeg'),
		hover_image_three: cdn('/Case_studies/SlipStream/slipstream-hover-two.jpg'),
		hover_image_four: cdn('/Case_studies/SlipStream/slipstream-hover-five.jpg'),
		color: '#f148FB',
		client: 'Slipstream',
		by_line: 'Managed Security Service Provider',
		category: 'education',
		component: SlipStream
	},
	{
		id: 19,
		slug: 'stirling-capital',
		image: cdn('/init_hover/stirling-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/StirlingCapital/stirling-hover-2.jpg'),
		hover_image_two: cdn('/Case_studies/StirlingCapital/stirling-hover-3.jpg'),
		hover_image_three: cdn('/Case_studies/StirlingCapital/stirling-hover-4.jpg'),
		hover_image_four: cdn('/Case_studies/StirlingCapital/stirling-hover-5.jpg'),
		color: '#f148FB',
		client: 'Stirling Capital',
		by_line: 'City Living Re-imagined',
		category: 'property',
		component: StirlingCapital
	},
	{
		id: 20,
		slug: 'blackburne',
		image: cdn('/init_hover/blackburne-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/BlackBurne/b-hover-2.jpg'),
		hover_image_two: cdn('/Case_studies/BlackBurne/b-hover-3.jpg'),
		hover_image_three: cdn('/Case_studies/BlackBurne/b-hover-4.jpeg'),
		hover_image_four: cdn('/Case_studies/BlackBurne/b-hover-5.jpg'),
		color: '#f148FB',
		client: 'Blackburne Property',
		by_line: 'WA’s Leading Property Group',
		category: 'property',
		component: BlackBurne
	},
	{
		id: 16,
		slug: 'construction-futures-centre',
		image: cdn('/init_hover/ctf-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/CTF/ctf-hover-2.jpg'),
		hover_image_two: cdn('/Case_studies/CTF/ctf-hover-3.jpeg'),
		hover_image_three: cdn('/Case_studies/CTF/ctf-hover-4.jpeg'),
		hover_image_four: cdn('/Case_studies/CTF/ctf-hover-5.jpeg'),
		color: '#f148FB',
		client: 'Construction Futures Centre',
		by_line: 'Creating a Skilled WA Workforce',
		category: 'education',
		component: CTF
	},
	{
		id: 15,
		slug: 'pyc',
		image: cdn('/init_hover/pyc-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/PYC/pyc-hover-2.jpeg'),
		hover_image_two: cdn('/Case_studies/PYC/pyc-hover-3.jpeg'),
		hover_image_three: cdn('/Case_studies/PYC/pyc-hover-4.jpeg'),
		hover_image_four: cdn('/Case_studies/PYC/pyc-hover-5.jpeg'),
		color: '#f148FB',
		client: 'PYC Therapeutics',
		by_line: 'Life-Changing Science',
		category: 'technology',
		component: PYC
	},
	{
		id: 14,
		slug: 'source-energy',
		image: cdn('/init_hover/source-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/SourceEnergy/source-hover-2.jpeg'),
		hover_image_two: cdn('/Case_studies/SourceEnergy/source-hover-3.jpeg'),
		hover_image_three: cdn('/Case_studies/SourceEnergy/source-hover-4.jpeg'),
		hover_image_four: cdn('/Case_studies/SourceEnergy/source-hover-5.jpeg'),
		color: '#f148FB',
		client: 'Source Energy',
		by_line: 'Energy Provider of Choice',
		category: 'resources',
		component: SourceEnergy
	},
	{
		id: 13,
		slug: 'primero',
		image: cdn('/init_hover/primero-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/Primero/primero-hover-2.jpeg'),
		hover_image_two: cdn('/Case_studies/Primero/primero-hover-3.jpeg'),
		hover_image_three: cdn('/Case_studies/Primero/primero-hover-4.jpeg'),
		hover_image_four: cdn('/Case_studies/Primero/primero-hover-5.jpeg'),
		color: '#f148FB',
		client: 'Primero',
		by_line: 'Driving Projects Forward',
		category: 'resources',
		component: Primero
	},
	{
		id: 12,
		slug: 'dolphin-discovery-centre',
		image: cdn('/init_hover/ddc-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/DDC/hover-2.jpg'),
		hover_image_two: cdn('/Case_studies/DDC/hover-3.jpg'),
		hover_image_three: cdn('/Case_studies/DDC/hover-4.jpg'),
		hover_image_four: cdn('/Case_studies/DDC/hover-5.jpg'),
		color: '#f148FB',
		client: 'Dolphin Discovery Centre',
		by_line: 'Australia\'s Premier Dolphin Experience',
		category: 'education',
		component: DDC
	},
	{
		id: 11,
		slug: 'busselton-jetty-swim',
		image: cdn('/init_hover/busso-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/BusseltonSwim/114A2891-web-v2.jpeg'),
		hover_image_two: cdn('/Case_studies/BusseltonSwim/114A2573-web-v2.jpeg'),
		hover_image_three: cdn('/Case_studies/BusseltonSwim/114A2617-web-v2.png'),
		hover_image_four: cdn('/Case_studies/BusseltonSwim/0Y9A8924-web-v2.jpeg'),
		color: '#f148FB',
		client: 'Busselton Jetty Swim',
		by_line: 'An Iconic WA Event',
		logo_width: '200px',
		category: 'community',
		component: BusseltonSwim
	},
	{
		id: 10,
		slug: 'uwa-sport',
		image: cdn('/init_hover/uwa-hover-oner.jpeg'),
		hover_image_one: cdn('/Case_studies/UWA_Sport/uwa-hover-two.jpg'),
		hover_image_two: cdn('/Case_studies/UWA_Sport/UWA_POSTER_MOCKUP-03.png'),
		hover_image_three: cdn('/Case_studies/UWA_Sport/fence.jpeg'),
		hover_image_four: cdn('/Case_studies/UWA_Sport/2018.04.19 FENCING, SOCCER, ATHLETICS  (1 of 54) (5)-web.jpeg'),
		color: '#f148FB',
		client: 'UWA Sport',
		by_line: 'See Sport Differently',
		logo_width: '200px',
		category: 'health_care',
		component: UWASport
	},
	{
		id: 33,
		slug: 'cicerellos-sea-critters',
		image: cdn('/Case_studies/CicerellosSeaCritters/cicerellos-sea-critters-small-image-three.jpg'),
		hover_image_one: cdn('/Case_studies/CicerellosSeaCritters/Sea Critters-5-web.jpeg'),
		hover_image_two: cdn('/Case_studies/CicerellosSeaCritters/Sea Critters 2021-46-web.jpeg'),
		hover_image_three: cdn('/Case_studies/CicerellosSeaCritters/cicerellos-sea-critters-flyer.jpg'),
		hover_image_four: cdn('/Case_studies/CicerellosSeaCritters/210702_CICERELLOS_SC STILLS-02-web.jpeg'),
		color: '#f148FB',
		client: "Cicerello's - Sea Critters",
		by_line: 'Aquarium Tours',
		category: 'hospitality',
		component: CicerellosSeaCritters
	},
	
	{
		id: 34,
		slug: 'foodies-market-iga',
		image: cdn('/Case_studies/FoodiesMarket/foodies-square-one.jpg'),
		hover_image_one: cdn('/Case_studies/FoodiesMarket/foodies-square-two.jpg'),
		hover_image_two: cdn('/Case_studies/FoodiesMarket/foodies-square-three.jpg'),
		hover_image_three: cdn('/Case_studies/FoodiesMarket/foodies-square-four.jpg'),
		hover_image_four: cdn('/Case_studies/FoodiesMarket/foodies-square-five.jpg'),
		color: '#f148FB',
		client: 'Foodies Market IGA',
		by_line: 'See Sport Differently',
		logo_width: '200px',
		category: 'hospitality',
		component: FoodiesMarket
	},
	{
		id: 37,
		slug: 'murdoch-university',
		image: cdn('/Case_studies/MurdochUni/murdoch-small-image-one.jpg'),
		hover_image_one: cdn('/Case_studies/MurdochUni/murdoch-small-image-two.jpg'),
		hover_image_two: cdn('/Case_studies/MurdochUni/murdoch-small-image-three.jpg'),
		hover_image_three: cdn('/Case_studies/MurdochUni/murdoch-uni-header-image.jpg'),
		hover_image_four: cdn('/Case_studies/MurdochUni/murdoch-square-two.png'),
		color: '#f148FB',
		client: 'Murdoch University',
		by_line: '',
		category: 'education',
		component: MurdochUni	
	},
	/*{
		id: 35,
		slug: 'capital-property-advisory',
		image: cdn('/Case_studies/Case_studies/HunterCapital/hunter-capital-main-image.jpg'),
		hover_image_one: cdn('/Case_studies/HunterCapital/hunter-capital-small-image-one.jpg'),
		hover_image_two: cdn('/Case_studies/HunterCapital/hunter-capital-small-image-two.jpg'),
		hover_image_three: cdn('/Case_studies/HunterCapital/hunter-capital-small-image-three.jpg'),
		hover_image_four: cdn('/Case_studies/HunterCapital/hunter-capital-hallifax.jpg'),
		color: '#f148FB',
		client: 'Capital Property Advisory',
		by_line: '',
		category: 'property',
		component: CapitalPropertyAdvisory
	},*/
	{
		id: 35,
		slug: 'hunter-capital',
		image: cdn('/Case_studies/HunterCapital/hunter-capital-small-image-one.jpg'),
		hover_image_one: cdn('/Case_studies/HunterCapital/hunter-capital-small-image-two.jpg'),
		hover_image_two: cdn('/Case_studies/HunterCapital/hunter-capital-small-image-three.jpg'),
		hover_image_three: cdn('/Case_studies/HunterCapital/hunter-capital-angove-west.jpg'),
		hover_image_four: cdn('/Case_studies/HunterCapital/hunter-capital-hallifax.jpg'),
		color: '#f148FB',
		client: 'Hunter Capital',
		by_line: '',
		category: 'property',
		component: HunterCapital
	},
	/*
	{
		id: 35,
		slug: 'chase-residential',
		image: cdn(''),
		hover_image_one: cdn(''),
		hover_image_two: cdn(''),
		hover_image_three: cdn(''),
		hover_image_four: cdn(''),
		color: '#f148FB',
		client: 'Chase Residential',
		by_line: '',
		category: 'property',
		component: ChaseResidential
	},
	*/
	/*{
		id: 36,
		slug: 'distinguished-homes',
		image: cdn('/Case_studies/DistinguishedHomes/dh-homes-small-image-two.jpg'),
		hover_image_one: cdn('/Case_studies/DistinguishedHomes/dh-homes-main-image.jpg'),
		hover_image_two: cdn('/Case_studies/DistinguishedHomes/dh-homes-small-image-one.jpg'),
		hover_image_three: cdn('/Case_studies/DistinguishedHomes/dh-homes-small-image-two.jpg'),
		hover_image_four: cdn('/Case_studies/DistinguishedHomes/dh-homes-small-image-three.jpg'),
		color: '#f148FB',
		client: 'Distinguished Homes',
		by_line: '',
		category: 'property',
		component: DistinguishedHomes
	},*/
	// {
	// 	id: 30,
	// 	slug: '#',
	// 	logo: cdn('coming-soon-final-v2.png'),
	// 	image: cdn('/coming-soon/coming-soon-1.jpeg'),
	// 	hover_image_one: cdn('/coming-soon/coming-soon-2.jpeg'),
	// 	hover_image_two: cdn('/coming-soon/coming-soon-3.jpg'),
	// 	hover_image_three: cdn('/coming-soon/coming-soon-4.png'),
	// 	hover_image_four: cdn('/coming-soon/coming-soon-5.jpeg'),
	// 	color: '#f148FB',
	// 	client: 'Community',
	// 	by_line: 'coming soon',
	// 	logo_width: '200px',
	// 	category: ''
	// },
	 {
	 	id: 38,
	 		slug: 'boomers-protein',
	 		image: cdn('/Case_studies/BoomersProtein/BOOMERS---FRAME-1-web.jpg'),
	 		hover_image_one: cdn('/Case_studies/BoomersProtein/boomers-small-image-three.jpg'),
	 		hover_image_two: cdn('/Case_studies/BoomersProtein/boomers-header-image.jpg'),
	 		hover_image_three: cdn('/Case_studies/BoomersProtein/boomers-small-image-two.jpg'),
	 		hover_image_four: cdn('/Case_studies/BoomersProtein/boomers-small-image-one.jpg'),
	 		color: '#f148FB',
	 		client: 'Boomers Protein',
	 		by_line: '',
	 		logo_width: '200px',
	 		category: 'health',
	 		component: BoomersProtein
	 },
	
	{
		id: 38,
			slug: 'cocoon-luxury',
			logo: cdn('/Case_studies/CocoonLuxury/cocoon-main-image.jpg'),
			image: cdn('/Case_studies/CocoonLuxury/cocoon-image-small-one.jpg'),
			hover_image_one: cdn('/Case_studies/CocoonLuxury/cocoon-small-image-two.jpg'),
			hover_image_two: cdn('/Case_studies/CocoonLuxury/cocoon-small-image-three.jpg'),
			hover_image_three: cdn('/Case_studies/CocoonLuxury/cocoon-bunddle.jpg'),
			hover_image_four: cdn('/Case_studies/CocoonLuxury/cocoon-small-image-two.jpg'),
			color: '#f148FB',
			client: 'Cocoon Luxury',
			by_line: '',
			logo_width: '200px',
			category: 'retail',
			component: CocoonLuxury
	},

	/*{
		id: 39,
			slug: 'ajdiamond',
			logo: cdn('/Case_studies/AjDiamond/ajdiamond-main-image.jpg'),
			image: cdn('/Case_studies/AjDiamond/ajdiamond-small-image-one.jpg'),
			hover_image_one: cdn('/Case_studies/AjDiamond/ajdiamond-small-image-two.jpg'),
			hover_image_two: cdn('/Case_studies/AjDiamond/ajdiamond-small-image-three.jpg'),
			hover_image_three: cdn('/Case_studies/AjDiamond/ajdiamond-screen-grab.jpg'),
			hover_image_four: cdn('/Case_studies/AjDiamond/ajdiamond-small-image-two.jpg'),
			color: '#f148FB',
			client: 'Aj Diamond',
			by_line: '',
			logo_width: '200px',
			category: 'technology',
			component: AjDiamond
	},*/
	
	 {
 	id: 9,
	 		slug: '4farmers',
	 		image: cdn('/Case_studies/4Farmers/4FARMERS-frame.jpeg'),
	 		hover_image_one: cdn('/Case_studies/4Farmers/4farmers-hover-two.jpeg'),
	 		hover_image_two: cdn('/Case_studies/4Farmers/4farmers-hover-three.jpeg'),
	 		hover_image_three: cdn('/Case_studies/4Farmers/4farmers-hover-four.jpeg'),
	 		hover_image_four: cdn('/Case_studies/4Farmers/4farmers-hover-five.jpeg'),
	 		color: '#f148FB',
	 		client: '4Farmers',
	 		by_line: '',
	 		logo_width: '200px',
	 		category: 'agriculture',
	 		component: Farmers
 },
 /*
	{
		id: 42,
		slug: 'dimattina',
		image: cdn('/Case_studies/Dimattina/cicerellos-sea-critters-small-image-three.jpg'),
		hover_image_one: cdn('/Case_studies/Dimattina/Sea Critters-5-web.jpeg'),
		hover_image_two: cdn('/Case_studies/Dimattina/Sea Critters 2021-46-web.jpeg'),
		hover_image_three: cdn('/Case_studies/Dimattina/cicerellos-sea-critters-flyer.jpg'),
		hover_image_four: cdn('/Case_studies/Dimattina/210702_CICERELLOS_SC STILLS-02-web.jpeg'),
		color: '#f148FB',
		client: "Dimattina",
		by_line: 'Dimattina Coffee',
		category: 'retail',
		component: Dimattina
	},
	*/
	// {
	// 	id: 42,
	// 	slug: 'cicerellos-scent-of-summer',
	// 	image: cdn('/Case_studies/CicerellosScentOfSummer/CICERELLO-TSOS.png'),
	// 	hover_image_one: cdn('/Case_studies/CicerellosScentOfSummer/perfume.png'),
	// 	hover_image_two: cdn('/Case_studies/CicerellosScentOfSummer/box1.png'),
	// 	hover_image_three: cdn('/Case_studies/CicerellosScentOfSummer/box3.png'),
	// 	hover_image_four: cdn('/Case_studies/CicerellosScentOfSummer/CICERELLO-TSOS.png'),
	// 	color: '#f148FB',
	// 	client: "Cicerello's - Scent of Summer",
	// 	by_line: 'Scent of Summer',
	// 	category: 'hospitality',
	// 	component: CicerellosScentOfSummer
	// },
	
	{
		id: 40,
				 slug: 'heart-foundation',
				 logo: cdn('/Case_studies/HeartFoundation/heart-screen-two.jpg'),
				 image: cdn('/Case_studies/HeartFoundation/heart-screen-three.jpg'),
				 hover_image_one: cdn('/Case_studies/HeartFoundation/heart-healthy-active.png'),
				 hover_image_two: cdn('/Case_studies/HeartFoundation/heart-screen-two.jpg'),
				 hover_image_three: cdn('/Case_studies/HeartFoundation/heart-screen-three.jpg'),
				 hover_image_four: cdn('/Case_studies/HeartFoundation/heart-screen-two.jpg'),
				 color: '#f148FB',
				 client: 'Heart Foundation',
				 by_line: '',
				 logo_width: '200px',
				 category: 'health',
				 component: HeartFoundation
	}
	

]