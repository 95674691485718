/*
  RevoFitness.jsx
  @Component - Revo Fitness Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './HoneyForLife.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]

//stat images
const images_stat = [
  {
   id: 1,
   image: cdn('/Case_studies/BlackBurne/6developments.jpg'),
   width:'calc(50% - 30px)',
 },
 {
   id: 2,
   image: cdn('/Case_studies/FoodiesMarket/foodies-square-stat-one.png'),
   width:'calc(50% - 30px)',
   borderRadius: '32px',
   className:'special'
 },
 {
   id: 3,
   image: cdn('/Case_studies/FoodiesMarket/foodies-square-stat-two.png'),
   width:'calc(50% - 30px)',
   borderRadius: '32px',
   className:'special'
 },

{
   id: 4,
   image: cdn('/Case_studies/BlackBurne/50videos.jpg'),
   width:'calc(50% - 30px)',
   borderRadius: '32px',
 },
];


// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/HoneyForLife/honey-for-life-main-image.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/HoneyForLife/honey-for-life-thumb-image-two.jpg'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/HoneyForLife/honey-for-life-image-three.jpg'),
    background_size: 'cover',
  }

]

const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/HoneyForLife/finz-frensy.svg'),
    background_size: '500px',
    background_repeat:'no-repeat',
    background_position:'right',
    width:'50%',
    background_position:'right',
    className:'special-image'
  },
  {
    id: 2,
    image: cdn('/Case_studies/HoneyForLife/stat-two.svg'),
    background_size: '290px',
    background_repeat:'no-repeat',
    width:'40%',
    background_position:'left',
    className:'custom-height-mob'
  },
  {
    id: 3,
    image: cdn('/Case_studies/HoneyForLife/stat-one.svg'),
    background_size: '290px',
    background_repeat:'no-repeat',
    width:'40%',
    background_position:'right',
    className:'custom-height-mob'

  },
  {
    id: 4,
    image: cdn('/Case_studies/HoneyForLife/fingo.svg'),
    background_size: '500px',
    background_repeat:'no-repeat',
    width:'50%',
    background_position:'left',
    className:'special-image'
  },


]

const images_gifs = [
   {
    id: 1,
    image: cdn('/Case_studies/HoneyForLife/image_1%402x.png'),
    width:'20%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/HoneyForLife/Swell Times IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/HoneyForLife/Honey for Life_INSTA%402x.png'),
    width:'30%',
  },
  {
    id: 4,
    image: cdn('/Case_studies/HoneyForLife/Open Now IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 6,
    image: cdn('/Case_studies/HoneyForLife/image_1%402x.png'),
    width:'20%',
  },


]


export default class HoneyForLife extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="honey-for-life-wrapper twin-fin-wrapper">
      <MetaTags>
        <title>Honey For Life - OKMG</title>
        <meta name="description" content="Honey For Life - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/honey-for-life'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Honey For Life" role="heading"
        sub_content="Digital | Creative | Marketing"
        service_info="Honey For Life is a local producer of raw, natural honey gathered from the pristine forests around Western Australia. Through a refined marketing strategy, OKMG educated consumers on the benefits of pure, organic honey, boosting Honey For Life’s brand awareness within a highly saturated market."
        image={cdn('/Case_studies/HoneyForLife/honey-for-life-jar.jpg')}
        image_class="header-image"
        image_width="85%"
        foot_image={cdn('/Case_studies/HoneyForLife/honey-for-life-crumb.jpg')}
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>OKMG was initially engaged to improve Honey For Life's marketing tactics. Whilst money was previously being spent on their digital marketing, it wasn't yielding the desired outcome. This resulted from audiences lacking education around high-quality honey vs what was readily available from the supermarket. </p>
            <p>Through a refined digital marketing strategy, we were able to define our audience. People who were looking to introduce more honey into their diet for taste and health purposes, who felt frustrated by the number of honey alternatives available. From here we developed a marketing approach that would talk directly to consumer needs, communicating why Honey For Life was the pure and organic honey of choice, delivering on flavour but also remedial purposes. </p>
            <p>After conducting extensive research into how different cultures interact with honey we identified channels and a messaging strategy to start a conversatiionio around the benefits of Honey For Life’s product range. A large focus of this was a three-day video production & photography shoot in the southwest of Western Australia, capturing the content of the Honey For Life honey gathering process, including the pristine environments in which they procure their delicious golden honey. Highlighting the natural purity of their honey was crucial in making Honey For Life relevant within international markets, especially within the UAE, where honey is revered, almost sacred.</p>
            <p>To close the consumer journey loop, OKMG successfully elevated Honey For Life's brand position into the international honey market via several website duplications for the UK, the USA and the UAE audiences. We targeted our content to directly promote Honey For Life's products within these platforms, resulting in a dramatic and consistent increase in sales.</p>
            <a href="https://honeyforlife.com.au" target="_blank" style={{fontWeight:'bold'}}>View website</a>
          </div>}
        />

        <ComponentVideo
          video_url={cdn('/Case_studies/HoneyForLife/OKMG_HFL_CASESTUDY_FINAL-web.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />

          <ComponentFullBleedImage 
          image={cdn('/Case_studies/HoneyForLife/honey-for-life-screen-grab-one.jpg')}
          width="100%"
          marginTop="100px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          />

          <ComponentVideo
          video_url={cdn('/Case_studies/HoneyForLife/HFL_HOWITWORKS_WIDE_EDIT V2-webv1.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />     

          <ComponentFullBleedImage
          image={cdn('/Case_studies/HoneyForLife/2020.11.27 Honey For Life-59-web.jpeg')}
          marginTop="100px"
          width="100%"
          />
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}