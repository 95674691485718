/*
  CurtinUni.jsx
  @Component - Curtin UniversityCase Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './CurtinUni.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/CurtinUni/squareonev3.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/CurtinUni/square-two.jpg'),
    background_repeat: 'no-repeat',
    background_size: 'contain',
    background_color:'rgb(212 212 212)'
  },
  {
    id: 3,
    image: cdn('/Case_studies/CurtinUni/square-threev2.jpg'),
    background_size: 'cover',
  }

]


export default class CurtinUni extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="curtin-uni-wrapper">
      <MetaTags>
        <title>Curtin University - Virtual Explorer - OKMG</title>
        <meta name="description" content="Curtin University - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/curtin-university'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Curtin University" role="heading"  
        sub_content="Strategy | Digital | Creative | Marketing"
        service_info="A full service engagement with Curtin University, creating an immersive virtual campus experience for prospective students."
        image={cdn('/Case_studies/CurtinUni/curtin-map.jpg')}
        image_width="110%"
        foot_image={cdn('/Case_studies/CurtinUni/Avatar Beanie M2.png')}
        foot_image_width="200px"
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>Greatly impacted by COVID-19, Curtin University required a pivot towards a digital presence that would translate the look and feel of the Bentley Campus in Perth, WA, to the digital medium, with a specific objective of engaging future students.</p>
            <p>We created a human-centric platform within a digital space, ensuring that each prospective student would feel welcomed and gain insights into Australian culture - leading with the Acknowledgement of Country to pay respect to the Noongar people, and welcome new students to Noongar land. </p>
            <p>This interactive technology was designed to show students the Curtin lifestyle, all the while fitting into the palm of your hand, no matter where you are in the world. Maintaining a strong level of interest in the campus virtually, for international students.</p>
            <p>The Curtin Virtual Explorer was a huge leap forward for Curtin University in reaching a larger audience of prospective students across a global market.</p>
             <ComponentReadMore
             content={
              <span>
                <p>Faced with a short deadline to launch by Open Day, we began to conceptualise a digital platform to bring this vision to life. This technology would fit into the palm of your hand, no matter your location in the world, acting as a way to maintain a strong level of interest for international students. </p>
                <p>OKMG developed original concepts, going through various Creative Council approvals to ensure that this new digital platform was on brand, complied with ISO 27001 standards, as well as giving consideration to the multiple stakeholders.</p>
                <p>To make this unique project truly standout to prospective students we utilised our full creative agency resources, ensuring that we created a digital platform that was as human-centric as possible.</p>
                <p>With over 116 hectares of the Bentley Campus to cover, we collaborated with an expert rendering team for 3D modelling, covering all the different facilities on campus. The user journey was a large aspect of this project, as we were going to market in multiple countries. Our international audience was taken into consideration, ensuring that different languages were available, to more cultural aspects.  Creating an accurate reflection of the welcoming nature that Curtin University embodies.</p>
                <p>Launching successfully on Open Day, we created a digital experience showing the potential of the Curtin University lifestyle. We were able to build a human-centric platform within a digital space, ensuring that each prospective student would both feel welcome and gain insight into Australian culture, even covering education on the importance of Acknowledgement of Country to pay respect to the Noongar people.</p>
                <p>The Curtin Virtual Explorer had a successful run of push marketing, with 30,000 unique page views and usage across a global market including India, Indonesia, Vietnam, Philippines, Philippines, Pakistan, Singapore and, Australia. </p>
              </span>
            }
             />
          </div>}
        />

        <ComponentVideo 
          video_url={cdn('/Case_studies/CurtinUni/curtin-uni-vid-v2-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
        />
          <ComponentFullBleedImage
          image={cdn('/Case_studies/CurtinUni/CU_Mobile Screenshots.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />
        <ComponentVideo 
          video_url={cdn('/Case_studies/CurtinUni/Phone-virtual-campus-horizontal-rz-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
        />
           <ComponentFullBleedImage
          image={cdn('/Case_studies/CurtinUni/Curtin_iMac_Mockup-min.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />
  
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}