/*
  MurdochUni.jsx
  @Component - Murdoch Uni Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './MurdochUni.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]

//stat images
const images_two = [
  {
   id: 1,
   image: cdn('/Case_studies/MurdochUni/navitas-1.7-mil.png'),
   width:'calc(50% - 30px)',
 },
 {
   id: 2,
   image: cdn('/Case_studies/MurdochUni/navitas-rec-2.png'),
   width:'calc(50% - 30px)',
   borderRadius: '32px',
   className:'special'
 },
 {
   id: 3,
   image: cdn('/Case_studies/MurdochUni/navitas-rec-3.png'),
   width:'calc(45% - 30px)',
   borderRadius: '32px',
   className:'special'
 },

{
   id: 4,
   image: cdn('/Case_studies/MurdochUni/navitas-60.png'),
   width:'calc(55% - 30px)',
   borderRadius: '32px',
 },
];

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/MurdochUni/murdoch-small-image-one.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/MurdochUni/murdoch-small-image-two.jpg'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/MurdochUni/murdoch-small-image-three.jpg'),
    background_size: 'cover',
  }

]

const images_squares = [
   {
    id: 1,
    image: cdn('/Case_studies/MurdochUni/murdoch-square-one.png'),
    background_size: '316px 316px',
    background_repeat:'no-repeat',
    background_position:'bottom',
    width:'316px',
    className:'sp-image-one'
  },
  {
    id: 2,
    image: cdn('/Case_studies/MurdochUni/mudoch-square-two.png'),
    background_size: '370px 370px',
    background_repeat:'no-repeat',
    width:'370px',
    background_position:'bottom',
    className:'sp-image-two'
  },
  {
    id: 3,
    image: cdn('/Case_studies/MurdochUni/murdoch-square-three.png'),
    background_size: '316px 316px',
    background_repeat:'no-repeat',
    width:'316px',
    background_position:'bottom',
    className:'sp-image-three'

  },


]

const images_gifs = [
   {
    id: 1,
    image: cdn('/Case_studies/MurdochUni/image_1%402x.png'),
    width:'20%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/MurdochUni/Swell Times IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/MurdochUni/Murdoch University_INSTA%402x.png'),
    width:'30%',
  },
  {
    id: 4,
    image: cdn('/Case_studies/MurdochUni/Open Now IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 6,
    image: cdn('/Case_studies/MurdochUni/image_1%402x.png'),
    width:'20%',
  },


]


export default class MurdochUni extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="murdoch-uni-wrapper">
      <MetaTags>
        <title>Murdoch University - Case Study - OKMG</title>
        <meta name="description" content="Leveraging the ‘This is Free Thinking’, we crafted a captivating digital advertising campaign for the Murdoch Dubai campus." />
        <link rel='canonical' href='https://okmg.com/work/murdoch-university'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Murdoch University" role="heading"
        sub_content="Marketing | Creative | Digital"
        service_info="Murdoch University approached us to challenge the Fremantle harbour seafood giants, giving a fresh twist to fish & chips."
        image={cdn('/Case_studies/MurdochUni/murdoch-uni-header-image.jpg')}
        image_class="header-image"
        image_width="100%"
        foot_image={cdn('/Case_studies/MurdochUni/murdoch-crumb.jpg')}
        />
        <ComponentCaseCopy
        content={
          <div>
           <p>OKMG agency embarked on an innovative digital advertising campaign for Murdoch Dubai campus, leveraging the captivating tagline 'This is Free Thinking' to ignite a sense of curiosity and foster inspiration among prospective students. Through meticulous execution, the campaign aimed to position Murdoch Dubai as a beacon of intellectual exploration and limitless possibilities.</p>

           <p>Strategic placement across various digital channels, coupled with visually striking imagery and captivating storytelling, effectively conveyed the essence of the campus's progressive educational environment. Immersive virtual tours and thought-provoking video testimonials brought the concept of 'Free Thinking' to life, showcasing the campus as a hub for independent thought, innovation, and personal growth.</p> 

           <p>The campaign's emphasis on intellectual freedom attracted a diverse audience of ambitious individuals, driving a surge of interest and establishing Murdoch Dubai as a renowned institution for academic excellence. By aligning the 'This is Free Thinking' tagline with an engaging digital presence, the campaign solidified Murdoch Dubai's reputation as a destination for those seeking a transformative educational experience.</p>
          </div>}
        />

          <ComponentCaseImages
            stats={images_squares}
            className="square-block-images"

          />

          <ComponentVideo
          video_url={cdn('/Case_studies/MurdochUni/navitas-video.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
 
          <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="100px"
          width="70%"
          />
      

          <ComponentFullBleedImage
          image={cdn('/Case_studies/MurdochUni/murdoch-main-image.jpg')}
          marginTop="100px"
          width="100%"
          />
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}