/*
  ComponentSplitSpan.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentSplitSpan.scss';
// images


export default class ComponentSplitSpan extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  render(){

   const {

      } = this.props;

  return (
      <span aria-label={this.props.copy} role={this.props.role} className="split-span">
        {this.props.copy.split("").map(function(char, index){
            return <span aria-hidden="true" key={index}>{char}</span>;
        })}
        </span>
    );
  }
}