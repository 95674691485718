/*
  BlackBurne.jsx
  @Component - Blackburne Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './BlackBurne.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/BlackBurne/Enclave-Hero-web.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/BlackBurne/Enclave-2-Bed-Living-Classic-web.jpg'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/BlackBurne/2018.10.15-BLACKBURNE-ENCLAVE-SUNSET-95-web.jpg'),
    background_size: 'cover',
  }

]


const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/BlackBurne/6developments.jpg'),
    width:'calc(50% - 30px)',
  },
  {
    id: 2,
    image: cdn('/Case_studies/BlackBurne/b-hover-4.jpeg'),
    width:'calc(50% - 30px)',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/BlackBurne/b-hover-3.jpg'),
    width:'calc(50% - 30px)',
    borderRadius: '32px',
    className:'special'
  },

{
    id: 4,
    image: cdn('/Case_studies/BlackBurne/50videos.jpg'),
    width:'calc(50% - 30px)',
    borderRadius: '32px',
  },



];


export default class BlackBurne extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="blackburne-wrapper">
      <MetaTags>
        <title>Blackburne - WA’s leading property group - OKMG</title>
        <meta name="description" content="Blackburne - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/stirling-capital'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Blackburne Property" role="heading"  
        sub_content="Creative"
        service_info="As the driving force behind high-rise living, Blackburne has cemented its reputation as WA’s leading property group. Storytelling is the most powerful tool for inspiring future homeowners to envision themselves in a Blackburne apartment."
        hover_image_two={cdn('Case_studies/BlackBurne/ORACLE_COMMNON-AREAS_HR-50_photoshopped-web-min.jpg')}
        hover_image_one={cdn('/Case_studies/BlackBurne/Enclave-Gym-web-min.jpg')}
        hover_image_three={cdn('/Case_studies/BlackBurne/b-hover-1.jpg')}
        foot_image={cdn('/Case_studies/BlackBurne/Blackburne_icon.svg')}
        className="special"
        image_class="header-image"

        foot_image_width="170px"
        foot_image_left="0px"
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>Each project by Blackburne requires a thoughtful narrative that connects with the unique lifestyle and location of each development, selling a provoking story before the building has even commenced. </p>
            <p>OKMG have produced hero campaign content for their most exciting, ground-up projects. For each campaign, we begin with conceptual ideation and strategic alignment to identify the unique attributes of each property. Then, combining scriptwriting, 3D render integration, audio selection, talent sourcing, photography and visually stunning cinematography…all to produce creative work that feels like home. </p>
            <p>Content that defines the lifestyle and appeal of each Blackburne development. Through a complete set of campaign materials shared across TV, OOH print, social media and digital applications, each development tells a unique story. </p>
          </div>}
        />

        <ComponentVideo
          video_url={cdn('/Case_studies/BlackBurne/THE-COVE-COMPLETION-VIDEO-V4-V4-1-min-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
          <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="100px"
          width="70%"
          />
          <ComponentVideo
          video_url={cdn('/Case_studies/BlackBurne/Enclave-Completion-Video-web-min-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
          <ComponentVideo
          video_url={cdn('/Case_studies/BlackBurne/carousel-rz-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          screenCast
          />
     
          <ComponentFullBleedImage
          image={cdn('/Case_studies/BlackBurne/2019.11.08 ESSENCE COMPLETION - DRONE - SAMPLE-4.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="200px"
          />
  
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}