/*
  SingleBlogs.jsx
*/

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Articles.
import CaseStudies from '../../../../CaseStudies/case_studies.js';

// Components.
import NotFoundComponent from '../../../NotFound';

// Styles.
import './ComponentCaseNav.scss';



export default class ComponentCaseNav extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

      status: false

    };
  }


  componentDidMount() {

   
    window.scrollTo(0, 0);


  }

  scrollTopLink() {

    window.scrollTo(0, 0);
    
    this.setState({status: !this.state.status});


  }





  render() {
    

    return (
      <div className="single-blog-page-wrapper">
       
      </div>
    );
  }
}
