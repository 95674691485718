/*
	cdn.js
*/

/*s3 backupurl : https://okmg-web-assets-rc.s3-ap-southeast-2.amazonaws.com **/

/**
 * Returns a URL to the asset, given a relative URL.
 *
 * @param {String} path - optional path.
 *
 * @return {String} path - absolute URL.
 */
export default function (path) {
  path = (path && String(path)) || '';

  if (path && !path.startsWith('/')) {
    path = `/${path}`;
  }

  let cdnRoot = 'https://okmg-web-assets-rc.s3.ap-southeast-2.amazonaws.com';
  try {
    if (process.env.NODE_ENV === 'development') {
      cdnRoot = 'https://okmg-web-assets-rc.s3.ap-southeast-2.amazonaws.com';
    }
  } catch (err) { }

  return `${cdnRoot}${path}`;
}
