/*
  BusseltonSwim.jsx
  @Component - Busselton Swim Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import BlobOne from '../../blobOne';
import './CurtinGoGreater.scss';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/curtinGoGreater/go-greater-one.jpg'),
    background_size: 'cover',
  },
   {
    id: 2,
    image: cdn('/Case_studies/curtinGoGreater/go-greater-three.jpg'),
    background_size: 'cover',
  },
   {
    id: 3,
    image: cdn('/Case_studies/curtinGoGreater/go-greater-two.jpg'),
    background_size: 'cover',
  }


]


const images_two = [
  {
    id: 1,
    image: cdn('/Case_studies/CurtinDiscover/discover-1.jpg'),
    // width:'30%',
    className:'img',
  },
  {
    id: 2,
    image: cdn('/Case_studies/CurtinDiscover/discover-2.jpg'),
    // width:'33%',
    borderRadius: '32px',
    className:'img',

  },
  {
    id: 3,
    image: cdn('/Case_studies/CurtinDiscover/discover-3.jpg'),
    borderRadius: '32px',
    className:'img',

  },

];



export default class CurtinGoGreater extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="curtin-uni-go-greater">
      <MetaTags>
        <title>Curtin University - Go Greater - OKMG</title>
        <meta name="description" content="Curtin University - Go Greater" />
        <link rel='canonical' href='https://okmg.com/work/curtin-university-go-greater'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Curtin University Go Greater" role="heading"  
        sub_content="Strategy | Creative"
        service_info="Curtin University developed a study abroad program targeted at current undergraduate students in their third or final year of study in the United States. "
        image_width="70%"
        image={cdn('/Case_studies/curtinGoGreater/go-greater-header-banner.jpg')}
        image_class="header-image"
        foot_image={cdn('/Case_studies/curtinGoGreater/go-greater-arrow-left.jpg')}
        foot_image_width="400px"
        foot_image_left="0"
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>The program’s goal is to offer a complete study abroad package to undergraduate students in affiliated U.S. colleges and universities, who intend to continue their studies in a graduate program, with the potential of earning credit towards their current undergraduate degree and in an area they intend to study at a postgraduate level.</p>

<p>OKMG was engaged to position Curtin University’s study abroad program as a distinctive and prestigious learning opportunity, welcoming and accelerating US pre-graduate students that are up for the challenge.</p>

<p>U.S. students looking to do study abroad programs do not see Australia as an advanced, career-building location to finish their degrees. The Covid pandemic has exacerbated this. Our distance and extended border closures have increased the perception of isolation and as a slow follower to other countries instead of a global leader.</p>

<p>The new study abroad program allows students to experience their potential graduate course before graduating from a top 1% ranked university worldwide, celebrated for innovation and creativity.</p>
<img src={cdn('/Case_studies/curtinGoGreater/go-greater-arrow-right.jpg')} className="arrow-right"/>
          </div>}
        />

       <ComponentVideo
          video_url={cdn('/Case_studies/curtinGoGreater/220217_Curtin+Mobility_Go+Greater+Animation_v3.mp4')}
          marginTop="100px"
          marginBottom="100px"
          autoplay="autoplay"
          loop="loop"
          />

          <ComponentCaseImageGifs 
            stats={images}
            marginTop="60px"
            width="70%"
            className="gif-wrap"
          />

           <ComponentFullBleedImage
          image={cdn('/Case_studies/curtinGoGreater/go-greater-full-bleed-one.jpg')}
          width="110%"
          marginTop="60px"
          className="full-bleed-one"
          />
           <ComponentFullBleedImage
          image={cdn('/Case_studies/curtinGoGreater/go-greater-full-bleed-two.jpg')}
          width="110%"
          marginTop="60px"
          className="full-bleed-two"
          />
          <div className="copy-two">
           <ComponentCaseCopy
           className="copy-two"
        content={
          <div>
            <p>The Go Greater concept plays into the Curtin Go Global brand, working to build brand equity and retention with the International department, inclusive of the new US program. </p>

            <p>Executed across various mediums, including social media assets, flyers, and a landing page, we brought forward human-centric elements within the imagery and graphic elements. Through this visual language, we were able to talk about the different opportunities available within the Go Greater campaign, spanning the different majors on offer and the life experiences unique to Western Australia. </p>

            <p>The Go Greater campaign positions Curtin Perth as an academic institution that provides students access to a top 1% ranked university worldwide, celebrated for innovation and creativity and to create memories and experiences that will enhance their value as students and global citizens.</p>
                      </div>}
        />
        </div>

         <ComponentFullBleedImage
          image={cdn('/Case_studies/curtinGoGreater/go-greater-full-bleed-three.jpg')}
          width="100%"
          marginTop="60px"
          className="full-bleed-three"
          />
  
           <ComponentFullBleedImage
          image={cdn('/Case_studies/curtinGoGreater/go-greater-footer.jpg')}
          width="100%"
          marginTop="60px"
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}