/*
  PerthBrainCenter.jsx
  @Component - Perth Brain Centre Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './PerthBrainCenter.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/PerthBrainCentre/square-one.jpeg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/PerthBrainCentre/square-two.jpg'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/PerthBrainCentre/square-three.png'),
    background_size: 'cover',
  }

]

const images_two = [
{
    id: 1,
    image: cdn('/Case_studies/PerthBrainCentre/PBC_FB Mockup %2B Stats.jpg'),
    width:'50%'
},
{
    id: 2,
    image: cdn('/Case_studies/PerthBrainCentre/PBC_FB Mockup-3.jpg'),
    width:'40%'
}
]


export default class PerthBrainCenter extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="perth-brain-center-wrapper">
      <MetaTags>
        <title>Perth Brain Centre - Neurofeedback Therapy - OKMG</title>
        <meta name="description" content="Perth Brain Centre - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/lever-property'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Perth Brain Centre" role="heading"  
        sub_content="Strategy | Marketing | Creative | Digital"
        service_info="With increasing interest in non-invasive and drug-free therapies for brain based disorders, Perth Brain Centre were perfectly positioned to take the lead in neurotherapy, neurofeedback and brain based therapies."
        // image={cdn('/Case_studies/PerthBrainCentre/perth-brain-animation-hjead.png')}
        hover_video_one={cdn('/Case_studies/PerthBrainCentre/pbc-animation-rz.mp4')}
        hover_videobg_one={cdn('/websiteScrolls/iMac_Mockup_v2.png')}
        image_width="110%"
        foot_image={cdn('/Case_studies/PerthBrainCentre/PERTH BRAIN CENTRE_GRAPHIC DEVICE.png')}
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>The Perth Brain Centre is WA’s most established clinic providing therapies to relieve a range of different brain based disorders. Though a growing industry there is still a large gap between the therapy available and the audience due to a lack of education.</p>
            <p>OKMG was engaged to build a brand and marketing strategy to provide the Perth Brain Centre with the platform to be recognised for their innovative therapy to treat brain disorders.</p>
            <p>This campaign was developed to address public perceptions and act as an ongoing source of education, aiming to further inform communication objectives and creative content for PBC.</p>
            <p>To position PBC as thought leaders within Neurotherapy, OKMG designed and created a website as well as additional articles and collateral that was both informative whilst being simple to navigate, to effectively explain the process and uses of Neuroplasticity.</p>
             <ComponentReadMore
             content={
              <span>
                <p>The growing interest in alternative treatment options for brain-based disorders, Perth Brain Centre saw an opportunity to become the leading advocate for neurofeedback therapy. As a relatively new science, the lack of awareness surrounding Neuroplasticity was a clear obstacle for disrupting a topical industry of brain disorder treatment.</p>
                <p>To become leaders in Neuroplasticity, we had to first educate the public on what it is. A compressive brand and marketing strategy was developed to launch the brand into market in an educational and emotive way.</p>
                <p>Recognising that people were actively seeing ‘brain based’ treatments, OKMG assisted PBC in developing a specific content release platform,  Neuroknowledge, to host research articles and opinion pieces. This platform was a strategic opportunity to cement authority in the digital space nationally, dominating the market prior to physical expansion of clinics.</p>
                <p>Perth Brain Centre has continued to grow and expand, grounded in the strategic brand foundations developed with OKMG. PBC has been established as thought leaders in the industry, where education and resources continue to be a key point of engagement with patients. In addition to ongoing management of the PBC website and Neuroknowledge platform, we have continued to find innovative ways to connect with new audience groups, with new clinics on the horizon. </p>
              </span>
            }
             />
          </div>}
        />
        <ComponentVideo
          video_url={cdn('/Case_studies/PerthBrainCentre/PBC_GET_STARTED-min.mp4')}
          loop="loop"
          marginTop="100px"
          controls="true"
          />

           <ComponentCaseImageGifs 
         stats={images_two}
         marginTop="100px"
         width="100%"
         wrapperClass="gif-wrapper-pbc"
        />

        <ComponentVideo
          video_url={cdn('/websiteScrolls/PBC2-rz.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          controls="true"
          screenCast
          />
         
           <ComponentFullBleedImage
          image={cdn('/Case_studies/PerthBrainCentre/brain.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />

          
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}