/*
  RevoFitness.jsx
  @Component - Revo Fitness Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './HairSupplies.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentToggleImage from '../_CaseComponents/ComponentToggleImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]
// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/HairSupplies/square-one.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/HairSupplies/square-two.png'),
    background_size: 'contain',
    background_repeat:'no-repeat',
    background_position: 'center',
    background_color: '#dadada'
  },
  {
    id: 3,
    image: cdn('/Case_studies/HairSupplies/square-three.jpg'),
    background_size: 'cover',
  }

]

const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/HairSupplies/2020.11.27 HAIR SUPPLIES-28-web.jpeg'),
    background_size: '600px',
    background_repeat:'no-repeat',
    background_position:'right',
    width:'50%',
    background_position:'right',
    className:'special-image-one'
  },
  {
    id: 2,
    image: cdn('/Case_studies/HairSupplies/Group 1320.svg'),
    background_size: '250px',
    background_repeat:'no-repeat',
    width:'40%',
    background_position:'center',
    className:'custom-height-mob'
  },
   {
    id: 2,
    image: cdn('Case_studies/HairSupplies/Hairdryer_brush_2%402x.png'),
    background_size: '300px',
    background_repeat:'no-repeat',
    width:'100%',
    background_position:'center',
    className:'image-absolute'
  },
  
  {
    id: 3,
    image: cdn('/Case_studies/HairSupplies/Group 1321.svg'),
    background_size: '300px',
    background_repeat:'no-repeat',
    width:'40%',
    background_position:'center',
    className:'custom-height-mob'

  },
  {
    id: 4,
    image: cdn('/Case_studies/HairSupplies/2020.10.12 BTS-15-web.jpeg'),
    background_size: '600px',
    background_repeat:'no-repeat',
    width:'50%',
    background_position:'left',
    className:'special-image'
  },


]

const images_gifs = [
   {
    id: 1,
    image: cdn('/Case_studies/TwinFin/image_1%402x.png'),
    width:'20%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/TwinFin/Swell Times IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/TwinFin/TWIN FIN_INSTA%402x.png'),
    width:'30%',
  },
  {
    id: 4,
    image: cdn('/Case_studies/TwinFin/Open Now IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 6,
    image: cdn('/Case_studies/TwinFin/image_1%402x.png'),
    width:'20%',
  },


]


export default class RevoFitness extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="hair-supplies-wrapper">
      <MetaTags>
        <title>Hair Supplies - Get the Look you want - OKMG</title>
        <meta name="description" content="Hair Supplies - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/hair-supplies'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Hair Supplies." role="heading"
        sub_content="Strategy | Digital | Creative | Marketing"
        service_info="An e-commerce led engagement, helping an East Fremantle hair retailer go national."
        intro_para="Driven by user experience, Revo Fitness has fast become an industry disruptor offering affordable and flexible gym memberships in an inclusive environment. With expansion at the forefront of business operations, Revo Fitness required an assertive brand identity that would facilitate growth and empower new and existing members to Own Their Fit."
        hover_image_two={cdn('/Case_studies/HairSupplies/HS_HOMEPAGE_TRADE.png')}
        hover_image_one={cdn('/Case_studies/HairSupplies/HS_HOMEPAGE_RETAIL.png')}
        image_width="100%"
        className="special"
        image_class="header-image"
        foot_image={cdn('/Case_studies/HairSupplies/HAIR SUPPLIES_BRAND ASSET-01%402x.png')}
        foot_image_width="350px"
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>After decades of providing dedicated professional experience and genuine customer service to a national client base, it was time for Hair Supplies to grow their online presence.  </p>
            <p>As their business evolved, Hair Supplies found themselves catering to two distinct customer groups, trade and retail. Their existing e-commerce platform lacked distinction between the two customer bases, resulting in a blurring of their product offering.  </p>
            <p>An invigorated digital platform was essential to serving these two distinctive audiences, without distilling their established brand and reputation for exceptional service.</p>
             <ComponentReadMore
             content={<span>
              <p>Despite being a smaller business within a highly competitive market, Hair Supplies has managed to do more than hold its own. Through their highly personable customer service, Hair Supplies have developed a reputation for delivering unparalleled value and knowledge to their national customer base. Pivoting with the times, the Hair Supplies team wanted to focus more on their online presence, requiring a website that would deliver that same genuine user experience and journey.</p>
              <p>OKMG was engaged to create a website to facilitate an effortless online user experience for the Hair Supplies loyal, and growing customer base, whilst maintaining the same high quality of experience provided in-store.</p>
              <p>The first step was to separate their existing website into two different websites from the back end that would not disrupt the user journey from the home page: one for Trade Customers and one for Retail. From there we designed two seamlessly linked websites, integrating the new trade website Shopify store with Retail Management Hero, a complete point-of-sale solution.</p>
              <p>As part of the broader strategy to ensure that Hair Supplies continued to connect with their audience, we filmed a series of videos to capture the Hair Supplies team’s warm and engaging nature. </p>
              <p>The new Hair Supplies websites meant an effortless customer’s user journey. Enhancing the shopping experience, especially for busy hair care professionals, allows them to shop and checkout efficiently for every day or even last minute hair products.</p>
              <p>In merging Shopify stores with Retail Management Hero we enabled the Hair Supplies team to efficiently manage all their back office, inventors and POS activities across several channels. We were able to perfectly embody the genuine and inviting customer service that the Hair Supplies team provides, giving them leverage within the generic online retail space. </p>
              </span>
            }
             />
          </div>}
        />
        <ComponentToggleImage 
        image_one={cdn('/Case_studies/HairSupplies/HAIR SUPPLIES_MOBILE_MOCKUP-2.jpg')}
        image_two={cdn('/Case_studies/HairSupplies/HAIR SUPPLIES TRADE_MOBILE_MOCKUP-2.jpg')}
        width="100%"
        marginTop="100px"
        left="100px"
        />

        <ComponentVideo
          video_url={cdn('/Case_studies/HairSupplies/hair-supplies-video.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
          {/* 
          <ComponentCaseImages
            marginTop="100px"
            stats={images_two}
            wrap


          />
        */}
          <ComponentFullBleedImage 
          image={cdn('/Case_studies/HairSupplies/HS_STATS BLOCK.jpg')}
          marginTop="100px"
          width="100%"
          />
          <ComponentFullBleedImage
          image={cdn('/Case_studies/HairSupplies/HAIR SUPPLIES_TRADE CATALOGUE_MOCKUP.jpg')}
          marginTop="100px"
          width="100%"
          />
          <ComponentCaseImages
          className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}