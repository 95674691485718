/*
  ScotchCollege.jsx
  @Component - ScotchCollege Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './Femma.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';
import Reveal from 'react-reveal/Fade';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';


// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/Femma/femma-box1.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Femma/femma-box2.png'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/Femma/femma-box3.png'),
    background_size: 'cover',
  }

]

const images_gifs = [
  {
   id: 1,
   image: cdn('/Case_studies/Femma/rfds-rec-1.png'),
   width:'30%',
 },
 {
   id: 2,
   image: cdn('/Case_studies/Femma/rfds-rec-2.png'),
   width:'30%',
 }

]

export default class ScotchCollege extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="Femma-wrapper">
      <MetaTags>
        <title>Femma - OKMG</title>
        <meta name="description" content="Femma is a digital women's healthcare service changing how women access health across Australia." />
        <link rel='canonical' href='https://okmg.com/work/femma'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Femma" role="heading"
        sub_content="Creative | Strategy | Digital"
        service_info="Femma is a digital women's healthcare service changing how women access health across Australia. OKMG was engaged at the beginning of Femma, responsible for creating their brand identity, a custom-built website and generating content for use across their website and social media."
        image={cdn('/Case_studies/Femma/femma-laptop.png')}
        image_icon={cdn('/Case_studies/Femma/femma-circle.svg')}
        image_class="header-image"
        image_width="100%"

        foot_image={cdn('/Case_studies/Femma/femma-side-v2.png')}
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>Femma is a clinically-led, evidence-based healthcare platform with a multi-disciplinary approach to diagnosing, treating, and educating women's health issues. With the mission to close the gap within women's healthcare, provide education, and remove any stigma or shame that comes with the variety of weird, wonderful, and completely normal health challenges women face every day. </p>
            <p>We collaborated closely with the Femma team to create an identity reflective of the aspirational and progressive change to health that Femma was embarking upon. </p>
            <p>We challenged the standard perceptions of tech within the health sector, which is usually more traditional, with colours of blues and greens. Instead, we opted for more organic shapes paired with a bright and zesty orange contrast, representing the aspirational trailblazing effect that Femma will have on the female health industry. To further elevate the brand identity, we had custom illustrations designed to represent the expansive range of women and their symptoms that Femma will service.</p>
            <p>The Femma branding was rolled out across the custom-built website, featuring a comprehensive brand toolkit that allows women quick and easy access to online consultations and on-demand information. </p>
            <p>We launched the Femma brand with curated video and photographic content to start the conversation around women's health, featuring 'real stories', focusing on hormonal changes, menopause, PCOS and periods to highlight the areas where people lack information and education. This content is to reassure that through Femma's effortless access to healthcare, you'll receive the caring, high-quality assistance you need and deserve every time.</p>
          </div>}
        />
        
        {/* <Reveal up>
          <ComponentCaseImageGifs 
            stats={images_gifs}
            marginTop="60px"
            width="80%"
            className="gif-wrap"
          />
        </Reveal> */}

          <ComponentFullBleedImage
          image={cdn('/Case_studies/Femma/femma-block.png')}
          marginTop="100px"
          width="70%"
          />


          <ComponentVideo
          video_url={cdn('/Case_studies/Femma/FEMMA_MENOPAUSE-reduced-size.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/Femma/femma-color.png')}
          marginTop="100px"
          width="50%"
          special_image={cdn('/Case_studies/Femma/femma-circle.svg')}
          special_class_wrapper="big-box"
          />
          <ComponentFullBleedImage
          image={cdn('/Case_studies/Femma/femma-font.png')}
          marginTop="80px"
          width="80%"
          // special_image={cdn('/Case_studies/Femma/femma-circle.svg')}
          // special_class_wrapper="big-box"
          />
          {/* <ComponentVideo
            video_url={cdn('/Case_studies/Femma/femma-education-vid.mp4')}
            autoplay="autoplay"
            loop="loop"
            marginTop="100px"
            // className="vid-bot"
          /> */}

          <ComponentFullBleedImage
          image={cdn('/Case_studies/Femma/femma-box-big2.png')}
          marginTop="150px"
          width="100%"
          // special_image={cdn('/Case_studies/Femma/femma-circle.svg')}
          // special_class_wrapper="big-box"
          />
          <ComponentCaseImages
            stats={images}
          />

          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}