/*
  RevoFitness.jsx
  @Component - Revo Fitness Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './CottesloeVillage.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]
// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/Cottesloe_village/square-one.jpg'),
    background_size: 'cover',
    background_color: 'green',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Cottesloe_village/square-two.png'),
    background_size: '80%',
    background_color: '#1875a9',
    background_repeat:'no-repeat'
  },
  {
    id: 3,
    image: cdn('/Case_studies/Cottesloe_village/2019.05.22-MAY-CONTENT-98-web.jpg'),
    background_size: 'cover',
    background_color: 'green',
  }

]





export default class CottesloeVillage extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="cott-village-wrapper">
        <MetaTags>
          <title>Cottesloe Village - Relax. You're in Cottesloe - OKMG</title>
          <meta name="description" content="Cottesloe Village - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
          <link rel='canonical' href='https://okmg.com/work/cottesloe-village'/>
        </MetaTags>
        <ComponentHeaderbanner
          heading="Cottesloe Village" role="heading"
          sub_content="Strategy | Creative | Marketing"
          service_info="Nestled within the leafy beachside suburb of Cottesloe lies the vibrant Cottesloe Village. An open-plan mecca for shopping, eating and socialising, Cottesloe Village needed to attract a larger audience through engaging promotional material, capturing the essence of Cottesloe."
          image={cdn('/Case_studies/Cottesloe_village/cott-village-flyers.jpg')}
          image_class="header-image"
          image_width="105%"
          foot_image={cdn('/Case_studies/Cottesloe_village/COTTESLOE VILLAGE_RED LOGO.svg')}
          foot_image_width="350px"
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>OKMG was engaged for a full brand and communications strategy including building campaigns to promote Cottesloe Village’s annual events.</p>
            <p>Despite being well known to those within the area, there was a lack of pull when promoting events taking place in the village. OKMG expanded upon the existing potential of Cottesloe Village to create a stronger brand identity to increase public awareness, cultivating a visceral relationship between the audience and Cottesloe Village.</p>
            <p>Designing and developing more consistent collateral as a solid foundation to build on each year, was a key component in our brand and communications strategy to structure campaigns promoting organised annual events, including the Cottesloe Village Street Party; achieved through eye-catching print and out-of-home assets as well as through far-reaching digital channels.</p>
            <p>OKMG is entering the second year of engagement with Cottesloe Village, excited to support a vibrant community organisation, at the heart of one of Perth’s most well known suburbs.</p>
           
          </div>}
        />
          
        <ComponentFullBleedImage 
          image={cdn('/Case_studies/Cottesloe_village/Group 1424.jpg')}
          width="100%"
          marginTop="100px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          />
          <ComponentFullBleedImage 
          image={cdn('/Case_studies/Cottesloe_village/COTTESLOE VILLAGE_FLOOR DECAL_MOCKUP.jpg')}
          width="100%"
          marginTop="100px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          special_class_wrapper="decal-mock"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/Cottesloe_village/COTTESLOE VILLAGE_BILLBOARD_MOCKUP.jpg')}
          marginTop="100px"
          width="100%"
          />
          <ComponentCaseImages
          className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}