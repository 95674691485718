/*
  BlobTwo.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentServicesBox.scss';
import Reveal from 'react-reveal/Reveal';
import BlobOne from './../blobOne';
// images
import cdn from '../../lib/cdn';
import Lottie from 'react-lottie';

export default class ComponentServiceBox extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      isStopped: false, 
      isPaused: true
   
    };
  }

  handleMouseEnter(){
    this.setState({isPaused: false})
  }
  handleMouseOut(){
    this.setState({isPaused: !this.state.isPaused})
  }

  handleMouseEnterVid(event){
    let video = event.currentTarget;
    video.playbackRate = 0.65;
    setTimeout(function(){ 

     video.pause();

    }, 1500);

    
  } 
  handleMouseOutVid(event){
    let video = event.currentTarget;
    video.playbackRate = 1;
    video.play();
  }

  render(){

   const {
      left,
      top,
      right,
      bottom,
      colour,
      width,
      height
      } = this.props;

  return (
      <div className="component-services-box">
        <div className="sub sub-one">
          <div className="sub-sub-one">
            <Reveal effect="fadeInUp">
              <h2>One size <br/>never fits all.</h2>
            </Reveal>
            <Reveal effect="fadeInUp">
            <p>We understand that not all challenges are created equal. We live in an increasingly personalised and hyper connected world where people expect everything to be a tailored fit.  This combined with a constant state of change in almost every market is forcing companies to be more human centric and be driven by a clear purpose. </p>
            <p>We work with our clients to identify and prioritise their most important challenges that will have the biggest impact and commit to solving these together. For every client, that’s a different problem. With every client, we find a different solution!  </p>
            <p>Our purpose is to create high impact integrated brand experiences guided by strategic thinking, informed by data and centered around the customer.  </p>
            </Reveal>
          </div>
          <div className="sub-sub-two" onMouseEnter={this.handleMouseEnter.bind(this)} onMouseLeave={this.handleMouseOut.bind(this)}>
            <Reveal effect="fadeInUp">
                <video loop muted playsInline="playsInline" autoPlay="autoPlay" onMouseEnter={this.handleMouseEnterVid.bind(this)} onMouseLeave={this.handleMouseOutVid.bind(this)}>
                  <source src={cdn('/Kaleidoscope-02.webm')} type="video/webm" />
                  Your browser does not support the video tag.
                </video>
            </Reveal>
          </div>
        </div>
      
      </div>
    );
  }
}