/*
  BlobOne.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentCaseStats.scss';
import Reveal from 'react-reveal/Reveal';


export default class ComponentCaseStats extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  render(){

   const {
      stats,
      has_image,
      image_top,
      marginTop
      } = this.props;

  return (
      <div className="component-case-stats-wrapper" style={{marginTop:marginTop}}>
        {
          stats.map(info =>

            <div className={info.has_image ? 'has_image ' + 'stat-wrap' : 'stat-wrap'} key={info.id} style={{backgroundColor: info.color}}>
            {info.image_left ? <img src={info.image_left} className="image-left" style={{maxWidth:info.image_width, top:image_top}} /> : ''}
              <span className="stat-sub-wrap">
                  <h4>{info.amount}</h4>
                  <p>{info.text}</p>
              </span>
              {info.image_right ? <img src={info.image_right} className="image-right" style={{maxWidth:info.image_width,top:image_top}}/> : ''}
            </div>
          )
        }
      </div>
  );
  }
}