/*
  PrideWa.jsx
  @Component - Pride WA Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './PrideWa.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]
// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/Pride_wa/Naomi_Reed_Photography-3400-web-b2.jpeg'),
  },
  {
    id: 2,
    image: cdn('/Case_studies/Pride_wa/square-two.jpg'),
  },
  {
    id: 3,
    image: cdn('/Case_studies/Pride_wa/DSCF9832-web-v2.jpeg'),
  }

]




const image_full = [
  {
    id: 1,
    image: cdn('/Case_studies/Pride_wa/pride-guider.jpg'),
    className:'pm-img'
  }
]


export default class PrideWa extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="pridewa-wrapper">
      <MetaTags>
        <title>Pride WA - A community, arts and cultural organisation - OKMG</title>
        <meta name="description" content="Pride WA - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/pride-wa'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Pride WA" role="heading"
        sub_content="Digital | Creative | Marketing | Community"
        service_info="Pride Festival is a state wide celebration of love, inclusion and diversity. An integrated platform was required to drive more awareness to the festival and accurately reflect the growth of PrideFEST."
        image={cdn('/Case_studies/Pride_wa/pride-ipad-v2.jpg')}
        image_class="header-image"
        image_width="120%"
        foot_image={cdn('/Case_studies/Pride_wa/Group 1364.svg')}
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>Pride WA has been an essential part of the Western Australian community since 1989. Grown from a community movement protesting laws that actively discriminated against members of the LGBTQI  community Pride WA has steadily grown, now featuring the annual festival, PrideFest.</p>
            <p>With 2019’s theme was Reflect: Heroes, Community, Identity, Love plus marking the 30th anniversary of PrideFEST, it had high stakes to be the biggest celebration of the West Australian LGBTQI  community so far.</p>
            <p>OKMG was engaged to create a greater WA community awareness campaign for PrideFEST. Through a revisioning of their logo and creating programs to ensure full coverage of the festival but also to create a platform where acceptance is necessary. Linking larger sponsors to the festival through our stakeholder management securing the longevity and success of PrideFEST 2019.</p>
             <ComponentReadMore
             content={
              <span>
                <p>2019 marked the 30th anniversary milestone for PrideFEST. This placed even more significance on celebrating how far the LGBTQI  community has come, but also raising awareness on how far we as a society have to go.</p>
                <p>From modifying their logo, creating a program that was both educational and entertaining and overseeing event coverage.</p>
                <p>OKMG was challenged to explore this theme of Reflect, looking back on the history of Pride and the LGBTIQ  community within Western Australia, whilst building upon a brand focused on the future of acceptance.</p>
                <p>Creating a PrideFEST Program that aimed both to educate and inform on the diverse and exciting events within the PrideFEST Calendar and modifying the Pride WA logo to have a more refined look, applied across multiple platforms from print to social media. </p>
                <p>OKMG approached this campaign with thoughtful consideration, applying comprehensive marketing and public relations strategies, overseeing the festival throughout its entirety across multiple levels from creative direction to marketing to event coverage. Larger sponsors were engaged through our stakeholder management, paired with our campaign creative this fuelled Pride WA to grow even further, giving it longevity.</p>
                <p>2019 was PrideFEST’s most successful year so far, generating an impressive 100% increase in sponsors and over 600 new members of the Pride WA community. The 2019 Pride Parade was also the largest to date, with over 110 floats and 5000 people marching in solidarity of the LGBTQI  community. </p>
                <p>PrideFEST not only allows people to celebrate their culture in a safe environment but is also an integral part of the Perth evolving narrative. The entire OKMG team was so proud to be involved and are very excited to contribute to the future growth of Pride WA.</p>
              </span>
            }
             />
          </div>}
        />

{/* 
        <ComponentFullBleedImage 
          image={cdn('/Case_studies/Pride_wa/PRIDE_GUIDE_MOCKUP.png')}
          width="calc(100%   200px)"
          marginTop="50px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          left="-100px"
          /> */}
          <ComponentCaseImageGifs
            stats={image_full}
            marginTop="100px"
            special_class_wrapper="pm-img-full-wrap"
          />


        <ComponentVideo
          video_url={cdn('/Case_studies/Pride_wa/PRIDE_Parade_Highlights_90SEC_16-9_EDIT%2BV1-Trimmed-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />

          <ComponentFullBleedImage 
          image={cdn('/Case_studies/Pride_wa/PRIDE_STATS BLOCK.jpg')}
          width="100%"
          marginTop="50px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"

          />
          <ComponentFullBleedImage 
          image={cdn('/Case_studies/Pride_wa/PRIDE_POSTER_MOCKUP-1.jpg')}
          width="100%"
          marginTop="100px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"

          />
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pride-wa"
          />
     </div>
    );
  }
}