/*
  ECC.jsx
  @Component - Edith Cowan College Case Study
*/

import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ECC.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/ECC/square-one.jpeg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/ECC/square-two.jpeg'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/ECC/square-three.jpeg'),
    background_size: 'cover',
  }

]


const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/ECC/46Kunique clickson Facebook& Instagram.jpg'),
    width:'15%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/ECC/ECC_FB Posts.jpg'),
    width:'50%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/ECC/96Kthruplaysacross thecampaign.jpg'),
    width:'15%',
  },

];

const stat_info = [
  
  
  {
    id: 1,
    amount: '"The Buzz has become a favourite among staff and students and this is thanks to the creativity of the OKMG team. They are very focused on the client and really take the time to understand the business/industry that we\'re in, to ensure the content is relevant and successful. I really enjoy working with them as they are very approachable and flexible!"',
    text: 'Sophie Waddell, Marketing Manager - South East Asia',
    color: 'rgb(10 78 132)',
  },
  {
    id:2,
    image_left:cdn('/Case_studies/ECC/ECC_PHONE MOCKUP.png'),
    has_image: true,
    image_width:'460px',
    color: 'rgb(10 78 132)',
  },

];

export default class ECC extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="ecc-wrapper">
      <MetaTags>
        <title>Edith Cowan College - Your Direct Pathway to ECU  - OKMG</title>
        <meta name="description" content="Edith Cowan College - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/edith-cowan-college'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Edith Cowan College" role="heading"  
        sub_content="Creative | Marketing"
        service_info="Edith Cowan College (ECC), a pathway college for Edith Cowan University. The international education market is a highly competitive industry and it has become imperative to stand out from the crowd. "
        hover_image_two={cdn('/Case_studies/ECC/2018.02.21-ECC-on-set-photos-31-of-83-web.jpeg')}
        hover_image_one={cdn('/Case_studies/ECC/2018.04.27-ECC-Perth-City-Shoot-1-of-13-web.jpeg')}
        hover_image_three={cdn('/Case_studies/ECC/2018.02.21-ECC-on-set-photos-25-of-83-web.jpeg')}
        image_width="100%"
        className="special"
        image_class="header-image"
        foot_image={cdn('/Case_studies/HairSupplies/HAIR SUPPLIES_BRAND ASSET-01%402x.png')}
        foot_image_width="350px"
        foot_image={cdn('/Case_studies/ECC/The Buzz Logo.gif')}
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>OKMG was engaged in early 2019 to conceptualise and produce a mini web series for Edith Cowan College. </p>
            <p>The purpose of the web series was to showcase ‘the vibe’ of student life on ECC’s campus, in a unique way by creating relatable and enjoyable video content that a younger and vastly diverse student audience would find engaging and whilst remaining informative and educational. </p>
            <p>OKMG accurately captured campus life at ECC in 12 highly engaging web series episodes, covering topics that aimed to inform such as the shift to virtual learning in the wake of COVID-19 and to entertain covering everything from Halloween to exam stress. We ensured that there was a constant balance between scripted and candid moments within the series to really give international prospective students a taste of attending university within Australia.</p>
            <p>The Buzz, your guide to campus life.</p>
          </div>}
        />

        <ComponentVideo
          video_url={cdn('/Case_studies/ECC/ECC_THE_BUZZ-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />

          <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="100px"
          />

          <ComponentCaseStats
            stats={stat_info}
            has_image='true'
          />

           <ComponentFullBleedImage
          image={cdn('/Case_studies/ECC/200611_EDITH COWAN COLLEGE_FIND YOUR PLACE_INDONESIA-02-web.jpeg')}
          width="100%"
          marginTop="0px"
          marginTop="200px"
          />
  
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}