/*
  ChaseResidential.jsx
  @Component - ChaseResidential Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ChaseResidential.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]
// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/ChaseResidential/hunter-capital-small-image-one.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/ChaseResidential/hunter-capital-small-image-two.jpg'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/ChaseResidential/hunter-capital-small-image-three.jpg'),
    background_size: 'cover',
  }

]

const images_squares = [
   {
    id: 1,
    image: cdn('/Case_studies/ChaseResidential/hunter-capital-hallifax.jpg'),
    background_size: '300px',
    background_repeat:'no-repeat',
    background_position:'left bottom',
    width:'700px',
    className:'sp-image-one'
  },
  {
    id: 2,
    image: cdn('/Case_studies/ChaseResidential/hunter-capital-angove-west.jpg'),
    background_size: '300px',
    background_repeat:'no-repeat',
    width:'700px',
    background_position:'right bottom',
    className:'sp-image-two'
  },

]

const images_squares_two = [
   {
    id: 1,
    image: cdn('/Case_studies/ChaseResidential/hunter-capital-hallifax-folder.jpg'),
    background_size: '700px',
    background_repeat:'no-repeat',
    background_position:'bottom left',
    width:'750px',
    className:'sp-image-one'
  },
  {
    id: 2,
    image: cdn('/Case_studies/ChaseResidential/hunter-capital-angove-west-folder.jpg'),
    background_size: '700px',
    background_repeat:'no-repeat',
    width:'750px',
    background_position:'bottom',
    className:'sp-image-two'
  },

]

const images_gifs = [
   {
    id: 1,
    image: cdn('/Case_studies/ChaseResidential/image_1%402x.png'),
    width:'20%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/ChaseResidential/Swell Times IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/ChaseResidential/Chase Residential_INSTA%402x.png'),
    width:'30%',
  },
  {
    id: 4,
    image: cdn('/Case_studies/ChaseResidential/Open Now IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 6,
    image: cdn('/Case_studies/ChaseResidential/image_1%402x.png'),
    width:'20%',
  },


]


export default class ChaseResidential extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="chase-residential-wrapper">
      <MetaTags>
        <title>Chase Residential - Fast-casual fish & chips - OKMG</title>
        <meta name="description" content="Chase Residential - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/ChaseResidential'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Chase Residential" role="heading"
        sub_content="Creative"
        service_info="Chase Residential approached us to challenge the Fremantle harbour seafood giants, giving a fresh twist to fish & chips."
        image={cdn('/Case_studies/ChaseResidential/chase-residential-header-image.jpg')}
        image_class="header-image"
        image_width="100%"
        foot_image={cdn('/Case_studies/ChaseResidential/chase-residential-crumb.jpg')}
        foot_image_width="200px"
        foot_image_left="0"
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>OKMG was approached to develop the concept of a new venue with a core offering of affordable, no-fuss fish & chips. With existing outlets catering to a loyal, mature audience, OKMG worked with Chase Residential to challenge the harbour giants, by creating a brand with a twist on fish & chips. </p>
            <p>Requiring a full brand identity applied across both traditional and digital collateral including a website, content creation and a digital campaign strategy. We took to this blank slate challenge with great enthusiasm, all hands on deck.</p>
            <p>The result was Chase Residential, a place for anyone, any time of day, any season. Oh, and did we mention that there’s free ice-cream?</p>
             <ComponentReadMore
             content={
              <span>
               <p>Without having a deep history to ground in, Chase Residential underwent a rigorous naming process, to find a strong brand concept that would cut through the market, and represent the vibrancy of the brand.</p>
               <p>After several workshops, ‘Chase Residential Fish & Chips’ was born. This name became the centerpiece of the full brand identity, giving legs to the core brand values through personality filled messaging and visuals.</p>
               <p>After growing the fun and approachable identity on paper, it was time to bring this to life visually. We developed a full suite of core logos, graphic devices, treatments, colours, fonts which conveyed the voice of this exciting new restaurant. A quirky mix of fun and no fuss.</p>
               <p>The Chase Residential identity was applied in-store through digital menu screens, as well as store signage, wall vinyls, merchandise and more. This identity seamlessly carried over to the website, designed specifically to represent the classic offering in an accessible way.</p>
               <p>Structured to be both fun and informative, we captured online traffic and pointed them in the right direction; through the front door.</p>
               <p>Through distinct branding and immersive content we were able to embody the cheeky personality of the brand, building a solid relationship between Chase Residential and the Fremantle community long before opening day.</p>
               <p>With brand awareness and visitation as our key objectives, the OKMG creative and in-house video department worked closely to concept and deliver a hero launch video that brought the  brand tone and voice to life, kicking off the first digital campaign.</p>
               <p>Grounded in a strong core identity, Chase Residential secured the position as a leading provider of fish & chips, launching this exciting venue as a fast-casual fish & chip shop that creates its own rules.</p>
              </span>
            }
             />
          </div>}
        />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/ChaseResidential/chase-resindential-logo.jpg')}
          marginTop="100px"
          width="300px"
          />
          <ComponentFullBleedImage
          image={cdn('/Case_studies/ChaseResidential/chase-residential-flyer.jpg')}
          marginTop="100px"
          width="90%"
          />
          {/*}
          <ComponentCaseImages
            stats={images_squares}
            className="square-images"
          />
          <ComponentCaseImages
            stats={images_squares_two}
            className="square-images-two"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/ChaseResidential/hunter-capital-website-image.jpg')}
          marginTop="100px"
          width="70%"
          />
    
          
          <ComponentFullBleedImage
          image={cdn('/Case_studies/ChaseResidential/hunter-capital-main-image.jpg')}
          marginTop="100px"
          width="100%"
          />
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />
        */}
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}