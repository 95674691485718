/*
  RevoFitness.jsx
  @Component - Revo Fitness Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './iga.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const image_one = [
   {
    id: 1,
    image: cdn('/Case_studies/IGA/iga-stats-one.png'),
    background_size: '35%',
    background_color: '#fff',
    background_repeat:'no-repeat'
  },
]

const image_two = [
 {
    id: 1,
    image: cdn('/Case_studies/IGA/Group 1423.svg'),
    background_size: 'contain',
    background_color: '#fff',
    background_repeat:'no-repeat'
  },
]

const image_three = [
   {
    id: 1,
    image: cdn('/Case_studies/IGA/iga-stats-two-v2.png'),
    background_size: '30%',
    background_color: '#fff',
    background_repeat:'no-repeat'
  },
]

const multi_images = [
 {
    id: 1,
    image: cdn('/Case_studies/IGA/IGA_HOME_MOBILE.png'),
    width: 'calc(33.33% - 15px)'
  },
  {
    id: 1,
    image: cdn('/Case_studies/IGA/IGA_HOME_MOBILE-2-v2.png'),
    width: 'calc(33.33% - 15px)'
  },
  {
    id: 1,
    image: cdn('/Case_studies/IGA/IGA_PRODUCTS_MOBILE.png'),
    width: 'calc(33.33% - 15px)'
  },
]


// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/IGA/iga-img2@2x.png'),
    background_size: 'cover',
    background_color: 'green',
  },
  {
    id: 2,
    image: cdn('/Case_studies/IGA/iga-img-3@2x.png'),
    background_size: 'cover',
    background_color: 'green',
  },
  {
    id: 3,
    image: cdn('/Case_studies/IGA/iga-img-4@2x.png'),
    background_size: 'cover',
    background_color: 'green',
  }

]


export default class IGA extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="iga-wrapper">
      <MetaTags>
        <title>IGA - Shop Online with IGA - OKMG</title>
        <meta name="description" content="IGA - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/iga'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="IGA" role="heading"
        sub_content="Strategy | Digital | Creative | Marketing"
        service_info="IGA, a chain of independently owned and operated grocery stores, offering fresh, local produce. A seamless e-commerce platform was required to link a group of IGA stores located in Perth, accurately reflecting their dedication to customer service."
        images={multi_images}
        image_class="header-image"
        foot_image={cdn('/Case_studies/IGA/Group 1421.svg')}
        />
         <ComponentCaseCopy
        content={
          <div>
          <p>IGA stores offer communities access to fresh, delicious local produce with the advantage of being nestled within the community. A collective of local IGA stores required an upgraded e-commerce platform that mirrored the exceptional customer experience in-store.</p>
          <p>Fuelled by the insight that the  independent grocery market sector remains best positioned to achieve personalisation at scale by retaining authentic and local relationships with customers, we were able to facilitate tailored marketing offers for customers.</p>
          <p>OKMG launched a digital transformation strategy, activating a new revenue stream via an online shopping experience supported by worldsmart and myfoodlink. IGA’s online store was deployed across web, iOS and Android app experiences. In addition to this, OKMG ideated a sophisticated inbound funnel spanning SEM, SMM and SEO acquiring new customers as well as a multi-tiered email marketing strategy leveraging existing customer groups into loyal brand advocates via a series of value-driven offers.</p>
          <p>Leveraging brand pillars of quality, convenience and community, OKMG aligned brand development and digital transformation initiatives prompting a humanisation in the end to end digital shopping experience. Grocery shopping online, memories made fresh.</p>
          </div>}
        />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/IGA/iga-stats.jpg')}
          marginTop="50px"
          />
           <ComponentVideo
          video_url={cdn('/Case_studies/IGA/IGA_Click %26 Collect_30SEC 16-9_EDIT V1-v2-web.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          controls="false"
          />

          <ComponentCaseImages
            stats={image_one}
          />
             <ComponentFullBleedImage
          image={cdn('/Case_studies/IGA/iga-phone.jpg')}
          width="140%"
          marginTop="0px"
          />
          <ComponentCaseImages
            stats={image_three}
          />
          <ComponentFullBleedImage
          image={cdn('/Case_studies/IGA/IGA BUS STOP SIGN.jpg')}
          width="100%"
          marginTop="50px"
          />
          <ComponentCaseImages
          className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="iga"
          />
     </div>
    );
  }
}