/*
  Farmersjsx
  @Component - Farmers Case Study
*/

import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './Farmers.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]
// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/4Farmers/4farmers-small-image-one.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/4Farmers/4farmers-small-image-two.jpg'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/4Farmers/4farmers-small-image-three.jpg'),
    background_size: 'cover',
  }

]

const images_squares = [
   {
    id: 1,
    image: cdn('/Case_studies/4Farmers/4farmers-old-logo.jpg'),
    background_size: '200px',
    background_repeat:'no-repeat',
    background_position:'center',
    width:'350px',
    className:'sp-image-one'
  },
  {
    id: 2,
    image: cdn('/Case_studies/4Farmers/4farmers-new-logo.jpg'),
    background_size: '200px',
    background_repeat:'no-repeat',
    width:'330px',
    background_position:'center',
    className:'sp-image-two'
  },

]

const images_gifs = [
   {
    id: 1,
    image: cdn('/Case_studies/Farmers/image_1%402x.png'),
    width:'20%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Farmers/Swell Times IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/Farmers/Farmers_INSTA%402x.png'),
    width:'30%',
  },
  {
    id: 4,
    image: cdn('/Case_studies/Farmers/Open Now IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 6,
    image: cdn('/Case_studies/Farmers/image_1%402x.png'),
    width:'20%',
  },


]


export default class Farmers extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="four-farmers-wrapper">
      <MetaTags>
        <title>4Farmers - OKMG</title>
        <meta name="description" content="4Farmers - Digital and creative engagement developed across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/4farmers'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="4Farmers" role="heading"
        sub_content="Digital | Creative"
        service_info="4Farmers is quite simply… for farmers. They manufacture, import and distribute a wide range of quality agricultural chemicals direct to farmers, ensuring that they get a fair and competitively priced product."
        image={cdn('/Case_studies/4Farmers/4farmers-header-image.png')}
        main_image_position_right="-25px"
        image_class="header-image"
        image_width="100%"
        foot_image={cdn('/Case_studies/4Farmers/4farmers-crumb.jpg')}
        foot_image_width="200px"
        foot_image_left="0"
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>4Farmers is an Australian-owned business, established in 1995 due to the concern for WA farmers paying a high cost for farm chemicals. Over the past 25 years, they’ve developed a respected name as the go-to for high quality products you can trust, at an affordable price. 4Farmers came to OKMG with a strong brand essence which was not consistently conveyed digitally or across their product packaging. </p>
            <p>In our brand audit we discovered multiple iterations of their visual identity and our approach was to respect the journey of the brand, refine the logo and graphic device into a cleaner, more confident and more identifiable presentation. We modernised the logo, brightened the colour palette, focussing on legibility and heroed the iconic Australia outline. It was important to the company to retain the unique brand loyalty that farmers had come to recognise and trust. Our refined brand work was applied to the website, marketing collateral and also across product labelling. </p>
            
          </div>}
        />

          <ComponentCaseImages
            stats={images_squares}
            className="square-block-images"

          />
          <ComponentVideo
          video_url={cdn('/Case_studies/4Farmers/4farmers-screen-cast.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          screenCast
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/4Farmers/4farmers-flyer.jpg')}
          marginTop="100px"
          width="100%"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/4Farmers/4farmers-flyer-two.jpg')}
          marginTop="100px"
          width="80%"
          />
     
         
        
 
           <ComponentFullBleedImage
          image={cdn('/Case_studies/4Farmers/4farmers-main-image.jpg')}
          marginTop="100px"
          width="100%"
          />
          
          
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}