/*
  BlobOne.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentCaseImageGifs.scss';
import Reveal from 'react-reveal/Reveal';


export default class ComponentCaseImageGifs extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  render(){

   const {
      stats,
      marginTop,
      width,
      wrap,
      left,
      wrapperClass,
      special_image,
      special_class_wrapper,
      } = this.props;

  return (
    <div className={special_class_wrapper}>
      {special_image ? <img src={special_image} className={special_class_wrapper}/> : ''}
      <div className={wrapperClass ? wrapperClass + ' component-case-images-gifs-wrapper' : 'component-case-images-gifs-wrapper'} style={{marginTop:marginTop,width:width,flexWrap:wrap ? 'wrap' : '',left:left}}>
        {
          stats.map(images =>
            <img src={images.image} style={{width:images.width,borderRadius:images.borderRadius,boxShadow:images.box_shadow}} className={images.className}/>
            )
          }
      </div>
    </div>
  );
  }
}