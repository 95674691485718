/*
  BlobTwo.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './blobTwo.scss';
import Fade from 'react-reveal/Fade';
// images


export default class BlobTwo extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  render(){

   const {
      left,
      top,
      right,
      bottom,
      colour,
      width,
      height
      } = this.props;

  return (
      <svg className="blob-two" style={{left:`${left}`,right:`${right}`,top:`${top}`,bottom:`${bottom}`,width:`${height}`,}} viewBox="0 0 294 332" version="1.1">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Homepage---hi-fi---1B" transform="translate(-1599.000000, -416.000000)" fill={colour}>
            <path d="M1890.63706,577.083942 C1890.63706,667.153391 1859.90359,745 1769.83414,745 C1679.76469,745 1599.50203,667.153391 1599.50203,577.083942 C1599.50203,487.014493 1679.76469,414 1769.83414,414 C1859.90359,414 1890.63706,487.014493 1890.63706,577.083942" id="Blob-6" transform="translate(1745.069545, 579.500000) rotate(6.000000) translate(-1745.069545, -579.500000) "></path>
        </g>
    </g>
</svg>
    );
  }
}