/*
  ComponentFooter.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import Plx from 'react-plx';
import './ComponentCtaFooter.scss';
import BlobOne from './../blobOne';
import Reveal from 'react-reveal/Reveal';
// images

// video


export default class ComponentCtaFooter extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  render(){

   const {
      heading,
      blob_color,
      content,
      contact_default
    } = this.props;

  return (
   <div className="component-cta-footer">

      <div>
        <Link to="/contact" >
          <span>
            <Reveal effect="fadeInUp">
            <div className="reveal-link">
              <span>
                <h3>{heading}</h3>
                <h3>{contact_default ? contact_default : "Contact us."}</h3>
              </span>
            </div>
            </Reveal>
            <Reveal effect="fadeInUp">
              <p>{content ? content : ""}</p>
            </Reveal>
          </span>
        </Link>
        <Reveal effect="fadeInUp">
          <div className="contact-links">
              <Link to="/contact" className="button">Contact Us</Link>
          </div>
        </Reveal>
        <div className="footer-wrapper">
          <div className="left-content">
         <div className="row row-four">
            <ul className="social-links">
            <Reveal effect="fadeInUp">
            <li><a href="https://www.facebook.com/OKMGAustralia" target="_blank" rel="noreferrer">Facebook</a></li>
            </Reveal>
            <Reveal effect="fadeInUp">
            <li><a href="https://www.instagram.com/okmg.agency/?hl=en" target="_blank" rel="noreferrer">Instagram</a></li>
            </Reveal>
            <Reveal effect="fadeInUp">
            <li><a href="https://www.youtube.com/channel/UC8CFbRXOKKu37F25p7aKhRA?feature=emb_subscribe_title" target="_blank" rel="noreferrer">Youtube</a></li>
            </Reveal>
            <Reveal effect="fadeInUp">
            <li><a href="https://www.linkedin.com/company/okmg/?originalSubdomain=au" target="_blank" rel="noreferrer">Linkedin</a></li>
            </Reveal>
            </ul>
         </div>
         <div className="bottom-content">
          <ul>
            <Reveal effect="fadeInUp">
              <li>Copyright © {(new Date().getFullYear())}</li>
            </Reveal>
            <Reveal effect="fadeInUp">
            <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
            </Reveal>
            <Reveal effect="fadeInUp">
              <li><Link to="/privacy-policy">Privacy</Link></li>
            </Reveal>
            <Reveal effect="fadeInUp">
              <li><Link to="/disclaimer">Disclaimer</Link></li>
            </Reveal>
            <Reveal effect="fadeInUp">
            <li><Link to="/payments">Payments</Link></li>
            </Reveal>
            <Reveal effect="fadeInUp">
            <li><Link to="/careers">Careers</Link></li>
            </Reveal>
           </ul>
       </div>
       </div>
       </div>
       <BlobOne
        colour={blob_color}
         left="auto"
         right="0"
         top="150px"
         bottom="auto"
         width="100vw"
        />
      </div>

       
    </div>
    );
  }
}