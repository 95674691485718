/*
  ComponentInternalFullWidthLinks.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentInternalFullWidthLink.scss';
import Reveal from 'react-reveal/Reveal';
import Marquee from 'react-double-marquee';

import cdn from '../../lib/cdn';
// images


export default class ComponentInternalFullWidthLink extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  render(){

   const {
      left_button,
      right_button,
      l_color,
      l_scroll_content,
      l_link_text,
      l_link_target,
      r_color,
      r_scroll_content,
      r_link_text,
      r_link_target,
      r_left_position,
      no_reveal,
      crumb
      } = this.props;

  return (
      <div className="component-full-width-link">
      {crumb ? 
        <img src={crumb} className="crumb" alt="crumb"/>
        : 
        ''
      }
        {
          // if prop left button render left button
          left_button ? 
          // if prop no reveal dont add animation
          no_reveal ? 

            <div className="left-button-link-wrapper">
              <div className="button-wrapper sub sub-one" style={{backgroundColor:l_color}}>
                <div className="link-wrap">
                  <Link to={l_link_target}>{l_link_text}</Link>
                </div>
              </div>
               <div className="tech-slide-wrap">
                <div
                    style={{
                      width: '100%',
                      whiteSpace: 'nowrap',
                    }}
                    className="tech-slideshow"
                  >
                    <div className="mark">
                      <div className="sub-mark">
                        {l_scroll_content}
                      </div>
                    </div>
                  </div>
              </div>
              <div className="sub sub-two">
               
              </div>
            </div>
            : 
            <Reveal effect="fadeInUp">
              <div className="left-button-link-wrapper">
                <div className="button-wrapper sub sub-one" style={{backgroundColor:l_color}}>
                  <div className="link-wrap">
                    <Link to={l_link_target}>{l_link_text}</Link>
                  </div>
                </div>
                 <div className="tech-slide-wrap">
                 <div
                    style={{
                      width: '100%',
                      whiteSpace: 'nowrap',
                    }}
                    className="tech-slideshow"
                  >
                    <div className="mark">
                      <div className="sub-mark">
                        {l_scroll_content}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sub sub-two">
                 
                </div>
            </div>
          </Reveal>
          
          :
          ''
        }
        {
          // if prop right button render button on right
          right_button ?
          // if prop no reveal dont add animation
          no_reveal ? 
            
              <div className="right-button-link-wrapper">
              <div className="sub sub-two">
              </div>
                <div className="tech-slide-wrap">
                 <div
                    style={{
                      width: '100%',
                      whiteSpace: 'nowrap',
                    }}
                    className="tech-slideshow"
                  >
                    <div className="mark-alt">
                      <div className="sub-mark-alt">
                      {r_scroll_content}
                      </div>
                    </div>
                  </div>
                </div>
                 <div className="button-wrapper sub sub-one" style={{backgroundColor:r_color}}>
                  <div className="link-wrap">
                    <Link to={r_link_target}>{r_link_text}</Link>
                  </div>
                </div>
              </div>
          : 
          <Reveal effect="fadeInUp">
            <div className="right-button-link-wrapper">
              <div className="sub sub-two">
                 
                </div>
                <div className="tech-slide-wrap">
                  <div
                    style={{
                      width: '100%',
                      whiteSpace: 'nowrap',
                    }}
                    className="tech-slideshow"
                  >
                    <div className="mark-alt">
                      <div className="sub-mark-alt">
                      {r_scroll_content}
                      </div>
                    </div>
                  </div>
                </div>
                 <div className="button-wrapper sub sub-one" style={{backgroundColor:r_color}}>
                  <div className="link-wrap">
                    <Link to={r_link_target}>{r_link_text}</Link>
                  </div>
                </div>
              </div>
          </Reveal>
          :
          ''
        }
      </div>
    );
  }
}