/*
  ComponentFullBleedImage.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentCaseCopy.scss';
import Reveal from 'react-reveal/Reveal';
// images


export default class ComponentCaseCopy extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  render(){

   const {
    heading,
    content,
    marginTop,
    crumb,
    crumb2,
    position,
    foot_image,
    foot_image_width,
    foot_image_left
      } = this.props;

  return (
      <div className="component-case-copy-wrapper" style={{marginTop: marginTop, position: position}}>
      {foot_image ?
          <img src={foot_image} alt="header foot image" className="foot-image sux" style={{maxWidth:foot_image_width,width:'100%',left:foot_image_left}} />
        :
        ''
      }
      {crumb ? 
        <img src={crumb} className="crumb"/>
        : ''
      }
      {crumb2 ? 
        <img src={crumb2} className="crumb2"/>
        : ''
      }
        <div className="sub sub-one">
            <h4>{heading}</h4>
        </div>
        <div className="sub sub-two">
            {content}
        </div>
      </div>
    );
  }
}