/*
  LeverProeprty.jsx
  @Component - Lever Property Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './LeverProperty.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/Lever_property/IMAGE_01.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Lever_property/square-two.png'),
    background_repeat: 'no-repeat',
    background_size: 'contain',
    background_color:'#e1e1e1'
  },
  {
    id: 3,
    image: cdn('/Case_studies/Lever_property/IMAGE_02.png'),
    background_size: 'cover',
  }

]
// for images
const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/Lever_property/190221_LEVER-DA_336x280-01.gif'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Lever_property/190221_LEVER-DA_336x280-02.gif'),
    background_repeat: 'no-repeat',
    background_size: 'contain',
    background_color:'#e1e1e1'
  },
  {
    id: 3,
    image: cdn('/Case_studies/Lever_property/190221_LEVER-DA_336x280-03.gif'),
    background_size: 'cover',
  }

]


export default class PilbaraMinerals extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="lever-property-wrapper">
      <MetaTags>
        <title>Lever Property - Commercial Property Management - OKMG</title>
        <meta name="description" content="Lever Property - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/lever-property'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Lever Property" role="heading"  
        sub_content="Digital | Creative"
        service_info="Established in Commercial Property Management, PIR Group were looking to expand into a Residential offering. A new brand name and identity elevated the newly launched Lever Property into the space of asset investment management."
        image={cdn('/Case_studies/Lever_property/lever-mock.jpg')}
        image_width="105%"
        foot_image={cdn('/Case_studies/Lever_property/LEVER BRANDMARK.png')}
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>Operating in a mature and saturated property industry, the already established PIR Group was looking to enter into the Residential Property Management space. </p>
            <p>To ensure a successful expansion into the residential property sector OKMG led a series of collaborative strategic leadership workshops to refine the value drivers of the existing business, for a unified company vision. </p>
            <p>Our communication strategy revolved around the brand ethos of assisting clients to expand their property horizons. Lever Property was selected as the brand name, encompassing their strategic approach to leverage all property assets as an opportunity for growth; the Lever Property confident service offering was proliferated through a tailored brand hero message ‘Expanding Property Horizons’ and reinforced through all branded collateral.</p>
            <p>Lever Property was successfully launched, a residential property group focused on shifting their client’s understanding of property investments to a portfolio mindset across an individual's entire balance sheet with sensible levels of leverage, ensuring future growth.</p>
          </div>}
        />


          <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="100px"
          
          />
         
           <ComponentFullBleedImage
          image={cdn('/Case_studies/Lever_property/SIGNAGE_FOR LEASE.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />
  
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="lever-property"
          />
     </div>
    );
  }
}