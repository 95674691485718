/*
  BlobOne.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './blobOne.scss';
import Fade from 'react-reveal/Fade';
// images


export default class BlobOne extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  render(){

   const {
      left,
      top,
      right,
      bottom,
      colour,
      width,
      height,
      stroke,
      stroke_width
      } = this.props;

  return (
      <svg className="blob-one" style={{left:`${left}`,right:`${right}`,top:`${top}`,bottom:`${bottom}`,width:`${height}`,}} viewBox="0 0 403 415" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="hero-left" transform="translate(-61.000000, -57.000000)" fill={colour} fillRule="nonzero" style={{stroke: stroke, strokeWidth: stroke_width}}>
                  <path d="M481.251359,206.660614 C481.251359,314.436604 385.847513,446.615742 265.869949,450.952281 C158.164789,454.845047 30.251359,414.81446 30.251359,315.074076 C30.251359,207.298085 53.3795642,138.721511 164.684052,117.038819 C270.471016,96.4301428 481.251359,98.8846236 481.251359,206.660614" id="Blob-7" transform="translate(255.751359, 279.071202) rotate(63.000000) translate(-255.751359, -279.071202) "></path>
              </g>
          </g>
      </svg>
    );
  }
}