/*
  Farmersjsx
  @Component - Farmers Case Study
*/

import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './HeartFoundation.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]
// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/4Farmers/4farmers-small-image-one.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/4Farmers/4farmers-small-image-two.jpg'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/4Farmers/4farmers-small-image-three.jpg'),
    background_size: 'cover',
  }

]

const images_squares = [
   {
    id: 1,
    image: cdn('/Case_studies/4Farmers/4farmers-old-logo.jpg'),
    background_size: '200px',
    background_repeat:'no-repeat',
    background_position:'center',
    width:'350px',
    className:'sp-image-one'
  },
  {
    id: 2,
    image: cdn('/Case_studies/4Farmers/4farmers-new-logo.jpg'),
    background_size: '200px',
    background_repeat:'no-repeat',
    width:'330px',
    background_position:'center',
    className:'sp-image-two'
  },

]

const images_gifs = [
   {
    id: 1,
    image: cdn('/Case_studies/Farmers/image_1%402x.png'),
    width:'20%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Farmers/Swell Times IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/Farmers/Farmers_INSTA%402x.png'),
    width:'30%',
  },
  {
    id: 4,
    image: cdn('/Case_studies/Farmers/Open Now IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 6,
    image: cdn('/Case_studies/Farmers/image_1%402x.png'),
    width:'20%',
  },


]


export default class Farmers extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="heart-foundation-wrapper">
      <MetaTags>
        <title>The Heart Foundation - OKMG</title>
        <meta name="description" content="The Heart Foundation is a global non-profit organization committed to improving cardiovascular health, funding lifesaving research, and educating the public about heart disease and its prevention." />
        <link rel='canonical' href='https://okmg.com/work/heart-foundation'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="The Heart Foundation" role="heading"
        sub_content="Animation | Video | Design"
        service_info="The Heart Foundation is a global non-profit organization committed to improving cardiovascular health, funding lifesaving research, and educating the public about heart disease and its prevention."
        image={cdn('/Case_studies/HeartFoundation/heart-foundation-bus.jpg')}
        main_image_position_right="-25px"
        image_class="header-image"
        image_width="90%"
        foot_image={cdn('/Case_studies/HeartFoundation/heart-crumb.jpg')}
        foot_image_width="180px"
        foot_image_left="0"
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>OKMG worked with The Heart Foundation to create a vibrant, engaging, and insightful video series that utilised professional and compelling storytelling as well as visual metaphors, animations and in person interviews to underscore the importance of maintaining an active and healthy lifestyle as we age. </p>

            <p>The healthy active ageing animated video depicts different animated characters at various stages of life, demonstrating how consistent physical activity, balanced nutrition, regular medical checkups, and mental wellness practices can contribute to heart health and overall wellbeing. </p>

            <p>The animation style is bright and lively, using approachable, relatable ‘stick figures’ to convey scientific information in an accessible way. The video also included practical tips for integrating heart-healthy habits into everyday routines, making it not just informative but directly actionable for viewers of all ages.</p>
            
          </div>}
        />


          <ComponentVideo
          video_url={cdn('/Case_studies/HeartFoundation/heart-foundation-web-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />

          <div className="heart-multi-image-wrap">
            <div className="row row-1">
              <img src={cdn('/Case_studies/HeartFoundation/heart-screen-two.jpg')}/>
              <img src={cdn('/Case_studies/HeartFoundation/heart-screen-three.jpg')}/>
            </div>
            <div className="row row-2">
              <img src={cdn('/Case_studies/HeartFoundation/heart-healthy-active.png')} />
            </div>
          </div>

          
          <ComponentVideo
          video_url={cdn('/Case_studies/HeartFoundation/heart-foundation-video-two.min.mp4')}
          loop="loop"
          marginTop="100px"
          />
          
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}