/*
  ContactPage.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import ComponentParticles from '../ComponentParticles';
import ComponentFooter from '../ComponentFooter';
import ComponentCtaFooter from '../ComponentCtaFooter';
import './NotFound.scss';


// images



export default class NotFound extends React.Component {


    constructor(props) {
    super(props);
    // State.

    this.state = {


    };


  }
  componentDidMount() {
    window.scrollTo(0, 0);

  }




 

  render(){


  return (


     <div className="contact-page">
       <Helmet>
            <title>Not Found 404</title>
            <link rel="canonical" href="https://okmg.com/404" />
            <meta name="description" content="Page not found" />
        </Helmet>
         <div className="not-found-wrapper" style={{marginBottom:'0px'}}>
         <ComponentParticles />
          <div className="heading">
            <h3>404 Sorry this page does not exist...</h3>
            <p>Head back to our <Link to="/">Home Page</Link> or check out some of <Link to="/work">Our Projects here</Link></p>
          </div>
         
        </div>
      <ComponentCtaFooter
      heading="Get in touch."
      />
      </div>
    );
  }
}