/*
  PYC.jsx
  @Component - PYC Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './PYC.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/PYC/pexels-photo-3819539.jpeg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/PYC/IMG_5500-web.jpeg'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/PYC/pexels-thisisengineering-3862130.jpg'),
    background_size: 'cover',
  }

]


const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/PYC/PYC_Pull Up Banner-2.png'),
    width:'calc(30% - 30px)',
  },
  {
    id: 2,
    image: cdn('/Case_studies/PYC/PYC_website page animation.png'),
    width:'calc(60% - 30px)',
    borderRadius: '32px',
    className:'special'
  },
];


export default class PYC extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="PYC-wrapper">
      <MetaTags>
        <title>PYC - Life-changing Science - OKMG</title>
        <meta name="description" content="PYC - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/PYC'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="PYC Therapeutics" role="heading"  
        sub_content="Digital | Creative "
        service_info="With a commitment to developing ‘life-changing science’ and world-leading RNA technology, PYC required a global brand and digital presence that showed the world their potential.  "
        // image={cdn('/Case_studies/PYC/PYC_iMac_Mockup.png')}
        hover_video_one={cdn('/websiteScrolls/PYC-rz-min.mp4')}
        hover_videobg_one={cdn('/websiteScrolls/iMac_Mockup_v2.png')}
        // hover_video_two={cdn('/websiteScrolls/PYC-rz.mp4')}
        // hover_videobg_two={cdn('/websiteScrolls/iMac_Mockup.png')}

        foot_image={cdn('/Case_studies/PYC/PYC_GRAPHIC-DEVICE-02v2.png')}
        className="special"
        image_class="header-image"
        foot_image_left="0px"

        />
         <ComponentCaseCopy
        content={
          <div>
            <p>PYC Therapeutics is a drug-development company offering world-leading RNA therapeutics and delivery of technology, revolutionising the approach to treating inherited diseases. </p>
            <p>Based in Perth, Western Australia, PYC had already made their mark in the Australian biotech industry. Now with a global focus and new headquarters in the US, PYC needed a brand identity to match their innovative capabilities and compete on a global scale.</p>
            <p>OKMG thoughtfully crafted a visual identity that brought their medical research into a contemporary space. We then worked with PYC to apply this brand to all key collateral as well as a new custom built interactive website. Taking highly complex scientific concepts, OKMG balanced integrated animation, imagery and branded infographics ensuring ownership over the RNA space.  </p>
          </div>}
        />

       {/* <ComponentVideo
          video_url={cdn('/Case_studies/PYC/THE-COVE-COMPLETION-VIDEO-V4-V4-1-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
          
         
        */}
        <ComponentFullBleedImage
          image={cdn('/Case_studies/PYC/PYC_MASTER LOGO.svg')}
          width="300px"
          marginTop="0px"
          marginTop="100px"
          />
         <ComponentVideo
          video_url={cdn('/Case_studies/PYC/pyc-screen-cast-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          screenCast
          marginTop="100px"
          />
          <ComponentFullBleedImage
          image={cdn('/Case_studies/PYC/PYC_Infographic-3.svg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />
        <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="100px"
          width="100%"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/PYC/PYC_Poster_Mockup-min.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="0px"
          />
  
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}