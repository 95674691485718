/*
  bep.jsx
  @Component - Busselton Swim Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import BlobOne from '../../blobOne';
import './BusinessEventsPerth.scss';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/bep/bep-footer-image-one.jpg'),
    background_size: 'cover',
  },
   {
    id: 2,
    image: cdn('/Case_studies/bep/bep-footer-image-two.jpg'),
    background_size: 'cover',
  }

]



export default class bep extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="bep">
      <MetaTags>
        <title>Business Events Perth - OKMG</title>
        <meta name="description" content="Business Events Perth" />
        <link rel='canonical' href='https://okmg.com/work/business-events-perth'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Business Events Perth" role="heading"  
        sub_content="Creative"
        service_info="Business Events Perth has been helping not-for-profit associations, corporations and agents bring their conferences and incentive groups to Western Australia for 50 years."
        image_width="100%"
        image={cdn('/Case_studies/bep/bep-header-banner-v2.png')}
        image_class="header-image"
        foot_image={cdn('/Case_studies/bep/bep-lower-banner.png')}
        foot_image_width="300px"
        foot_image_left="0"
        />
         <ComponentCaseCopy
        content={
          <div>
        <p>For 50 years, Business Events Perth (formerly known as Perth Convention Bureau) has been aiding non-profit associations, corporations, and agents to organise their conferences and incentive groups in Western Australia. Their mission is to promote Western Australia as a prime destination for conventions, exhibitions, and travel groups nationally and internationally, as they offer their in-house expertise and industry knowledge to assist planners in bringing their events to the area. Business Events Perth is well-versed in what the state has to offer and how to stage a successful event, making them an ideal partner for any upcoming conference.</p>

<p>OKMG has an ongoing relationship with Business Events Perth producing design, video, and animation content that serves to communicate the unique attributes of Perth to Business Events Perth's target audience.</p>

<p>Our first engagement was to develop a 104 page digital publication, the ‘Business Event Planners Guide’, a comprehensive directory of everything from accommodation offerings, event spaces and service providers, including exciting new experiences stretching from the Perth Metro area to Western Australia’s regions, and featuring graphic elements and design that embodies the essence of Western Australia’s vibrant colours and natural beauty.</p>

<p>Combined with original interview content, and utilising their expansive video library stored on HIVO Digital Asset Management platform, we are regularly tasked with producing engaging bid videos for their members, pitching to host a wide range events and conferences and showcasing WA’s beauty, culture, and business-friendly environment. </p>

<p>We are also proud to have produced an animated timeline video for their 50th anniversary, highlighting the progress and impact that they have made over the past half century.</p>
          </div>}
        />

       <ComponentVideo
          video_url={cdn('/Case_studies/bep/bep-coast-mim.mp4')}
          marginTop="100px"
          marginBottom="100px"
          autoplay="autoplay"
          loop="loop"
          />

     
          <ComponentVideo
          video_url={cdn('/Case_studies/bep/bep-body-vid-one-mim.mp4')}
          marginTop="100px"
          marginBottom="100px"
          autoplay="autoplay"
          loop="loop"
          className="swan-valley"
          />
            <ComponentVideo
          video_url={cdn('/Case_studies/bep/bep-swan-valley-min.mp4')}
          marginTop="100px"
          marginBottom="100px"
          autoplay="autoplay"
          loop="loop"
          />
           <ComponentCaseImages
            className="footer-images"
            stats={images}
            className="case-images"
          />
         
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}