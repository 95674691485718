/*
  BlobOne.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentInternalBigLink.scss';
import Marquee from 'react-double-marquee';
import MarqueeDouble from 'react-marquee-double';
import cdn from '../../lib/cdn';
export default class ComponentInternalBigLink extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  render(){

   const {
      background,
      target_url,
      client,
      client_info,
      rotating_text,
      background_color,
      heading,
      graphic,
      padskull
      } = this.props;

  return (
      <div className="component-internal-big-link" style={{'visibility': 'visible'}}>
      { graphic ? 

        <img src={graphic} className="graphic" alt="heading"/>
        : ''}
      {heading ? 
        <img src={heading} className="heading" alt="heading"/>
        : 

        ''}
        
        <Link to={target_url}>
          <div className="sub sub-one">
            <div className="spacer-one">
            </div>
            <div className="bg-image" style={{backgroundImage:`url(${background})`, backgroundColor: background_color}}>

              { padskull ? 

                <div className="award-wrapper">
                <div className="column col-1">
                  <img src={cdn('/awards/padskulls.png')} alt="award"/>
                </div>
                <div className="column col-2">
                  <h5>2x Silver</h5>
                  <section>
                    <p>Digital Advertising & Design</p>
                    <p><strong>User Experience Design (UX)</strong></p>
                  </section>
                  <section>
                    <p>Digital Advertising & Design</p>
                    <p><strong>Use of Interface Design (UI)</strong></p>
                  </section>
                </div>
              </div>

                : ''}
              


               <span className="client-info">
                 <h3>{client} <span>{client_info}</span></h3>
               </span>
             </div>
             <div className="spacer-two">
            </div>
            <div className="tech-slide-wrap">
              <div
                  style={{
                    width: '100%',
                    whiteSpace: 'nowrap',
                  }}
                >
                 {/* <MarqueeDouble
                  step={1} interval={20}
                  autoStart={true}
                  direction={'left'}
                  delay={1000}
                  >
                  {rotating_text}
                </MarqueeDouble>  

                */} 
                </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}