/*
  BlobOne.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentVideos.scss';
import Reveal from 'react-reveal/Reveal';


export default class ComponentCaseImageGifs extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  render(){

   const {
      stats,
      marginTop,
      maxWidth,
      className,
      width,
      wrap,
      left,
      } = this.props;

  return (
      <div className={`component-case-videos-wrapper`+className} style={{marginTop:marginTop,width:maxWidth,flexWrap:wrap ? 'wrap' : '',left:left}}>
        {
          stats.map(videos =>
              <video style={{width:videos.width}} className={videos.className} controls autoplay="autoplay" muted playsinline>
              <source src={videos.url} type="video/mp4" />
              <source src="movie.ogg" type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          )
        }
      </div>
  );
  }
}