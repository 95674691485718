/*
  PilbaraMinerals.jsx
  @Component - Revo Fitness Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './PilbaraMinerals.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/Pilbara_minerals/Sept_2020_SiteTrip-253.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Pilbara_minerals/square-two.png'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/Pilbara_minerals/Sept_2020_SiteTrip-52.jpg'),
    background_size: 'cover',
  }

]


const image_full = [
  {
    id: 1,
    image: cdn('/Case_studies/Pilbara_minerals/pilbara-double-flyer.jpg'),
    className:'pm-img'
  }
]


export default class PilbaraMinerals extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="pilbara-minerals-wrapper">
      <MetaTags>
        <title>Pilbara Minerals - Sustainable Energy Future - OKMG</title>
        <meta name="description" content="Pilbara Minerals - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/pilbara-minerals'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Pilbara Minerals" role="heading"  
        sub_content="Creative"
        service_info="Pilbara Minerals has placed WA at the forefront of an emerging lithium industry, requiring a visual identity that adequately reflected their size and capability in the global resource market."
        image={cdn('/Case_studies/Pilbara_minerals/pilbara-flyer.jpg')}
        image_width="105%"
        foot_image={cdn('/Case_studies/Pilbara_minerals/PILBARA MINERALS_BRAND ASSET-1.png')}
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>Rapidly progressing from the first drill hole to production in four years, Pilbara Minerals has secured its position as leaders in Lithium.</p>
            <p>Pilbara Minerals engaged OKMG to create a brand refinement that was indicative of the size, capabilities and confidence of the innovative organisation, whilst being easily adapted to all existing and new collateral requirements.</p>
            <p>Beginning with a refinement of the logo mark, elevating the brand into a modern context with a new wordmark, colour palette development and a creative device to connect all communication touchpoints. This revitalised direction informed the design of all future collateral.</p>
            <p>OKMG’s most significant engagement with Pilbara Minerals was the design of a fully customised, 100-page Annual Report. The design and display of information are complemented through the application of original OKMG photography captured across Pilbara Mineral offices and work sites.</p>
            <p>With a newly refreshed brand identity, Pilbara Minerals is positioned for future growth; leading Australia in lithium.</p>
          </div>}
        />
          <ComponentCaseImageGifs
            stats={image_full}
            marginTop="100px"
            special_class_wrapper="pm-img-full-wrap"
          />

          {/* <ComponentVideo
          video_url={cdn('/Case_studies/Pilbara_minerals/value-rainbow-rz.mp4')}
          autoplay="autoplay"
          loop="loop"
          controls="none"
          marginTop="100px"
          /> */}
          <div className="animate-vid-wrap">
            <video width="80%" className="animate-vid" autoplay="autoplay" muted loop playsinline>
              <source src={cdn('/Case_studies/Pilbara_minerals/value-rainbow-rz.mp4')} type="video/mp4" />
              <source src="movie.ogg" type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </div>
          {/* <ComponentFullBleedImage
          image={cdn('/Case_studies/Pilbara_minerals/201012_PILBARA MINERALS_BRANDING DEVICE.png')}
          width="80%"
          marginTop="0px"
          right="-220px"
          /> */}
           <ComponentFullBleedImage
          image={cdn('/Case_studies/Pilbara_minerals/190621_PLS WALL GRAPHIC.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />
          <ComponentFullBleedImage
          image={cdn('/Case_studies/Pilbara_minerals/PILBARA_BANNER_MOCKUP.jpg')}
          width="100%"
          marginTop="0px"
          />
           <ComponentFullBleedImage
          image={cdn('/Case_studies/Pilbara_minerals/PILBARA MINERALS_WINDOWS FROSTING_MOCKUP.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />
  
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}