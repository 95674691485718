import React, { Component } from "react"
// components
import ComponentHeader from '../Components/ComponentHeader';
// pages 
import HomePage from '../Components/HomePage';


import "./App.scss";

// case studies
import SingleCaseStudy from '../Components/_CaseStudies/SingleCaseStudy';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";


/*
  Inner router app.
*/

class AppInner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navIsOpen: false,
      showFooter: true,
      animate: '',
      location: this.props.location,
      activeClass: '',
      isLoading: true, // New loading state
      loadingProgress: 0, // New loading progress state
    };
  }

  componentDidMount() {
    this.middleAnimation();
    window.addEventListener('scroll', () => {
      let activeClass = 'scroll-active';
      if (window.scrollY === 0) {
        console.log('top');
        activeClass = 'top';
      }
      this.setState({ activeClass });
    });

    // Simulating loading completion after 2 seconds
    const intervalId = setInterval(() => {
      if (this.state.loadingProgress < 100) {
        this.setState((prevState) => ({
          loadingProgress: prevState.loadingProgress + 10,
        }));
      } else {
        clearInterval(intervalId);
        this.setState({ isLoading: false });
      }
    }, 500);
  }

  componentDidUpdate(prevProps) {
    const prevPath = prevProps.location.pathname.toLowerCase();
    const nextPath = this.props.location.pathname.toLowerCase();

    if (prevPath !== nextPath) {
      this.startAnimation();
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
    window.removeEventListener('scroll');
  }

  startAnimation() {
    clearTimeout(this._timer);
    this.setState({ animate: 'animOut' });
    this._timer = setTimeout(this.middleAnimation.bind(this), 500);
  }

  middleAnimation() {
    this.setState({ animate: 'animIn', location: this.props.location });
    this._timer = setTimeout(this.endAnimation.bind(this), 500);
  }

  endAnimation() {
    this.setState({ animate: 'animIn' });
  }

  navToggle = (e) => {
    this.setState({
      navIsOpen: !this.state.navIsOpen,
    });
  }

  handleFooter = (res) => {
    this.setState({
      showFooter: res,
    });
  }

  render() {
    const { isLoading, loadingProgress } = this.state;

    // Render loading screen while isLoading is true
   /* if (isLoading) {
      return (
        <div className="loader-wrapper">
          <div>
          <div className="loading-logo"><img src="https://okmg-digital-assets.s3-ap-southeast-2.amazonaws.com/okmg/OKMG_MASTER+LOGO.png" alt="OKMG." style={{'width':'70px','marginBottom':'10px'}}/></div>
          <div className="loading-website">Loading website...</div>
          <div style={{'marginTop':'5px'}} className="loading-progress">Loading Progress: {loadingProgress}%</div>
          <div
            style={{
              width: `${loadingProgress}%`,
              height: '40px',
              background: 'rgba(0,0,0,0.1)',
              marginTop: '15px',
              transition: 'width 0.5s',
              position: 'relative',
              left:'-25px'
            }}
          ></div>
          </div>
        </div>
      );
    }*/

    let className = 'react-router-wrapper';

    if (this.state.animate) {
      className += ' ' + this.state.animate;
    }

    return (
      <div className={className}>
       <ComponentHeader />
        <div className="App">
          <div className={`top-bar ${this.state.activeClass}`}>

            <Switch>
              <Route exact path='/' component={HomePage}></Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

/*
  Outer router app.
*/

class App extends Component {
  render() {
    return (
      <Router>
        <Route component={AppInner} />
      </Router>
    );
  }
}

export default App;

