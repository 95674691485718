
import React from 'react';
// Scripts.
const MATTER_JS_SCRIPT = '/assets/js/matter.min.js';
const SVG_PATH = '/assets/svg/';

// Constants.
const CANVAS_SIZE = 450;
const WALL_WIDTH = 50;
const WALL_COLOR = 'rgba(0,0,0,1.0)';
const WALL_IS_VISIBLE = false;
const START_SCALE = 0.2;
const END_SCALE = 0.75;
const GROW_SPEED = 0.1;
const SHAPE_SIZE = 45;
const SHAPE_SCALE = 1.2;
const ROTATE_SPEED = 0.4;
const HAS_DIAGONAL_WALLS = true;
const DIAGONAL_WALL_SIZE = 50;  // To make shapes tumble more.
const SPAWN_TIMER = .75;
const SHAPE_GROW_SPEED = .8;
const GRAVITY = 0.3;
const MOUSE_STRENGTH = 0.002;
const WALL_FRICTION = 1;
const SHAPE_FRICTION = 0;
const WIREFRAME = false;

// Queue maker.
function getShapeQueue() {
  const array = [
    { shape: SHAPES.hexagonStroke, size: 1.0 },
    { shape: SHAPES.squareFill, size: 0.5 },
    { shape: SHAPES.hexagonFill, size: 0.5 },
    { shape: SHAPES.triangleStroke, size: 1.0 },
    { shape: SHAPES.circleFill, size: 0.75 },
    { shape: SHAPES.triangleFill, size: 0.75 },
    { shape: SHAPES.circleStroke, size: 0.5 },
    { shape: SHAPES.triangleFill, size: 0.5 },
    { shape: SHAPES.squareFill, size: 1.0 },
    { shape: SHAPES.circleFill, size: 0.5 },
    { shape: SHAPES.hexagonFill, size: 1.0 },
    { shape: SHAPES.circleFill, size: 1.0 },
    { shape: SHAPES.squareStroke, size: 1.0 },
    { shape: SHAPES.triangleStroke, size: 1.0 },
  ];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

// All shapes.
const SHAPES = {
  circleFill: {
    type: 'circle',
    texture: `${SVG_PATH}circle-fill.svg`,
    xOffset: 0,
    yOffset: 0,
    xScale: 3.0 * SHAPE_SCALE,
    yScale: 3.0 * SHAPE_SCALE,
  },
  circleStroke: {
    type: 'circle',
    texture: `${SVG_PATH}circle-stroke.svg`,
    xOffset: 0,
    yOffset: 0,
    xScale: 1.2 * SHAPE_SCALE,
    yScale: 1.2 * SHAPE_SCALE,
  },
  hexagonFill: {
    type: 'hexagon',
    texture: `${SVG_PATH}hexagon-fill.svg`,
    xOffset: 0,
    yOffset: 0,
    xScale: 1.3 * SHAPE_SCALE,
    yScale: 1.3 * SHAPE_SCALE,
  },
  hexagonStroke: {
    type: 'hexagon',
    texture: `${SVG_PATH}hexagon-stroke.svg`,
    xOffset: 0,
    yOffset: 0,
    xScale: 2.5 * SHAPE_SCALE,
    yScale: 2.5 * SHAPE_SCALE,
  },
  squareFill: {
    type: 'square',
    texture: `${SVG_PATH}square-fill.svg`,
    xOffset: 0,
    yOffset: 0,
    xScale: 1.1 * SHAPE_SCALE,
    yScale: 1.1 * SHAPE_SCALE,
  },
  squareStroke: {
    type: 'square',
    texture: `${SVG_PATH}square-stroke.svg`,
    xOffset: 0,
    yOffset: 0,
    xScale: 1.1 * SHAPE_SCALE,
    yScale: 1.1 * SHAPE_SCALE,
  },
  triangleFill: {
    type: 'triangle',
    texture: `${SVG_PATH}triangle-fill.svg`,
    xOffset: 0,
    yOffset: 0,
    xScale: 1.1 * SHAPE_SCALE,
    yScale: 1.1 * SHAPE_SCALE,
  },
  triangleStroke: {
    type: 'triangle',
    texture: `${SVG_PATH}triangle-stroke.svg`,
    xOffset: 0,
    yOffset: 0,
    xScale: 1.1 * SHAPE_SCALE,
    yScale: 1.1 * SHAPE_SCALE,
  },
};

export default class App extends React.Component {

  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    const scriptId = 'matter-js-script';
    if (document.getElementById(scriptId)) {
      this.runScene();
    } else {
      const scriptEl = document.createElement('script');
      scriptEl.id = scriptId;
      scriptEl.src = MATTER_JS_SCRIPT;
      scriptEl.onload = () => this.runScene();
      document.body.appendChild(scriptEl);
    }
  }

  componentWillUnmount() {
    if (this.stop) {
      this.stop();
      this.stop = null;
    }
  }

  runScene() {

    const Matter = window.Matter;
    if (!Matter) {
      return;
    }

    const Engine = Matter.Engine,
      Render = Matter.Render,
      Runner = Matter.Runner,
      Composites = Matter.Composites,
      Common = Matter.Common,
      MouseConstraint = Matter.MouseConstraint,
      Mouse = Matter.Mouse,
      World = Matter.World,
      Body = Matter.Body,
      Bodies = Matter.Bodies,
      Vector = Matter.Vector,
      Bounds = Matter.Bounds,
      Events = Matter.Events;

    function createWalls() {

      const size = CANVAS_SIZE;
      const half = CANVAS_SIZE * 0.5;

      const wall1 = Bodies.rectangle(half, 0, size, WALL_WIDTH, { render: { fillStyle: WALL_COLOR }});
      wall1.friction = WALL_FRICTION;

      const wall2 = Bodies.rectangle(half, size, size, WALL_WIDTH, { render: { fillStyle: WALL_COLOR }});
      wall2.friction = WALL_FRICTION;

      const wall3 = Bodies.rectangle(0, half, WALL_WIDTH, size, { render: { fillStyle: WALL_COLOR }});
      wall3.friction = WALL_FRICTION;

      const wall4 = Bodies.rectangle(size, half, WALL_WIDTH, size, { render: { fillStyle: WALL_COLOR }});
      wall4.friction = WALL_FRICTION;

      const parts = [
        wall1,
        wall2,
        wall3,
        wall4
      ];

      if (HAS_DIAGONAL_WALLS) {

        const o = WALL_WIDTH * 0.5;
        const l = DIAGONAL_WALL_SIZE * 2;
        const ol = l * 0.5;
        const d = DIAGONAL_WALL_SIZE;
        const od = d * 0.5;

        const walls = [
          
          Bodies.rectangle(o + od, o + ol, d, l, { render: { fillStyle: WALL_COLOR }}),
          Bodies.rectangle(o + ol, o + od, l, d, { render: { fillStyle: WALL_COLOR }}),

          Bodies.rectangle(o + od, size - (o + ol), d, l, { render: { fillStyle: WALL_COLOR }}),
          Bodies.rectangle(o + ol, size - (o + od), l, d, { render: { fillStyle: WALL_COLOR }}),

          Bodies.rectangle(size - (o + od), o + ol, d, l, { render: { fillStyle: WALL_COLOR }}),
          Bodies.rectangle(size - (o + ol), o + od, l, d, { render: { fillStyle: WALL_COLOR }}),

          Bodies.rectangle(size - (o + od), size - (o + ol), d, l, { render: { fillStyle: WALL_COLOR }}),
          Bodies.rectangle(size - (o + ol), size - (o + od), l, d, { render: { fillStyle: WALL_COLOR }}),

        ];

        for (let wall of walls) {
          wall.friction = WALL_FRICTION;
          parts.push(wall);
        }
      }

      const walls = Body.create({
        parts: parts,
        isStatic: true,
        render: {
          visible: WALL_IS_VISIBLE,
        }
      });

      // Set anchor point.
      walls.position.x = half;
      walls.position.y = half;
      walls.positionPrev.x = half;
      walls.positionPrev.y = half;

      return walls;
    }

    // create engine
    const engine = Engine.create(),
    world = engine.world;
    world.gravity.y = GRAVITY;

    // create renderer
    const render = Render.create({
      element: this.ref.current,
      engine: engine,
      options: {
        width: CANVAS_SIZE,
        height: CANVAS_SIZE,
        showAngleIndicator: false,
        wireframes: WIREFRAME,
        background: 'none'
      }
    });

    Render.run(render);

    // create runner
    const runner = Runner.create();
    Runner.run(runner, engine);

    // Create walls.
    const walls = createWalls();
    World.add(world, [walls]);

    // add mouse control
    const mouse = Mouse.create(render.canvas),
    mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0,  // No drag.
        render: {
          visible: false
        }
      }
    });
    World.add(world, mouseConstraint);

    // mouse movement.
    // NOTE: probably a better way to do this?
    let lastMousePos = null;
    Events.on(mouseConstraint, 'mousemove', function (event) {
      const mousePos = event.mouse.position;
      if (lastMousePos != null) {
        const vec = Vector.create(
          Math.min(Math.max((mousePos.x - lastMousePos.x) * MOUSE_STRENGTH, -0.2), 0.2),
          Math.min(Math.max((mousePos.y - lastMousePos.y) * MOUSE_STRENGTH, -0.2), 0.2)
        );
        for (let i = 0; i < liveShapes.length; i++) {
          const shape = liveShapes[i];
          if (Bounds.contains(shape.bounds, mousePos)) {
            Body.applyForce(shape, mousePos, vec);
          }
        }
      }
      lastMousePos = {
        x: mousePos.x,
        y: mousePos.y,
      };
    });

    // Scroll.
    // TODO?

    // keep the mouse in sync with rendering
    render.mouse = mouse;

    // fit the render viewport to the scene
    Render.lookAt(render, {
      min: { x: 0, y: 0 },
      max: { x: CANVAS_SIZE, y: CANVAS_SIZE }
    });

    // Function to rotate the walls.
    function rotateWalls(rot) {
      Body.rotate(walls, rot, Vector.create(CANVAS_SIZE * 0.5, CANVAS_SIZE * 0.5));
    }

    // Function to animate in a shape.
    // NOTE: We can't set scale with matterjs (I think)
    // so we need to maintain last scale value.
    function animateInShape(shape) {
      let scale = 0.01;
      Body.scale(shape, scale, scale);
      if (shape.render.sprite) {
        shape.render.sprite.xScale *= scale;
        shape.render.sprite.yScale *= scale;
      }
      function animate() {
        const dt = runner.delta * 0.001;
        let next = scale + (dt * SHAPE_GROW_SPEED);
        const done = next >= 1.0;
        if (done) {
          next = 1.0;
        }
        const mul = next / scale;
        scale = next;
        Body.scale(shape, mul, mul);
        if (shape.render.sprite) {
          shape.render.sprite.xScale *= mul;
          shape.render.sprite.yScale *= mul;
        }
        if (done) {
          Events.off(runner, 'afterTick', animate);
        }
      }
      Events.on(runner, 'afterTick', animate);
    }

    // Function to increase the scale of the walls.
    let wallScale = START_SCALE;
    Body.scale(walls, wallScale, wallScale);
    function scaleWalls(amount) {
      const next = wallScale + amount;
      const mul = next / wallScale;
      wallScale = next;
      Body.scale(walls, mul, mul);
    }

    // Spawner.
    const shapeQueue = getShapeQueue();
    const liveShapes = [];
    function spawnNextShape() {
      if (shapeQueue.length > 0) {
        const next = shapeQueue.shift();

        const x = CANVAS_SIZE * 0.5;
        const y = CANVAS_SIZE * 0.5;
        const size = SHAPE_SIZE * next.size;

        const render = {
          sprite: {
            texture: next.shape.texture,
            xOffset: next.shape.xOffset,
            xScale: next.shape.xScale * next.size,
            yOffset: next.shape.yOffset,
            yScale: next.shape.yScale * next.size,
          }
        };

        let shape;
        if (next.shape.type === 'triangle') {
          shape = Bodies.polygon(x, y, 3, size, { render: render });
        } else if (next.shape.type === 'square') {
          shape = Bodies.rectangle(x, y, size * 1.4, size * 1.4, { render: render });
        } else if (next.shape.type === 'hexagon') {
          shape = Bodies.polygon(x, y, 6, size, { render: render });
        } else if (next.shape.type === 'circle') {
          shape = Bodies.circle(x, y, size, { render: render });
        }

        if (shape) {
          shape.friction = SHAPE_FRICTION;
          World.add(world, shape);
          animateInShape(shape);
          liveShapes.push(shape);
        }
      }
    }

    // Events.
    let spawnTimer = SPAWN_TIMER;
    Events.on(runner, 'afterTick', function (event) {
      
      const dt = runner.delta * 0.001;
      
      rotateWalls(dt * ROTATE_SPEED);

      if (wallScale < END_SCALE) {
        scaleWalls(dt * GROW_SPEED);
      }

      spawnTimer -= dt;
      if (spawnTimer <= 0.0) {
        spawnTimer = SPAWN_TIMER;
        spawnNextShape();
      }

    });

    // Set initial world.
    spawnNextShape();

    // Stopping scripts.
    this.stop = function () {
      Matter.Render.stop(render);
      Matter.Runner.stop(runner);
    };
  }

  render() {
    return (
      <div ref={this.ref}>

      </div>
    );
  }
}
