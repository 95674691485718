/*
  TheFast800.jsx
  @Component - TheFast800 Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './TheFast800.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]
// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/TheFast800/the-fast800-small-image-one.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/TheFast800/the-fast-800-main-image.jpg'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/TheFast800/the-fast800-image-three.jpg'),
    background_size: 'cover',
  }

]

const images_squares = [
   {
    id: 1,
    image: cdn('/Case_studies/TheFast800/ron-farris-original-logo.jpg'),
    background_size: 'calc(100% - 50px)',
    background_repeat:'no-repeat',
    background_position:'bottom left',
    width:'340px',
    className:'sp-image-one'
  },
  {
    id: 2,
    image: cdn('/Case_studies/TheFast800/ron-farris-new-logo.jpg'),
    background_size: 'calc(100% - 50px)',
    background_repeat:'no-repeat',
    width:'400px',
    background_position:'bottom',
    className:'sp-image-two'
  },

]

const images_gifs = [
   {
    id: 1,
    image: cdn('/Case_studies/TheFast800/image_1%402x.png'),
    width:'20%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/TheFast800/Swell Times IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/TheFast800/The Fast 800_INSTA%402x.png'),
    width:'30%',
  },
  {
    id: 4,
    image: cdn('/Case_studies/TheFast800/Open Now IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 6,
    image: cdn('/Case_studies/TheFast800/image_1%402x.png'),
    width:'20%',
  },

]

const images_gifs2 = [
   {
    id: 1,
    image: cdn('/Case_studies/TheFast800/Fast 800 Creative - 1-v3 Gif.gif'),
    width:'30%',
    borderRadius: '32px',
    className:'side-gif'
  },
  {
    id: 2,
    image: cdn('/Case_studies/TheFast800/Fast 800 Creative - 2-v4 Gif.gif'),
    width:'30%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/TheFast800/Fast 800 Creative - 3-v4 Gif.gif'),
    width:'30%',
    borderRadius: '32px',
    className:'side-gif'
  },

]




export default class TheFast800 extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="the-fast-800-wrapper">
      <MetaTags>
        <title>The Fast 800 - Healthy Habits - OKMG</title>
        <meta name="description" content="The Fast 800 - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/TheFast800'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="The Fast 800" role="heading"
        sub_content="Strategy | Digital | Creative"
        service_info="Pioneered by Dr. Michael Mosley, The Fast 800 program has become a globally recognised lifestyle program to assist in weight loss for better health. "
        image={cdn('/Case_studies/TheFast800/the-fast-800-header.jpg')}
        image_class="header-image"
        image_width="100%"
        foot_image={cdn('/Case_studies/TheFast800/the-fast800-logo.jpg')}
        foot_image_width="150px"
        foot_image_left="0"
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>Looking to cement their position in the Australian market and promote their online program, The Fast 800 required a campaign that focused equally on the science-based backing, as well as the convenience of the program.</p>
            <p>Trying to stick to healthy habits in the world today is harder than ever. We’re constantly being tempted, whether it’s driving by fast food outlets, ordering UberEats, or opting for Netflix rather than a sunset stroll. And technology is facilitating this bad behaviour, bringing the temptation too close for comfort.  </p>
            <p>But if you can’t beat them - join them. The Fast 800 online program has been designed with convenience in mind, creating an online and mobile experience that puts The Fast 800 support team in your pocket.</p>
             <ComponentReadMore
             content={
              <span>
                <p>To help keep our users on track, we put The Fast 800 program - and the team of health experts - into the palm of users' hands by removing a voice of lesser reason - Siri.</p>
               <p>We brought this idea to life through three, highly relatable scenarios that captured moments when people often fall back into old habits. Each different scenario worked to hero a specific pillar of the online program, demonstrating the breadth of support that members receive from guided recipes, to at-home workouts, and education grounded in science. We worked to ensure that the tone was equally surprising and supportive, avoiding the shame that often comes with health advertising.</p>
               <p>Leveraging the personality and distinctive voice of Dr. Michael Mosley who has become a credible household name, whilst also introducing the broader team of health experts that makes The Fast 800 so unique.</p>
              </span>
            }
             />
          </div>}
        />

    
          
          <ComponentVideo
          video_url={cdn('/Case_studies/TheFast800/OKMG_FAST 800_CASE STUDY_EDIT V2-web.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />

          <ComponentVideo
          video_url={cdn('/Case_studies/TheFast800/the-fast-800-screen-cast.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          screenCast
          />

          <ComponentVideo
          video_url={cdn('/Case_studies/TheFast800/FAST800_WEBSITE_SHOP_FINAL-web.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />

          <ComponentCaseImageGifs 
           stats={images_gifs2}
           marginTop="100px"
           width="100%"
          />
 
          <ComponentFullBleedImage
          image={cdn('/Case_studies/TheFast800/mr_michael.jpeg')}
          marginTop="100px"
          width="100%"
          />
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}