/*
  CapitalPropertyAdvisory.jsx
  @Component - CapitalPropertyAdvisory Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './CapitalPropertyAdvisory.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]
// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/HunterCapital/hunter-capital-small-image-one.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/HunterCapital/hunter-capital-small-image-two.jpg'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/HunterCapital/hunter-capital-small-image-three.jpg'),
    background_size: 'cover',
  }

]

const images_squares = [
   {
    id: 1,
    image: cdn('/Case_studies/HunterCapital/hunter-capital-hallifax.jpg'),
    background_size: '300px',
    background_repeat:'no-repeat',
    background_position:'left bottom',
    width:'700px',
    className:'sp-image-one'
  },
  {
    id: 2,
    image: cdn('/Case_studies/HunterCapital/hunter-capital-angove-west.jpg'),
    background_size: '300px',
    background_repeat:'no-repeat',
    width:'700px',
    background_position:'right bottom',
    className:'sp-image-two'
  },

]

const images_squares_two = [
   {
    id: 1,
    image: cdn('/Case_studies/HunterCapital/hunter-capital-hallifax-folder.jpg'),
    background_size: '700px',
    background_repeat:'no-repeat',
    background_position:'bottom left',
    width:'750px',
    className:'sp-image-one'
  },
  {
    id: 2,
    image: cdn('/Case_studies/HunterCapital/hunter-capital-angove-west-folder.jpg'),
    background_size: '700px',
    background_repeat:'no-repeat',
    width:'750px',
    background_position:'bottom',
    className:'sp-image-two'
  },

]

const images_gifs = [
   {
    id: 1,
    image: cdn('/Case_studies/HunterCapital/image_1%402x.png'),
    width:'20%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/HunterCapital/Swell Times IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/HunterCapital/Hunter Capital_INSTA%402x.png'),
    width:'30%',
  },
  {
    id: 4,
    image: cdn('/Case_studies/HunterCapital/Open Now IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 6,
    image: cdn('/Case_studies/HunterCapital/image_1%402x.png'),
    width:'20%',
  },


]


export default class HunterCapital extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="cpa-wrapper">
      <MetaTags>
        <title>Capital Property Advisory - Fast-casual fish & chips - OKMG</title>
        <meta name="description" content="Capital Property Advisory - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/capital-property-advisory'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Capital Property Advisory" role="heading"
        sub_content="Digital | Creative"
        service_info="Capital Property Advisory (CPA) works with luxury home-buyers, investors and seasoned developers from all over Australia in acquisition, development and property management. OKMG was approached to develop a brand that would accurately reflect the company's leading position within the property industry."
        image={cdn('/Case_studies/HunterCapital/hunter-capital-header-image.jpg')}
        image_class="header-image"
        image_width="100%"
        foot_image={cdn('/Case_studies/HunterCapital/hunter-capital-crumb.jpg')}
        foot_image_width="150px"
        foot_image_left="0"
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>The team at Capital Property Advisory apply their extensive local knowledge and experience to guide clients through the property market, with the sole purpose to maximise profits and minimise risk.</p>
            <p>We were engaged in creating a visual brand language that would feel both local and speak global, with the priority to represent the trust and authenticity of the CPA team consistently to deliver outstanding results. Through a series of creative workshops, the project was hugely a collaborative process to ensure we captured what it was that set CPA apart from other property companies.</p>
            <p>With a contrasting colour palette of muted tones paired with vivid pops of colour, the visual language elevated the brand to a familiar yet contemporary space. The linear structure to the logo further emphasises the established position of CPA within the Perth property industry. Capital Property Advisory has truly made its mark, announced as Perth’s highest-rated property buyer’s agency.</p>
          </div>}
        />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/HunterCapital/hunter-capital-logo.jpg')}
          marginTop="100px"
          width="300px"
          />
          <ComponentFullBleedImage
          image={cdn('/Case_studies/HunterCapital/hunter-capital-flyer.jpg')}
          marginTop="100px"
          width="90%"
          />

          <ComponentCaseImages
            stats={images_squares}
            className="square-images"
          />
          <ComponentCaseImages
            stats={images_squares_two}
            className="square-images-two"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/HunterCapital/hunter-capital-website-image.jpg')}
          marginTop="100px"
          width="70%"
          />
    
          
          <ComponentFullBleedImage
          image={cdn('/Case_studies/HunterCapital/hunter-capital-main-image.jpg')}
          marginTop="100px"
          width="100%"
          />
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}