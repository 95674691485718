/*
  RevoFitness.jsx
  @Component - Revo Fitness Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './RevoFitnessInnaloo.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentVideos from '../_CaseComponents/ComponentVideos';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/Revo_fitness/square-one.jpeg'),
    background_size: 'cover',
    background_color: 'green',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Revo_fitness/square-two.jpeg'),
    background_size: 'cover',
    background_color: 'rgb(224 225 226)',
    background_repeat:'no-repeat'
  },
  {
    id: 3,
    image: cdn('/Case_studies/Revo_fitness/square-one.jpg'),
    background_size: 'cover',
    background_color: 'green',
  }

]

const images_one = [
  {
    id: 1,
    image: cdn('/Case_studies/Revo_fitness/200925_RevoGym_NewGymAlert_v1-01-v4.png'),
    background_size: 'cover',
    background_color: 'green',
    width: 'calc(25% - 50px)',
    className: 'special-image'
  },
  {
    id: 2,
    image: cdn('/Case_studies/Revo_fitness/200930_REVO FITNESS_INNALOO FACEBOOK COVERS-01-v3.png'),
    background_size: 'contain',
    background_color: 'rgb(224 225 226)',
    background_repeat:'no-repeat',
    width: 'calc(75% - 50px)',
    className: 'special-image'
  }

];


const images_two = [
  {
    id: 1,
    image: cdn('/Case_studies/Revo_fitness/1000%2Bnew+followers.jpg'),
    background_size: 'cover',
    background_color: 'green',
    width: '25%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Revo_fitness/tony-gallati.png'),
    background_size: 'contain',
    background_color: 'rgb(224 225 226)',
    background_repeat:'no-repeat',
    width: '30%',
    className:'special-image'
  },
  {
    id: 3,
    image: cdn('/Case_studies/Revo_fitness/4000%2BSpudshed.jpg'),
    background_size: 'contain',
    background_color: 'rgb(224 225 226)',
    background_repeat:'no-repeat',
    width: '25%',
  }

];

const images_three = [
  {
    id: 1,
    image:cdn('/Case_studies/Revo_fitness/ari-filter.jpg'),
    width:'25%',
    className:'special-image-three'
  },
  {
    id:2,
    image:cdn('/Case_studies/Revo_fitness/Revo_stat.jpg'),
    width:'25%',
    className:'special-image-three'
  }

]

const videos = [
  {
    id: 1,
    url: cdn('/Case_studies/Revo_fitness/REVO_INNALOO PRESALE_HERO VIDEO-16_9 v3 FINAL.mp4'),
    width:'90%',
    className:'special-video'
  },

];


export default class RevoFitnessInnaloo extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="revo-fitness-wrapper">
      <MetaTags>
        <title>Revo Fitness - Innaloo launch  - OKMG</title>
        <meta name="description" content="Revo Fitness - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/revo-fitness-innaloo'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Revo Fitness" role="heading"
        sub_content="Strategy | Marketing | Creative | Digital"
        service_info="In late 2020 Revo Fitness made the move to open their 9th and biggest gym so far, located in Innaloo. A creative marketing campaign was required to position the brand to be the gym of choice in the greater Innaloo area."
        image={cdn('/Case_studies/Revo_fitness/Revo_header-illustration.png')}
        image_width="100%"
        foot_image={cdn('/Case_studies/Revo_fitness/Revo_crumb-illustration-1.png')}
        foot_image_width="300px"
        main_image_position_right="-50px"
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>Revo Fitness is known for providing a gym membership that cuts above the rest with their intimate knowledge and understanding of their clientele. The launch of their new gym in Innaloo meant directly going head to head competing in a saturated gym market in the immediate area, requiring a campaign with uniquely localised messaging.</p>
            <p>We drew inspiration from the iconic neighbours within the area, brands that are ingrained within our culture, IKEA and more locally, Spudshed. From here we were able to curate our campaign messaging and creative content; including an interactive AR Filter and a collaboration with the Spudshed, prompting our audience to describe this as the partnership they never knew they needed and opening up future opportunities between the two iconic brands.</p>
            <p>Through this positioning, we were able to pivot the brand in a more playful direction.</p>
            <p>With messaging that had a distinct tongue in cheek feel - ‘Size That Matters' and ‘Are You Inn? We were able to create a human experience and generate lasting engagement between the audience and Revo Fitness. </p>
            <p>Revo Fitness Innaloo had the most sign-ups for one of their gyms so far. As a brand that keeps on growing, we’re excited to facilitate Revo Fitness on its pathway to better and bigger opportunities.</p>
          </div>}
        />

     
        <ComponentCaseImageGifs 
         stats={images_one}
         marginTop="80px"
         width="70%"
        />

        <div className="vid-img-wrap">
          <ComponentVideos 
          stats={videos}
          width="80%"
          />
          <img src={cdn('/Case_studies/Revo_fitness/Insta Gif.gif')} alt="instagram post" className="special-image-s"/>
        </div>


          <ComponentVideo
          video_url={cdn('/Case_studies/Revo_fitness/REVO_INNALOO-FLYTHROUGH-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          controls="true"
          />
           <ComponentCaseImageGifs 
         stats={images_two}
         marginTop="100px"
         width="70%"
         wrapperClass="gif-wrapper"
        />
         <ComponentVideo
          video_url={cdn('/websiteScrolls/Revo2-rz.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          controls="true"
          screenCast
          />
           <ComponentCaseImageGifs 
           stats={images_three}
           marginTop="100px"
           width="70%"
          />
          <ComponentFullBleedImage 
          image={cdn('/Case_studies/Revo_fitness/20201111 REVOInnalooFitout-1-web.jpeg')}
          width="100%"
          marginTop="100px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          />
   
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}