/*
  ComponentFooter.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentHomeCaseStudies.scss';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';
import cdn from '../../lib/cdn';
// images
let timer = null;



class LazyBackground extends React.Component {
  state = { src: null };

  componentDidMount() {
    const { src } = this.props;

    const imageLoader = new Image();
    imageLoader.src = src;

    imageLoader.onload = () => {
      this.setState({ src });
    };
  }

  render() {
    return <span {...this.props} style={{ backgroundImage: `url(${this.state.src || this.props.placeholder})` }} className="case-bg-image init"></span>;
  }
}


export default class ComponentHomeCaseStudies extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }

  cycleHover(event) {
  if(timer != null){
    clearInterval(timer);
  }   
  const containerEl = event.currentTarget;

   timer = setInterval(function () {

      let index = -1;
      for (let i = 0; i < 5; i++) {
        if (containerEl.children[i].style.opacity === '1') {
          index = i;
          break;
        }
      }

      if (index > -1) {
        containerEl.children[index].style.opacity = '0';
        index = index + 1 >= 5 ? 0 : index + 1;
        containerEl.children[index].style.opacity = '1';
      }

    }, 400);
 }
 
 cycleHoverOff(event){
  
 clearInterval(timer);
 const containerEl = event.currentTarget;
 for (let i = 0; i < 4; i++){
   containerEl.children[0].style.opacity = '1';
   containerEl.children[1].style.opacity = '0';
   containerEl.children[2].style.opacity = '0';
   containerEl.children[3].style.opacity = '0';
   containerEl.children[4].style.opacity = '0';
 }
}

  render(){

   const {
      client_info,
      main_heading,
      crumb
      } = this.props;

  return (
   
    <div className="component-home-case-studies-main-wrap">
      {crumb ? 
        <img src={crumb} className="crumb" alt="crumb"/>
        : 

        ''
      }
      { main_heading ?
        <Reveal effect="fadeInUp">
          <h3 className="heading">{main_heading}</h3>
        </Reveal>
         : ''
      }
      <div className='component-home-case-studies'>
       {
          client_info.map(info =>
          <Reveal effect="fadeInUp" key={info.key}>
          <div  className={'sub sub-'+info.number} onMouseEnter={this.cycleHover.bind(this)} onMouseLeave={this.cycleHoverOff.bind(this)} >
            <LazyBackground src={info.backgroundImage} style={{'opacity':'1'}} className="case-bg-image init" ></LazyBackground>
            <span style={{ backgroundImage: `url('${info.hover_image_one}')`,opacity:'0' }} className="case-bg-image h hover-image-one" ></span>
            <span style={{ backgroundImage: `url('${info.hover_image_two}')`,opacity:'0' }} className="case-bg-image h hover-image-two" ></span>
            <span style={{ backgroundImage: `url('${info.hover_image_three}')`,opacity:'0' }} className="case-bg-image h hover-image-three"></span>
            <span style={{ backgroundImage: `url('${info.hover_image_four}')`,opacity:'0' }} className="case-bg-image h hover-image-four"></span>
           {info.video ?  <video className="hover-video" muted autoPlay loop> <source src={info.video} type="video/mp4" /> Your browser does not support the video tag.</video> : ''}
            <Link to={info.link}>
              <span className="client-info">
                 <h3>{info.client}<span><img src={cdn('right-arrow.svg')} className="arrow-right" alt="->"/></span></h3>
                  <link rel="prefetch" href={info.backgroundImage} />
                  <link rel="prefetch" href={info.hover_image_one} />
                  <link rel="prefetch" href={info.hover_image_two} />
                  <link rel="prefetch" href={info.hover_image_three} />
                  <link rel="prefetch" href={info.hover_image_four} />
               </span>
               </Link>
              </div>

          </Reveal>
          )
        }
      </div>
    </div>
     
    );
  }
}