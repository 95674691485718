/*
  ChickenTreat.jsx
  @Component - ChickenTreat Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ChickenTreat.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';
import Reveal from 'react-reveal/Fade';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';


// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/ChickenTreat/ct_img_box1.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/ChickenTreat/ct_img_box2.png'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/ChickenTreat/ct_img_box3.png'),
    background_size: 'cover',
  }

]
// for words + images
const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/ChickenTreat/ct_text1.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/ChickenTreat/ct_chip_hand.png'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/ChickenTreat/ct_text2.png'),
    background_size: 'cover',
  },
  {
    id: 4,
    image: cdn('/Case_studies/ChickenTreat/ct_chook_bag.png'),
    background_size: 'cover',
  }

]

const images_gifs = [
  {
   id: 1,
   image: cdn('/Case_studies/ChickenTreat/ct_gif1.gif'),
   width:'30%',
 },
 {
   id: 2,
   image: cdn('/Case_studies/ChickenTreat/ct_gif2.gif'),
   width:'30%',
 }

]

export default class ChickenTreat extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="ChickenTreat-wrapper twin-fin-wrapper">
      <MetaTags>
        <title>Cravable Brands - OKMG</title>
        <meta name="description" content="Chicken Treat has been a WA favourite for 46 years." />
        <link rel='canonical' href='https://okmg.com/work/cravable-brands'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Craveable Brands" role="heading"
        sub_content="Creative | Strategy"
        service_info="Chicken Treat has been a WA favourite for 46 years. Following their consistent growth in the local market, Chicken Treat was looking to break borders into the Eastern States. The team at Craveable Brands engaged OKMG to assist in introducing Chicken Treat to future franchise partners in the east."
        image={cdn('/Case_studies/ChickenTreat/ct_header_top.png')}
        image_class="header-image"
        image_width="100%"

        foot_image={cdn('/Case_studies/ChickenTreat/ct_logo.png')}
        />
        <Reveal up>
        <ComponentCaseCopy
        content={
          <div>
            <p>Chicken Treat has been a Western Australian icon since 1975, providing people with deliciously indulgent chicken perfect for everything from an afterschool snack to the ideal road trip partner.</p>
            <p>We were engaged by the team at Craveable Brands, who represents Chicken Treat, to assist in creating a campaign that would introduce the iconic brand to the Eastern States but also to drum up enquiries around investing in and owning a Chicken Treat restaurant. This campaign needed to inspire confidence in Chicken Treat as a brand by showcasing its success, strong foundations, business support, and future growth plans. </p>
            <p>The existing brand language for Chicken Treat sits in a very playful ‘comic’ book’ space which has proven highly effective in communicating with consumers. We leveraged this branding, refining it further to communicate with an audience who were looking at how this tantalising chicken can create a thriving business for them. Pulling from famous superhero catchphrases we developed the messaging for the campaign ‘The Legend Is Coming’ and ‘With Legendary Chicken Comes Amazing Opportunity’.</p>
            <p>Using existing assets we combined imagery of food, people and environment and brought these to life with custom illustrations and animation - emphasising the opportunity and support that future Franchise Partners will receive from Chicken Treat & Craveable Brands.</p>
            <p>The campaign launched successfully across Chicken Treat's website and LinkedIn and Seek. It highlights the playful and vibrant atmosphere of Chicken Treat, inspiring future Franchise Partners to enquire about growing their own opportunities through the iconic brand.</p>
          </div>}
        />
        </Reveal>

        <Reveal up>
          <ComponentCaseImageGifs 
            stats={images_gifs}
            marginTop="60px"
            width="80%"
            className="gif-wrap"
          />
        </Reveal>


        <ComponentVideo
          video_url={cdn('/Case_studies/ChickenTreat/ct_comm_vid.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
        />

        <ComponentCaseImages
          stats={images_two}
          marginTop="100px"
          width="100%"
          className="text-block"
        />

        <ComponentFullBleedImage
        image={cdn('/Case_studies/ChickenTreat/ct_img_big.png')}
        marginTop="150px"
        width="100%"
        className="big-box"
        />

        <ComponentCaseImages
          stats={images}
        />

        <ComponentCaseNav
        match="revo-fitness"
        />
     </div>
    );
  }
}