/*
  RevoFitness.jsx
  @Component - Revo Fitness Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './RevoFitness.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/Revo_fitness/square-one.jpg'),
    background_size: 'cover',
    background_color: 'rgb(224 225 226)',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Revo_fitness/square-two.jpg'),
    background_size: 'contain',
    background_color: 'rgb(224 225 226)',
    background_repeat:'no-repeat'
  },
  {
    id: 3,
    image: cdn('/Case_studies/Revo_fitness/REVO-26-web.jpeg'),
    background_size: 'cover',
    background_color: 'green',
  }

]


export default class RevoFitness extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="revo-fitness-wrapper">
      <MetaTags>
        <title>Revo Fitness - Own Your Fit - OKMG</title>
        <meta name="description" content="Revo Fitness - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/revo-fitness'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Revo Fitness" role="heading"
        sub_content="Strategy | Marketing | Creative | Digital"
        service_info="Revo Fitness has fast become an industry disruptor offering affordable and flexible gym memberships in an inclusive environment. Following rapid growth, the Revo Fitness brand messaging ‘Own Your Fit’ pivoted to reconnect to their audience."
        image={cdn('/Case_studies/Revo_fitness/revo-mocks.jpg')}
        image_width="110%"
        foot_image={cdn('/Case_studies/Revo_fitness/REVO FITNESS_RED BRAND MARK-1.png')}
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>Revo Fitness provides customers with a highly competitive offering, the most flexible and affordable gym memberships in a saturated 24-hour gym market. Revo Fitness has amassed a loyal customer base which continues to grow with each new location. Through this rapid growth however, the Revo Fitness brand messaging had lost alignment and no longer had the same connection with customers.</p>
            <p>OKMG was engaged in developing a more dynamic brand identity to facilitate growth and empower new and existing members. The Revo Fitness philosophy is that there is no one person who interacts with the gym the same way, encouraging members to define their own version of health and fitness. Recognising this, OKMG worked to refine their ethos into a central hero message: Own Your Fit.</p>
            <p>The ‘Own Your Fit’ campaign was brought to life through content and through a fully customised website design and build, hero campaign video, photography and an integrated digital campaign.</p>
            <p>With their new positioning, Revo’s strong core values are now emphasised through a clean and confident approach to all web and design applications, continuing to connect and engage with their existing target market and extending into more challenging customer groups.</p>
          
          </div>}
        />

        <ComponentVideo
          video_url={cdn('/Case_studies/Revo_fitness/revo-first-vidoe.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          controls="true"
          controlsoff
          />
          <ComponentVideo
          video_url={cdn('/Case_studies/Revo_fitness/revo-vide-home-trimmed.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          controls="false"
          screenCast
          controlsoff
          />
           <ComponentFullBleedImage
          image={cdn('/Case_studies/Revo_fitness/revo-stats-v22.jpg')}
          marginTop="100px"
          width="100%"
          />
          <ComponentVideo
          video_url={cdn('/Case_studies/Revo_fitness/revo-second-video.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          controls="true"
          />
          <ComponentFullBleedImage 
          image={cdn('/Case_studies/Revo_fitness/REVO_POSTERS_MOCKUP-v2.jpg')}
          width="100%"
          marginTop="100px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          />
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}