/*
  Cicerellos.jsx
  @Component - Cicerellos Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './Cicerellos.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]
// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/Cicerellos/IMAGE03-v2.png'),
  },
  {
    id: 2,
    image: cdn('/Case_studies/Cicerellos/square-two.jpg'),
  },
  {
    id: 3,
    image: cdn('/Case_studies/Cicerellos/square-three.png'),
  }

]

const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/TwinFin/finz-frensy.svg'),
    background_size: '600px',
    background_repeat:'no-repeat',
    background_position:'right',
    width:'50%',
    background_position:'right',
    className:'special-image'
  },
  {
    id: 2,
    image: cdn('/Case_studies/TwinFin/stat-two.svg'),
    background_size: '300px',
    background_repeat:'no-repeat',
    width:'40%',
    background_position:'left',
    className:'custom-height-mob'
  },
  {
    id: 3,
    image: cdn('/Case_studies/TwinFin/stat-one.svg'),
    background_size: '300px',
    background_repeat:'no-repeat',
    width:'40%',
    background_position:'right',
    className:'custom-height-mob'

  },
  {
    id: 4,
    image: cdn('/Case_studies/TwinFin/fingo.svg'),
    background_size: '600px',
    background_repeat:'no-repeat',
    width:'50%',
    background_position:'left',
    className:'special-image'
  },


]

const images_gifs = [
   {
    id: 1,
    image: cdn('/Case_studies/TwinFin/image_1%402x.png'),
    width:'20%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/TwinFin/Swell Times IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/TwinFin/TWIN FIN_INSTA%402x.png'),
    width:'30%',
  },
  {
    id: 4,
    image: cdn('/Case_studies/TwinFin/Open Now IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 6,
    image: cdn('/Case_studies/TwinFin/image_1%402x.png'),
    width:'20%',
  },


]


export default class PrideWa extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="cicerellos-wrapper">
      <MetaTags>
        <title>Cicerello's - The home of Fish n Chips - OKMG</title>
        <meta name="description" content="Cicerellos - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/cicerellos'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Cicerello's" role="heading"
        sub_content="Strategy | Digital | Creative | Marketing"
        service_info="Celebrating the iconic 100 year old history of Cicerello’s fishing pioneers, recontextualised to a modern Australian audience within a campaign cementing Cicerello’s as an icon within the Fremantle community. This is Sharing Pioneered."
        hover_video_one={cdn('/websiteScrolls/cicerellos-rz-min.mp4')}
        hover_videobg_one={cdn('/websiteScrolls/iMac_Mockup_v2.png')}
        hover_video_two={cdn('/websiteScrolls/cicerellos-mobile-rz.mp4')}
        hover_videobg_two={cdn('/websiteScrolls/iPhone X Mockup_v2.png')}
        image_class="header-image"
        image_width="120%"
        foot_video={cdn('/Case_studies/Cicerellos/tentacle-rz.mp4')}
        // foot_image={cdn('/Case_studies/Cicerellos/tentacles.png')}
        foot_image_width="100%"
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>Founded by fishing pioneers in 1903, Cicerello’s has been an iconic establishment in Fremantle, WA. Although being busy as ever, Cicerello’s felt like they had lost touch with the new generation of fish ’n’ chip lovers.  </p>
            <p>Diving into their rich and diverse past, OKMG refined the Cicerello’s brand narrative to have repurposed longevity, bringing new meaning to the Fremantle icon, centered around the idea of sharing stories, culture and of course, meals!</p>
            <p>Through revitalised branding we created a visual language to engage with audiences. Brought to life through both an interactive website and an integrated marketing campaign both lead strategically to communicate directly to the Cicerello’s community, continuing to immerse them in the world of Cicerello’s.</p>
             <ComponentReadMore
             content={
              <span>
               <p>Cicerello’s has stayed true to its fishing roots over it’s 100 year history, however as time has gone on, the nostalgic stories shared have gotten lost. OKMG were engaged to reinvigorate Cicerello’s for a new audience of fish and chip lovers, without losing the history that makes Cicerello’s so distinctive to Fremantle. We weren’t looking to rebrand, but fortify. </p>
               <p>Paying particular attention to the history of Cicerello’s, OKMG developed a timeless campaign that pays homage to the past whilst resonating with new audiences. We underwent an extensive research period connecting with Fremantle locals, undertaking staff interviews and understanding WA’s fishing history, to identify the core purpose of the iconic establishment that has stayed relevant over time.</p>
               <p>From an exploration of the Cicerello’s brand, OKMG identified a key theme that has remained consistent over time. From the creation of stories, celebration of history and communal ritual of fish ’n’ chips, Cicerello’s has welcomed everyone to become part of their shared culture. Sharing is what has defined Cicerello’s in the past and will continue to unite them in the future.</p>
               <p>Working with a 100 year old brand, OKMG wanted to complement and enhance the Cicerello’s style by giving new life to the nostalgic icon.</p>
               <p>A new family of design elements were created to give the next generations of patrons ownership over the brand and expand into a more memorable visual language. These new design elements opened up opportunities to communicate across platforms and create confident cut through.</p>
               <p>From the hero brand video to graphical elements, all creative content was developed in line with the Cicerello’s purpose to create and share memories, old and new, with everyone.</p>
               <p>The new Cicerello’s website was designed specifically to enhance user experience, whilst ensuring all information is easily accessible and appropriate for local and foreign customers. Providing a fully integrated experience online, OKMG designed and developed a website that brought the brand to life through cheeky animated sea creatures, an emotive hero video, historical imagery and accessible store information.</p>
               <p>Through an integrated approach to digital channel management, consumer targeted messaging and attention grabbing content, OKMG have encouraged deep social engagement and website traction.</p>
              </span>
            }
             />
          </div>}
        />


        <ComponentVideo
          video_url={cdn('/Case_studies/Cicerellos/cic-brand-web.min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />

          <ComponentFullBleedImage 
          image={cdn('/Case_studies/Cicerellos/CICERELLOS_STATS BLOCK.jpg')}
          width="100%"
          marginTop="50px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"

          />
           <ComponentFullBleedImage 
          image={cdn('/Case_studies/Cicerellos/CICERELLOS_MENU.jpg')}
          width="100%"
          marginTop="50px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          special_class_wrapper="special-wrapper"
          //special_video={cdn('/Case_studies/Cicerellos/crabarm-rz.mp4')}
          // special_image={cdn('/Case_studies/DDC/bg-l-light2.svg')}
          />
          
          <ComponentFullBleedImage 
          image={cdn('/Case_studies/Cicerellos/sharing-pioneered.jpg')}
          width="100%"
          marginTop="100px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"

          />
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pride-wa"
          />
     </div>
    );
  }
}