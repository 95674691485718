/*
  DDC.jsx
  @Component - Edith Cowan College Case Study
*/

import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './Dimattina.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
import { canUseDOM } from 'react-helmet';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/DDC/DDC-4-web.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/DDC/DDC-5-web.jpg'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/DDC/DDC-7-web.jpg'),
    background_size: 'cover',
  }

]


const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/DDC/58animateddisplays.svg'),
    // width:'30%',
    className:'img-svg',
  },
  {
    id: 2,
    image: cdn('/Case_studies/DDC/documentation.jpg'),
    // width:'33%',
    borderRadius: '32px',
    className:'img',

  },
  {
    id: 3,
    image: cdn('/Case_studies/DDC/40minimmersiveaudio.svg'),
    className:'img-svg',
  },
  {
    id: 4,
    image: cdn('/Case_studies/DDC/2018.12.20 - DDC (2 of 14)-v2.jpg'),
    borderRadius: '32px',
    className:'img',

  },
  {
    id: 5,
    image: cdn('/Case_studies/DDC/13Audio visualexhibits.svg'),
    borderRadius: '32px',
    className:'img-svg',
  },
  {
    id: 6,
    image: cdn('/Case_studies/DDC/DDC-7-web.jpg'),
    borderRadius: '32px',
    className:'img',
  },

];

const images_three = [
  {
    id: 1,
    image: cdn('/Case_studies/DDC/DDC_LOGO-1-1-300x80.png'),
    className:'img-logo',
  },
  {
    id: 2,
    image: cdn('/Case_studies/DDC/DDC_LOGO-2-300x154.png'),
    className:'img-logo',
  },
  {
    id: 3,
    image: cdn('/Case_studies/DDC/DDC_LOGO0-3-300x139.png'),
    className:'img-logo',
  },
];

const stat_info = [
  
  
  {
    id: 1,
    amount: '"The Buzz has become a favourite among staff and students and this is thanks to the creativity of the OKMG team. They are very focused on the client and really take the time to understand the business/industry that we\'re in, to ensure the content is relevant and successful. I really enjoy working with them as they are very approachable and flexible!"',
    text: 'Sophie Waddell, Marketing Manager - South East Asia',
    color: 'rgb(10 78 132)',
  },
  {
    id:2,
    image_left:cdn('/Case_studies/DDC/DDC_PHONE MOCKUP.png'),
    has_image: true,
    image_width:'460px',
    color: 'rgb(10 78 132)',
  },

];

export default class DDC extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="Dimattina-wrapper">
      <MetaTags>
        <title>Dimattina Coffee  - OKMG</title>
        <meta name="description" content="Dimattina Coffee - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/dimattina-coffee'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Dimattina coffee" role="heading"  
        sub_content="Marketing | Digital"
        service_info="The Dimattina’s have a rich history in the coffee industry dating back to the 1950s. OKMG was engaged to refine Dimattina’s website and SEO, to ensure brand consistency and relevancy as they continue o grow. "
        hover_image_two={cdn('/Case_studies/Dimattina/hover-3.jpg')}
        hover_image_one={cdn('/Case_studies/Dimattina/hover-4.jpg')}
        hover_image_three={cdn('/Case_studies/Dimattina/hover-5.jpg')}
        image_width="100%"
        className="special"
        image_class="header-image"
        foot_image={cdn('/Case_studies/Dimattina/bg-l-light.svg')}
        foot_image_width="350px"
        foot_image={cdn('/Case_studies/Dimattina/bg-l-light.svg')}
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>The Dimattina team has a strong passion for producing and delivering delicious, high quality coffee. It comes as no surprise that in Australia's coffee-obsessed landscape, Dimattina's rich, diverse flavour profiles have caused the brand to become a must-have item in coffee shops across the country, resulting in their expansion into the coffee wholesaler space.</p>
            <p>Dimattina engaged OKMG as their website needed a refresh to accommodate their increasing online orders. After reviewing the user experience, we uncovered that the existing template website placed strict e-commerce limitations, affecting the full user journey. </p>
            <p>We worked with the Dimattina marketing team to plan, validate, design & develop a custom Shopify website that could increase their online subscriptions. We unearthed a better understanding of the Dimattina audience user journey and adjusted the site structure to suit.</p>
            <p>The new custom website is engineered to provide ongoing support to Dimattina as they continue to grow, significantly reducing the need for site maintenance. The result; A relevant site for the Dimattina customer, supported by SEO, creating an effortless online experience.</p>
          </div>}
        />

        <ComponentVideo
          video_url={cdn('/Case_studies/DDC/DDC-final.min3.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />

          <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="100px"
          special_class_wrapper="special-wrapper"
          special_image={cdn('/Case_studies/DDC/bg-tl-dark-resize.svg')}
          />

          {/* <ComponentCaseStats
            stats={stat_info}
            has_image='true'
          /> */}
          
           <ComponentFullBleedImage
          image={cdn('/Case_studies/DDC/DDC_infographic.png')}
          marginTop="0px"
          marginTop="200px"
          className="infographic"
          special_class_wrapper="special-wrapper2"
          special_image={cdn('/Case_studies/DDC/bg-l-light2.svg')}
          />

          <div class="logo-section">
          <ComponentCaseImageGifs 
          stats={images_three}
          marginTop="200px"
          />
          </div>

           <ComponentFullBleedImage
          image={cdn('/Case_studies/DDC/2018.12.20 - DDC (5 of 14)-web-v2.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="200px"
          />
  
          <ComponentCaseImages
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}