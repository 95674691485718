/*
  StirlingCapital.jsx
  @Component - Stirling Capital Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './StirlingCapital.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/StirlingCapital/LOBBY-17-web.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/StirlingCapital/BAR-5-web.jpg'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/StirlingCapital/1602-BEDROOM-1.jpg'),
    background_size: 'cover',
  }

]


const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/StirlingCapital/STIRLING_PHONE_MOCKUP-min.jpg'),
    width:'60%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/StirlingCapital/Stirling_icons.svg'),
    width:'40%',
    borderRadius: '32px',
    className:'special'
  },


];


export default class PilbaraMinerals extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="stirling-capital-wrapper">
      <MetaTags>
        <title>Stirling Capital - City Living Re-Imagined - OKMG</title>
        <meta name="description" content="Stirling Capital - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/stirling-capital'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Stirling Capital" role="heading"  
        sub_content="Strategy | Creative"
        service_info="Stirling Capital came to OKMG looking for content for their newest inner-city project, Verdant Apartments. This $80M, energy-efficient, green living apartment complex was an exciting step forward for Perth."
        image={cdn('/Case_studies/StirlingCapital/verdant-mockup.jpg')}
        image_width="90%"
        foot_image={cdn('/Case_studies/StirlingCapital/Stirling-Logo.png')}
        foot_image_width="300px"
        foot_image_left="0px"
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>OKMG was engaged to create and capture a realistic narrative that resonated with a ‘new type’ of Perth, showing the inner city lifestyle and promoting shared living while highlighting the buildings unique features.</p>
            <p>More traditional property developments focus the completion content primarily on the building and the quality of its finishes. With Verdant, we put the lifestyle front and centre and let the building speak for itself.</p>
            <p>Wanting a deeper understanding of the desired clientele, developing a situational analysis to create personas of our target audience. From this insight we approached this project with a strong narrative approach, creating multiple characters, lifestyles and storylines; curating content to have an emotive engagement with the audience.</p>
            <p>The result of this project was aspirational pieces of videography. ‘Stay & Play in the City’ - where convenience is commonplace and freedom of choice is endless, this is city living, re-imagined.</p>
          </div>}
        />

        <ComponentVideo
          video_url={cdn('/Case_studies/StirlingCapital/stirling-capital-video-min-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
          <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="100px"
          width="80%"
          />
           <ComponentFullBleedImage
          image={cdn('/Case_studies/StirlingCapital/IMG_0400-Edit-web.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />
  
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}