/*
  ComponentFooter.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentFooter.scss';
import Reveal from 'react-reveal/Reveal';

// images
const facebook = "https://okmg-digital-assets.s3-ap-southeast-2.amazonaws.com/okmg/facebook.svg";
const instagram = "https://okmg-digital-assets.s3-ap-southeast-2.amazonaws.com/okmg/instagram.svg";
const youtube = "https://okmg-digital-assets.s3-ap-southeast-2.amazonaws.com/okmg/youtube.svg";
const linkedin = "https://okmg-digital-assets.s3-ap-southeast-2.amazonaws.com/okmg/linkedin.svg";

export default class ComponentFooter extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
    render(){


  return (
    <div className="component-footer">
    
      <div className="footer-wrapper">
        <div className="left-content">
          <div className="row row-one addresses">
            <Reveal effect="fadeInUp">
             <h5>Perth</h5>
            </Reveal>
            <ul>
              <Reveal effect="fadeInUp">
               <li>608 - 610 Stirling Hwy</li>
              </Reveal>
              <Reveal effect="fadeInUp">
                <li>Mosman Park, Perth WA, 6012</li>
              </Reveal>
            </ul>
          </div>
          <div className="row row-two addresses">
            <Reveal effect="fadeInUp">
              <h5>Melbourne</h5>
            </Reveal>
            <ul>
              <Reveal effect="fadeInUp">
                <li>Level 17, 31 Queen St</li>
              </Reveal>
              <Reveal effect="fadeInUp">
                <li>Melbourne, VIC, 3000</li>
              </Reveal>
            </ul>
          </div>
          <div className="row row-three addresses">
            <Reveal effect="fadeInUp">
              <h5>Sydney</h5>
            </Reveal>
            <ul>
            <Reveal effect="fadeInUp">
              <li>333 George St</li>
            </Reveal>
            <Reveal effect="fadeInUp">
              <li>Sydney, NSW, 2000</li>
            </Reveal>
            </ul>
          </div>
          <div className="row row-four">
            <ul className="social-links">
            <Reveal effect="fadeInUp">
            <li><a href="https://www.facebook.com/OKMGAustralia" target="_blank" rel="noopener">Facebook</a></li>
            </Reveal>
            <Reveal effect="fadeInUp">
            <li><a href="https://www.instagram.com/okmg.agency/?hl=en" target="_blank" rel="noopener">Instagram</a></li>
            </Reveal>
            <Reveal effect="fadeInUp">
            <li><a href="https://www.youtube.com/channel/UC8CFbRXOKKu37F25p7aKhRA?feature=emb_subscribe_title" target="_blank" rel="noopener">Youtube</a></li>
            </Reveal>
            <Reveal effect="fadeInUp">
            <li><a href="https://www.linkedin.com/company/okmg/?originalSubdomain=au" target="_blank" rel="noopener">Linkedin</a></li>
            </Reveal>
            </ul>
          </div>
        </div>
       
       </div>
       <div className="bottom-content">
        <ul>
          <Reveal effect="fadeInUp">
            <li>Copyright © {(new Date().getFullYear())}</li>
          </Reveal>
          <Reveal effect="fadeInUp">
          <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
          </Reveal>
          <Reveal effect="fadeInUp">
            <li><Link to="/privacy-policy">Privacy</Link></li>
          </Reveal>
          <Reveal effect="fadeInUp">
            <li><Link to="/disclaimer">Disclaimer</Link></li>
          </Reveal>
          <Reveal effect="fadeInUp">
          <li><Link to="/payments">Payments</Link></li>
          </Reveal>
         </ul>
       </div>
    </div>
    );
  }
}