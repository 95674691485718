/*
  PerthFestival.jsx
  @Component - PerthFestival Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './PerthFestival.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';
import Reveal from 'react-reveal/Reveal';


// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/PerthFestival/Screen-Shot-2021-03-09-at-7.50.42-pm.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/PerthFestival/Screen-Shot-2021-03-09-at-7.51.45-pm.png'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/PerthFestival/Screen-Shot-2021-03-09-at-7.51.32-pm.png'),
    background_size: 'cover',
  }

];

const stat_info = [
  {
    id: 1,
    amount: '"OKMG are the best! Professional, creative, personable and a dream to work with. Every project has been delivered on time. It\'s a pure joy to work with them"',
    text: 'Rachel Audino - Digital Content Strategist',
    color: 'rgb(44, 45, 65)',
  },

];


export default class PerthFestival extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="perthfestival-wrapper">
      <MetaTags>
        <title>Perth Festival - OKMG</title>
        <meta name="description" content="Perth Festival - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/perth-festival'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Perth Festival" role="heading"  
        sub_content="Creative"
        service_info="The Perth Festival is Australia’s oldest cultural festival, and has become known globally as one of the most highly anticipated multi-arts events, providing our community with entertainment for the past 68 years."
        hover_image_one={cdn('Case_studies/PerthFestival/Screen-Shot-2021-03-09-at-7.52.35-pm.png')}
        hover_image_two={cdn('/Case_studies/PerthFestival/Screen-Shot-2021-03-09-at-7.51.32-pm.png')}
        hover_image_three={cdn('/Case_studies/PerthFestival/Screen-Shot-2021-03-09-at-7.48.36-pm.png')}
        foot_image={cdn('/Case_studies/PerthFestival/Perth Festival_Stacked_Mono.png')}
        className="special"
        image_class="header-image"

        foot_image_width="300px"
        foot_image_left="0px"
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>Each year the Festival attracts thousands of locals and tourists, eager to catch a glimpse of the live music, art and shows available. Running for only three weeks each year, the Festival contributes an impressive amount to the local economy and community. Last year alone, the 475,000 attendees of the Festival generated over 32 million into the WA economy.</p>
            <p>OKMG work alongside Perth Festival as their primary content partner to create event promotions, television ads and wrap videos, furthering their digital footprint and creating lasting visual memories. Our team has captured countless incredible events, and are constantly impressed by the quality and quantity of shows in the Perth Festival.</p>
            <p>Perth Festival continues to encourage and support up-and-coming local talent, alongside well-known Australian and international artists. As our annual collaboration with Perth Festival draws closer, the OKMG team always look forward to seeing what they have in store for us next.</p>
          </div>}
        />

        <ComponentVideo
          video_url={cdn('/Case_studies/PerthFestival/Perth-festival.min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />

        <Reveal effect="fadeInUp">
        <div className="stat-wrapper">
          <div className="video-wrap">
            <video class="left-vid" autoplay="autoplay" loop muted playsinline >
              <source src={cdn('/Case_studies/PerthFestival/9x16 BOOK NOW ANIMATION_1.mp4')} type="video/mp4"/>
            </video>
          </div>
          <div className="insta-wrap">
            <img class="insta-svg" src={cdn('/Case_studies/PerthFestival/PerthFest_Insta Template.svg')} alt=""/>
            <video class="insta-vid" autoplay="autoplay" loop muted playsinline>
              <source src={cdn('/Case_studies/PerthFestival/PERTHFEST_LOTTERYWEST_SQUARE_30sec%232_EDIT V1_1.mp4')} type="video/mp4"/>
            </video>
          </div>
          <ComponentCaseStats
            stats={stat_info}
            has_image='false'
          />
        </div>
        </Reveal>

          <ComponentFullBleedImage
          image={cdn('/Case_studies/PerthFestival/Screen-Shot-2021-03-09-at-7.54.03-pm.png')}
          width="100%"
          marginTop="0px"
          marginTop="50px"
          />
  
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}