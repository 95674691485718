/*
  ComponentReviewSlider.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentReviewSlider.scss';
import Slider from "react-slick";
import BlobTwo from './../blobTwo';
import Reveal from 'react-reveal/Reveal';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// images
const quote_black = "https://okmg-digital-assets.s3-ap-southeast-2.amazonaws.com/okmg/quote.svg";
const quote_white = "https://okmg-digital-assets.s3-ap-southeast-2.amazonaws.com/okmg/quote-white.svg";
// const left_arrow = "https://okmg-web-assets-rc.s3-ap-southeast-2.amazonaws.com/okmg-left-arrow-black.svg";
const left_arrow = "https://okmg-web-assets-rc.s3-ap-southeast-2.amazonaws.com/right-arrow.svg";
const right_arrow = "https://okmg-web-assets-rc.s3-ap-southeast-2.amazonaws.com/left-arrow.svg";
// const right_arrow = "https://okmg-web-assets-rc.s3-ap-southeast-2.amazonaws.com/okmg-right-arrow-black.svg";
const star = "https://okmg-web-assets-rc.s3-ap-southeast-2.amazonaws.com/star+(1).svg";

function LeftNavButton(props) {
    const {className, style, onClick} = props
    return (
        <div
            className="slick-arrow prev"
            style={{...style, display: 'block'}}
            onClick={onClick}
        >
           <img src={right_arrow } width="20" alt="->" width={20} height={20}/>
        </div>
    );
}

function RightNavButton(props) {
    const {className, style, onClick} = props
    return (
        <div
            className="slick-arrow next"
            style={{...style, display: 'block'}}
            onClick={onClick}
        >
           <img src={left_arrow } width="20" alt="<-" width={20} height={20}/>
        </div>
    );
}


export default class ComponentReviewSlider extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }

  render(){

   const {

      } = this.props;

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay:true,
    prevArrow: <LeftNavButton />,
    nextArrow: <RightNavButton/>,
    responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
  };

  return (
      <div className="ComponentReviewSlider">
       
        <div className="wrapper">
     
          <Reveal effect="fadeInUp">
            <h3>Don't just take<br/> our word for it.</h3>
          </Reveal>
           
          <Slider {...settings}>

            <div>
              <span>
                 <Reveal effect="fadeInUp">
                  <div className="star-rating">
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                  </div>
                  <p>"Hair Supplies couldn't be happier with the service, productivity and results from the team at OKMG. We started our marketing just before COVID-19 struck and it turned out to be a masterstroke. Strong, consistent and creative output has been generating outstanding results."</p>
                  <div className="review-bot">
                    <h4>Murray Willock</h4>
                    <p className="company-title">General Manager, Hair Supplies Pty Ltd</p>
                  </div>
                </Reveal>
                 <div className="quote-wrapper">
                  <Reveal effect="fadeInUp">
                    <img src={quote_white} width={50} height={50} alt="quote"/>
                  </Reveal>
                </div>
              </span>
             </div>
            <div>
             <span>
                <Reveal effect="fadeInUp">
                 <div className="star-rating">
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                  </div>
                  <p>"The team at OKMG is great to work with - they have developed a range of highly creative content for us from videos, photos and EDM. They are all very lovely and professional and they really take the time to understand our needs and our industry to create and deliver relevant and successful content"</p>
                  <div className="review-bot">
                    <h4>Sophie Waddell</h4>
                    <p className="company-title">Marketing Manager, Edith Cowan College</p>

                  </div>
                </Reveal>
                 <div className="quote-wrapper">
                  <Reveal effect="fadeInUp">
                    <img src={quote_white} width={50} height={50} alt="quote"/>
                  </Reveal>
                </div>
              </span>
            </div>
            <div>
             <span>
              <Reveal effect="fadeInUp">
                <div className="star-rating">
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                  </div>
                <p>"OKMG were fantastic to work with throughout the our company's re-brand and web-site redevelopment. Making a new brand and website happen in a short period of time, with multiple stakeholders and to ASX standards was always going to be a challenge but they didn’t miss a beat!"</p>
                <div className="review-bot">

                  <h4>Kaggen Ausma</h4>
                  <p className="company-title">Business Manager, PYC Therapeutics Pty Ltd</p>
                </div>
              </Reveal>
               <div className="quote-wrapper">
                  <Reveal effect="fadeInUp">
                    <img src={quote_white} width={50} height={50} alt="quote"/>
                  </Reveal>
                </div>
               </span>
            </div>
            <div>
             <span>
              <Reveal effect="fadeInUp">
                <div className="star-rating">
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                  </div>
                <p>"A great agency built on great people. Their work speaks for itself but the people you get to work with make the experience that much better. A vibrant and enthusiastic team that want to help you improve your brand and business"</p>
                <div className="review-bot">
                  <h4>Andrew Holder</h4>
                  <p className="company-title">Owner, Revo Fitness</p>
                </div>
              </Reveal>
               <div className="quote-wrapper">
                <Reveal effect="fadeInUp">
                  <img src={quote_white} width={50} height={50} alt="quote"/>
                </Reveal>
              </div>
             </span>
            </div>
            <div>
             <span>
              <Reveal effect="fadeInUp">
                <div className="star-rating">
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                  </div>
                <p>"The Team at OKMG are fantastic to deal with, from their outstanding professionalism to their masterful digital design and video producing abilities, they have you covered. The Town of Mosman Park now has an amazing platform to generate community interest in an exciting project. Thanks OKMG!"</p>
                <div className="review-bot">
                  <h4>Leigh Ashby</h4>
                  <p className="company-title">Business Manager, The Town of Mosman Park</p>
                </div>
              </Reveal>
               <div className="quote-wrapper">
                <Reveal effect="fadeInUp">
                  <img src={quote_white} width={50} height={50} alt="quote"/>
                </Reveal>
              </div>
               </span>
            </div>
            <div>
             <span>
              <Reveal>
               <div className="star-rating">
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                    <img src={star} className="star" width={25} height={25} alt="star"/>
                  </div>
                <p>"Built from the ground up, our StudyPerth asset library is now full of rich content produced by OKMG which we can and do use for anything and everything. It is seen as a highly valued tool for our members to have access to and allows us to repurpose content as required. OKMG understood what we were after from day dot and helped us capture the authentic student experience in Perth."</p>
                <div className="review-bot">
                  <h4>Angela Beechey</h4>
                  <p className="company-title">Senior Manager, Study Perth</p>
                </div>
              </Reveal>
               <div className="quote-wrapper">
                <Reveal effect="fadeInUp">
                  <img src={quote_white} width={50} height={50} alt="quote"/>
                </Reveal>
              </div>
            </span>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}