/*
  UWASport.jsx
  @Component - UWA Sport Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './UWASport.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/UWA_Sport/fence.jpeg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/UWA_Sport/UWA_POSTER_MOCKUP-03.png'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/UWA_Sport/square-web.jpg'),
    background_size: 'cover',
  }

]
// for images
const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/Lever_property/190221_LEVER-DA_336x280-01.gif'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Lever_property/190221_LEVER-DA_336x280-02.gif'),
    background_repeat: 'no-repeat',
    background_size: 'contain',
    background_color:'#e1e1e1'
  },
  {
    id: 3,
    image: cdn('/Case_studies/Lever_property/190221_LEVER-DA_336x280-03.gif'),
    background_size: 'cover',
  }

]


export default class UWASport extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="uwa-sport-wrapper">
      <MetaTags>
        <title>UWA Sport - See sport differently - OKMG</title>
        <meta name="description" content="UWA Sport - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/lever-property'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="UWA Sport" role="heading"  
        sub_content="Digital | Creative | Marketing"
        service_info="UWA Sport provides an extensive range of activities that encourage students to make health and fitness a central part of life at UWA. OKMG has assisted UWA Sport to build a brand that speaks for itself."
        image={cdn('/Case_studies/UWA_Sport/uwa-magazine.jpg')}
        image_width="90%"
        
        />
        <img src={cdn('/Case_studies/UWA_Sport/UWA SPORT_GRAPHIC DEVICE.svg')} class="crumb" style={{width:'100%'}}/>
         <ComponentCaseCopy
        
        content={
          <div>
            <p>UWA Sport require annual brochures that would engage with students at all sporting levels to get active. Confident that those already interested in sport would engage with their services, UWA Sport were looking to connect with those a little less likely to get involved and encourage them to see sport differently.</p>
            <p>Our design work for UWA Sport had to balance the established and prestigious UWA brand whilst resonating with the interests of young university students. Identifying a positive intersection between sport, academia and community, it became clear that the motivations of students carried across all activities. </p>
            <p>To bring this concept to life, we developed a confident brochure that effectively communicated the range of services available, highlighting alternative sports or activities such as yoga to engage with a broader audience.</p>
            <p>Aligning with the broader UWA brand elements, we created a custom style guide, producing a stronger visual identity to provide UWA Sport a stronger and more relevant voice within the academic market. </p>
            <p>Combining iconic hero photography and supporting graphic devices we were able to capture the full UWA Sport experience, going beyond the services and into the welcoming community that is a direct result of participating in social sports. Through this we were able to successfully communicate in a more relevant way and have a more lasting impact on the audience, driving more engagement to the UWA Sport brand.</p>
            <p>Whether training for gold or looking for a new skill, see sport differently.</p>
          </div>}
        />


         
           <ComponentFullBleedImage
          image={cdn('/Case_studies/UWA_Sport/UWA_BROCHURE_MOCKUP.jpg')}
          width="90%"
          marginTop="100px"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/UWA_Sport/UWA_Stats.svg')}
          width="110%"
          marginTop="100px"
          left="100px"
          />
          <ComponentFullBleedImage
          image={cdn('/Case_studies/UWA_Sport/UWA_Brochure.jpg')}
          width="100%"
          marginTop="100px"
          left="-100px"
          />
    
        <ComponentFullBleedImage
          image={cdn('/Case_studies/UWA_Sport/UWA_FLYER_MOCKUP.jpg')}
          width="70%"
          marginTop="100px"
          />
          <ComponentFullBleedImage
          image={cdn('/Case_studies/UWA_Sport/2018.03.08 UWA SPORTS SIGNAGE-15-web-v2.jpeg')}
          width="100%"
          marginTop="100px"
          />
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="uwa-sport"
          />
     </div>
    );
  }
}