/*
  ComponentFullBleedImage.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import './ComponentFullBleedImage.scss';
// images


export default class ComponentFullBleedImage extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  render(){

   const {
    image,
    marginTop,
    marginBottom,
    backgroundSize,
    backgroundRepeat,
    width,
    left,
    right,
    className,
    special_image,
    special_image2,
    special_video,
    special_class_wrapper,

      } = this.props;

  return (

    <div className={special_class_wrapper}>
      {special_video ?               
        <LazyLoad offset={100}>
        <video className="special_video_wrapper" muted="muted" autoplay="autoplay" loop="loop">
          <source src={special_video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
       </LazyLoad>
      : ''}
      {special_image ? <img src={special_image} className={`${special_class_wrapper ? special_class_wrapper : ''} special-img1`}/> : ''}
      {special_image2 ? <img src={special_image2} className="special-img2"/> : ''}
      <div className={className ? className + " component-full-bleed-image" : "component-full-bleed-image"} style={{marginTop:marginTop, marginBottom:marginBottom, backgroundSize: backgroundSize,backgroundRepeat:backgroundRepeat}}>
        <LazyLoad offset={100}>
        <img src={image} style={{maxWidth:width,position:'relative',left:left,right:right,width:'100%'}}/>
        </LazyLoad>
      </div>
    </div>
    );
  }
}