/*
  Primero.jsx
  @Component - Primero Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './Primero.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/Primero/INFOGRAPHIC.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Primero/IMAGE_01.png'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/Primero/PUBLICATION_A4_PAGES.png'),
    background_size: 'cover',
  }

]


const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/Primero/Primero_Pull Up Banner-2.png'),
    width:'calc(30% - 30px)',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Primero/Primero_website page animation.png'),
    width:'calc(60% - 30px)',
    borderRadius: '32px',
    className:'special'
  },
];

const stat_info = [
  
  
  {
    id: 1,
    amount: '"The design and development experience with the team was beyond positive. They\'re to be commended for the patience they demonstrated throughout our web rejuvenation process. They demonstrated a great deal of flexibility while we wrestled with these challenges to design and built what ultimately became a fantastic website."',
    text: 'Max Piirto - Business Development Manager',
    color: 'rgb(23 168 224)',
  },
  {
    id:2,
    image_left:cdn('/Case_studies/Primero/PRIMERO_PULL UP BANNERS_MOCKUP-2.png'),
    has_image: true,
    image_width:'260px',
    color: 'rgb(23 168 224)',
  },

];


export default class Primero extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="Primero-wrapper">
      <MetaTags>
        <title>Primero - Driving Projects Forward - OKMG</title>
        <meta name="description" content="Primero - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/primero'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Primero" role="heading"  
        sub_content="Digital | Creative"
        service_info="After a period of rapid global expansion, resulting directly from their consistent high quality outcomes on all projects, Primero required a digital presence to accurately reflect their new international positioning."
        // image={cdn('/Case_studies/Primero/primero-header-v2.png')}
        video={cdn('/websiteScrolls/primero-screenshot-min-min.mp4')}
      //  hover_video_one={cdn('/websiteScrolls/primero-screenshot.mp4')}
        // hover_video_one={cdn('/Case_studies/Primero/Screenshot+Cycle+1.mp4')}
        foot_image={cdn('/Case_studies/Primero/Primero_icon.svg')}
        image_width="110%"
        className="special"
        image_class="header-image"
        foot_image_width="150px"
        foot_image_left="0px"

        />
         <ComponentCaseCopy
         marginTop="0"
        content={
          <div>
            <p>Primero Group is a vertically integrated engineering group that specialise in the design, construction and operation of global resource projects. </p>
            <p>In an increasingly competitive landscape, Primero has met the challenges of global expansion head on, continuing to win and deliver on a variety of minerals, energy & non-infrastructure projects. With multiple new opportunities and tender submissions on the horizon, a digital platform was needed to allow Primero to compete on an international level.</p>
            <p>OKMG were approached to produce a new custom website and brand collateral to reflect the quality and scale of Primero’s service. Our team designed and then built out an interactive website design that truly reflected the magnitude and sophistication of Primero’s operations.</p>
            <p>From content population, through to ASX listing integration, the Primero website was created to inspire, whilst ensuring all key Investor and Project information was front and centre, providing the company a strong platform to elevate their brand further.</p>
          </div>}
        />

       {/* <ComponentVideo
          video_url={cdn('/Case_studies/Primero/THE-COVE-COMPLETION-VIDEO-V4-V4-1-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
          
         
        */}
        <ComponentFullBleedImage
          image={cdn('/Case_studies/Primero/PRIMERO_CAP STAT_MOCKUP-2-min.jpg')}
          width="100%"
          marginTop="0px"
          left="100px"
          marginTop="100px"
          />
        <ComponentVideo
          video_url={cdn('/Case_studies/Primero/primero-video-screen-cast.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          className="special-video-primero"
          width="80%"
          screenCast
          />

        
        <ComponentCaseStats
            stats={stat_info}
            has_image='true'
            marginTop="150px"
          />
         
        <ComponentVideo
          video_url={cdn('/websiteScrolls/Primero-rz.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="180px"
          screenCast
          width="80%"
          />
          <ComponentFullBleedImage
          image={cdn('/Case_studies/Primero/PRIMERO_DISPLAY_MOCKUP.png')}
          width="100%"
          marginTop="0px"
          marginTop="200px"
          />

          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}