/*
  Goldfields.jsx
  @Component - Goldfields Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './Goldfields.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';
import Reveal from 'react-reveal/Fade';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';


// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/Goldfields/oasis-box1.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Goldfields/oasis-box2.png'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/Goldfields/oasis-box3.png'),
    background_size: 'cover',
  }

]

const images_gifs = [
  {
   id: 1,
   image: cdn('/Case_studies/RFDS/rec-1-gifs.gif'),
   width:'30%',
 },
 {
   id: 2,
   image: cdn('/Case_studies/RFDS/rec-2-gifs.gif'),
   width:'30%',
 }

]

export default class ScotchCollege extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="Goldfields-wrapper twin-fin-wrapper">
      <MetaTags>
        <title>Goldfields Oasis - OKMG</title>
        <meta name="description" content="The Royal Flying Doctor Service has dedicated itself to providing primary health care and emergency services to those that live, work and travel across the 7.69 million square kilometres of Australia." />
        <link rel='canonical' href='https://okmg.com/work/goldfields-oasis'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Goldfields Oasis" role="heading"
        sub_content="Creative | Digital"
        service_info="The Royal Flying Doctor Service has dedicated itself to providing primary health care and emergency services to those that live, work and travel across the 7.69 million square kilometres of Australia. OKMG was engaged to create a campaign to reinforce how the partnership between RFDS and Rio Tinto elevates health outcomes for people living in regional WA."
        image={cdn('/Case_studies/Goldfields/oasis-header-img.png')}
        image_class="header-image"
        image_width="100%"

        foot_image={cdn('/Case_studies/Goldfields/oasis-logo.png')}
        />
        <Reveal up>
        <ComponentCaseCopy
        content={
          <div>
            <p>Named Australia's 'Most Trusted Charity', the Royal Flying Doctor Service ensures that everyone in WA has access to health care and emergency, life-saving treatment. The RFDS wanted to incorporate personable imagery focusing on authentic human stories within this campaign, accompanied by their existing campaign tagline 'When Every Moment Counts'. </p>
            <p>When approaching this campaign, we wanted to explore the vastness of WA's beautiful but expansive state and how that can be terrifying in a moment of need. Our insight; In an emergency, time can be the greatest enemy. We wanted to flip the feeling of isolation and panic felt by those in remote areas during a medical emergency to a feeling of reassurance - safe in the knowledge that with the RFDS, help is never far away. </p>
            <p>We applied the RFDS's existing 'When Every Moment Counts' tagline, leveraging this creative to apply to the almost fatal circumstances faced by the Padfield family. The Padfield family were momentarily confronted with the potential of driving from Tom Price, 1452km away from Perth, roughly 15 hours by car, to get their daughter Lucy the medical assistance she needed. However, when the Padfield's heard that the RFDS were on their way, they felt instant relief. We reinforced the 'racing the clock' and distance through an odometer applied, ticking down the kilometres, across the creative, accompanied by the following messaging, 'For those who are far away, help never is'. Reinforcing that the Royal Flying Doctor Service will provide the medical assistance you and your loved ones need no matter how far away you are.</p>
            <p>This campaign was launched across several media platforms, including retail furniture, street furniture and digital billboards. In addition to this campaign creative, we produced a video emphasising the significance of their partnership with Rio Tinto. The video highlighted the advanced technology and medical equipment that the Royal Flying Doctor Service utilises every day to keep Australians living far and wide happy and healthy.</p>
          </div>}
        />
        </Reveal>
        
          <ComponentFullBleedImage
          image={cdn('/Case_studies/Goldfields/oasis-palette.png')}
          marginTop="100px"
          width="100%"
          className="palette"
          />

          <Reveal fade>
          <div className="video-with-blobs">
            <img class="red-blob" src={cdn('/Case_studies/Goldfields/red-blob.png')}  alt="red blob image" />
            <img class="teal-blob" src={cdn('/Case_studies/Goldfields/teal-blob.png')}  alt="teal blob image" />
            <ComponentVideo
            video_url={cdn('/Case_studies/Goldfields/webscroll-rz.mp4')}
            autoplay="autoplay"
            loop="loop"
            marginTop="150px"
            />
          </div>
          </Reveal>

          <ComponentFullBleedImage
          image={cdn('/Case_studies/Goldfields/oasis-cards.png')}
          marginTop="100px"
          width="100%"
          className="cards"
          special_image={cdn('/Case_studies/Goldfields/blue-blob.png')}
          special_class_wrapper="special-card-img"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/Goldfields/oasis-box-big.png')}
          marginTop="100px"
          width="100%"
          className="big-box"
          />
          
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />

          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}