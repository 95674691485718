/*
  CicerellosSeaCritters.jsx
  @Component - Cicerellos Sea Critters Case Study
*/

import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './CicerellosSeaCritters.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';
import Lottie from 'react-lottie';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';


const _anim = {};
function fetchAnimation(url) {
  return _anim[url] = _anim[url] || fetch(url).then(res => res.json());
}
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]
// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/CicerellosSeaCritters/cicerellos-sea-critters-image-small-one.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/CicerellosSeaCritters/cicerellos-sea-critters-small-image-two.jpg'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/CicerellosSeaCritters/cicerellos-sea-critters-small-image-three.jpg'),
    background_size: 'cover',
  }

]

const images_critters = [
   {
    id: 1,
    image: cdn('/Case_studies/CicerellosSeaCritters/sea-critters-blue-fish.jpg'),
    background_size: 'contain',
    background_repeat:'no-repeat',
    background_position:'bottom left',
    width:'33.33%',
    className:'sp-image-one'
  },
  {
    id: 2,
    image: cdn('/Case_studies/CicerellosSeaCritters/sea-critters-crab.jpg'),
    background_size: 'contain',
    background_repeat:'no-repeat',
    width:'33.33%',
    background_position:'bottom',
    className:'sp-image-two'
  },
  {
    id: 3,
    image: cdn('/Case_studies/CicerellosSeaCritters/sea-critters-yellow-fish.jpg'),
    background_size: 'contain',
    background_repeat:'no-repeat',
    width:'33.33%',
    background_position:'bottom',
    className:'sp-image-two'
  }

]

const images_gifs = [
   {
    id: 1,
    image: cdn('/Case_studies/CicerellosSeaCritters/image_1%402x.png'),
    width:'20%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/CicerellosSeaCritters/Swell Times IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/CicerellosSeaCritters/Cicerellos Sea Critters_INSTA%402x.png'),
    width:'30%',
  },
  {
    id: 4,
    image: cdn('/Case_studies/CicerellosSeaCritters/Open Now IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 6,
    image: cdn('/Case_studies/CicerellosSeaCritters/image_1%402x.png'),
    width:'20%',
  },


]


export default class CicerellosSeaCritters extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      animatonCrab: null,
      animationNemoFish: null,
      animationDoryFish: null
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

     fetchAnimation('/assets/animations/Crab.json').then(data => {
      this.setState({ animationCrab: data });
    });
     fetchAnimation('/assets/animations/NemoFish.json').then(data => {
      this.setState({ animationNemoFish: data });
    });
     fetchAnimation('/assets/animations/DoryFish.json').then(data => {
      this.setState({ animationDoryFish: data });
    });

  }
  render(){

    const defaultCrab = {
      loop: true,
      animationData: this.state.animationCrab,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    const defaultNemoFish = {
      loop: true,
      animationData: this.state.animationNemoFish,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    const defaultDoryFish = {
      loop: true,
      animationData: this.state.animationDoryFish,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="cicerellos-sea-critters-wrapper">
      <MetaTags>
        <title>Cicerello's Sea Critters - OKMG</title>
        <meta name="description" content="Cicerellos Sea Critters - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/CicerellosSeaCritters'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Cicerello's - Sea Critters" role="heading"
        sub_content="Strategy | Digital | Creative | Marketing"
        service_info="Cicerello’s is not only the home of fish’n’chips but also home to over 150 different sea creatures residing within their 11 world-class aquariums. OKMG was engaged to promote these aquariums to families with little ones interested in learning about creatures of the deep."
        image={cdn('/Case_studies/CicerellosSeaCritters/sea-critters-header-image.jpg')}
        image_class="header-image"
        image_width="90%"
        foot_image={cdn('/Case_studies/CicerellosSeaCritters/sea-critters-crumb.jpg')}
        foot_image_width="250px"
        foot_image_left="0"
        />
        
        <ComponentCaseCopy
        content={
          <div>
            <p>Stepping into Cicerello’s, it’s hard to ignore the vibrant aquariums throughout the venue. These tanks are curated to replicate realistic marine environments in which these creatures come from, cared for by the on-site Marine Team. The Marine Team also runs Aquarium tours, educating over 14,000 local students and visitors on the creatures’ unique behaviours.</p>
            <p>OKMG were engaged to promote the Aquarium tours, during the school holidays, positioning Cicerello’s as a key interactive family experience - specifically in the winter months. One of the key challenges in promoting these Aquarium tours was showing the true value of the aquariums so people felt it was worth staying at Cicerello’s a little longer for. </p>
            <p>We developed Sea Critters, a sub-brand under Cicerello’s, as a way to deliver content that was both educational and entertaining. Drawing inspiration from the diverse marine life within the aquariums, ensuring to focus on creatures that children would gravitate towards specifically easily recognisable like “Nemo’s” and “Dory’s”. We created a brand that would be engaging and captivating for families with younger children, delivering information that was age appropriate without talking down to our audience. We worked to highlight the interactive and educational experience, creating greater awareness and appreciation of our unique and beautiful marine life for little ones.</p>
            <p>We brought the Sea Critters brand to life by launching the content across the Cicerello's social media accounts and in-store signage which featured QR codes. They were then taken directly to the tour booking page, customised with the Sea critter’s branding for an integrated experience. The result; sell-out times for all tours during the school holiday season as well as a feature on the Sunrise TV show!</p>
          </div>}
        />

         
        <ComponentFullBleedImage
          image={cdn('/Case_studies/CicerellosSeaCritters/sea-critters-logo.jpg')}
          marginTop="100px"
          width="400px"
          />

          <div className="animated-animals">
          <Lottie options={defaultNemoFish}
              height={'100%'}
              width={'100%'}
              className="blob-homer"
              
              />
          <Lottie options={defaultCrab}
              height={'100%'}
              width={'100%'}
              className="blob-homer"
              
              />
              <Lottie options={defaultDoryFish}
              height={'100%'}
              width={'100%'}
              className="blob-homer"
              
              />
          </div>

          <ComponentVideo
          video_url={cdn('/Case_studies/CicerellosSeaCritters/cicerellos-sea-critters-screencast.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          screenCast
          />

          {/* <Reveal effect="fadeInUp"> */}
          <div className="stat-wrapper component-case-images-gifs-wrapper">
              <img class="img-card img-left side-gif" src={cdn('/Case_studies/CicerellosSeaCritters/210526_CICERELLOS_SCC SOCIALS_MARINE MONDAY-1-v3.png')} alt=""/>
            <div className="insta-wrap">
              <img class="insta-svg" src={cdn('/Case_studies/CicerellosSeaCritters/Cicerellos_Social 1.png')} alt=""/>
              <video class="insta-vid" autoplay="autoplay" loop muted playsinline>
                <source src={cdn('/Case_studies/CicerellosSeaCritters/CICERELLOS_SEA CRITTERS_15 sec_FISH_EDIT V1-V3.mp4')} type="video/mp4"/>
              </video>
            </div>
              <img class="img-card img-right side-gif" src={cdn('/Case_studies/CicerellosSeaCritters/210526_CICERELLOS_SCC SOCIALS_FISH FACT FRIDAY-1-v2.png')} alt=""/>
          </div>
          {/* </Reveal> */}
           
 
          <ComponentFullBleedImage
          image={cdn('/Case_studies/CicerellosSeaCritters/cicerellos-sea-critters-flyer.jpg')}
          marginTop="100px"
          width="90%"
          />
          <ComponentFullBleedImage
          image={cdn('/Case_studies/CicerellosSeaCritters/cicerellos-sea-critters-main-image.jpg')}
          marginTop="100px"
          width="100%"
          />
          
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}