/*
  StudyPerth.jsx
  @Component - StudyPerth Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './StudyPerth.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/StudyPerth/square-one.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/StudyPerth/squre-two-min.png'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/StudyPerth/square-three.jpg'),
    background_size: 'cover',
  }

]


const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/StudyPerth/study-perth-stats.png'),
    width:'calc(50% - 30px)',
  },
  {
    id: 2,
    image: cdn('/Case_studies/StudyPerth/study-perth-info-v2.png'),
    width:'calc(50% - 30px)',
    borderRadius: '32px',
    className:'special'
  },
];


export default class StudyPerth extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="StudyPerth-wrapper">
      <MetaTags>
        <title>StudyPerth - Your Guide to Education in Perth - OKMG</title>
        <meta name="description" content="StudyPerth - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/StudyPerth'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="StudyPerth" role="heading"  
        sub_content="Strategy | Creative"
        service_info="StudyPerth is a not-for-profit organisation promoting Perth as a study destination for international students. OKMG built a digital library of video and photo assets from around Perth and Western Australia to inspire future international students."
        hover_image_two={cdn('/Case_studies/StudyPerth/2018.08.05 STUDY PERTH DERBY-48-web.jpg')}
        hover_image_one={cdn('/Case_studies/StudyPerth/perth-skyline.jpg')}
        hover_image_three={cdn('/Case_studies/StudyPerth/2018.11.15 - STUDY PERTH - ROTTNEST-61-web.jpg')}
        foot_image={cdn('/Case_studies/StudyPerth/study-perth-logo.svg')}
        className="special"
        image_class="header-image"
        foot_image_width="250px"
        foot_image_left="0px"

        />
         <ComponentCaseCopy
        content={
          <div>
            <p>StudyPerth is the first point of contact for a majority of international students looking to make the move to Perth. With such a diverse audience to consider, the complexity of each region having its own culture played a huge part in the planning of this project.</p>
            <p>The visual content had to be engaging and dynamic while also flexible enough to be used for a variety of end products, for a global market.</p>
            <p>OKMG was engaged to curate content promoting StudyPerth including, casting, scouting locations, production and delivery of the content. 10 international students were selected as brand ambassadors, ensuring each student from a different international region. Locations were chosen to reflect a genuine Perth student’s lifestyle, focusing on both Perth’s universities or TAFE campuses, as well as life off-campus.</p>
            <p>This vast library of royalty-free content is stored on HIVO, available for future use across the StudyPerth membership network; including all major universities, bridging and training institutions around Perth.</p>
          </div>}
        />

       {/* <ComponentVideo
          video_url={cdn('/Case_studies/StudyPerth/THE-COVE-COMPLETION-VIDEO-V4-V4-1-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
          
         
        */}
         <ComponentVideo
          video_url={cdn('/Case_studies/StudyPerth/Study Perth WA Expo V1-web.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
        <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="100px"
          width="70%"
          />
     
          <ComponentFullBleedImage
          image={cdn('/Case_studies/StudyPerth/Group 3130.png')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/StudyPerth/2018.11.01 - STUDY PERTH - NOTRE DAME FREO-98-web.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />
  
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}