/*
  Fast800.jsx
  @Component - Busselton Swim Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import BlobOne from '../../blobOne';
import './Fast800.scss';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/curtinGoGreater/go-greater-one.jpg'),
    background_size: 'cover',
  },
   {
    id: 2,
    image: cdn('/Case_studies/curtinGoGreater/go-greater-three.jpg'),
    background_size: 'cover',
  },
   {
    id: 3,
    image: cdn('/Case_studies/curtinGoGreater/go-greater-two.jpg'),
    background_size: 'cover',
  }


]


export default class Fast800 extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="fast-800">
      <MetaTags>
        <title>Fast 800 - Product Shop Videos - OKMG</title>
        <meta name="description" content="Lose weight and regain your health with Dr Michael Mosley's The Fast 800, based on the latest science in nutrition and intermittent fasting." />
        <link rel='canonical' href='https://okmg.com/work/fast-800-product-shop-videos'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Fast 800 Product Shop Videos" role="heading"  
        sub_content="Creative"
        service_info="Lose weight and regain your health with Dr Michael Mosley's The Fast 800, based on the latest science in nutrition and intermittent fasting. "
        image_width="70%"
        image={cdn('/Case_studies/Fast800/800-header-banner.jpg')}
        image_class="header-image"
        foot_image={cdn('/Case_studies/Fast800/800-sub-header-banner.jpg')}
        foot_image_width="160px"
        foot_image_left="0"
        />
         <ComponentCaseCopy
        content={
          <div>
           <p>The Fast 800, required a video asset to help them build brand awareness, drive traffic and increase conversion for the products in The Fast 800 product shop, in Australia, New Zealand and the UK.</p>

<p>The video was to be bright and colourful, highly saturated and fast paced. The client wanted to introduce the brand in the beginning video, moving quickly into the benefits of shakes and highlight the key selling points. The production and delivery was an extremely tight turn around to be launched in time to integrate with their end of year campaigns.</p>

<p>Our intensive pre production phase covered creative concepting, script development, storyboarding, Voice Over (VO) sourcing and studio hire. We then enabled a full day production in studio, with a high-frame-rate camera capturing slow-motion footage of fruit splashing into shakes and high resolution styled product images. We also recorded two voice-overs, a British and Australian accent for the two different target markets.</p>

<p>Our in-house video editing and animation team managed the post production and delivery of 18 assets across the website and YouTube, in varying formats with alternating VOs.</p>
          </div>}
        />

       <ComponentVideo
          video_url={cdn('/Case_studies/Fast800/800-video.mp4')}
          marginTop="100px"
          marginBottom="100px"
          loop="loop"
          />

          <div className="images-with-video">
            <div className="ok-col">
              <img src={cdn('/Case_studies/Fast800/800-200-calories.jpg')} />
            </div>
            <div className="ok-col">
            <video autoplay="autoplay" muted="muted" loop="loop">
              <source src={cdn('/Case_studies/Fast800/800-video-square.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            </div>
            <div className="ok-col">
              <img src={cdn('/Case_studies/Fast800/800-low-carb.jpg')} />
            </div>

          </div>
       

 

         <ComponentFullBleedImage
          image={cdn('/Case_studies/Fast800/800-bottom-full-bleed.png')}
          width="100%"
          marginTop="60px"
          className="full-bleed-three"
          />
  
           <ComponentFullBleedImage
          image={cdn('/Case_studies/Fast800/800-footer-banner.jpg')}
          width="100%"
          marginTop="-53px"
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}