/*
  ComponentHeader.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentHeader.scss';
import HamburgerMenu from 'react-hamburger-menu';
import BlobOne from './../blobOne';
// images
import cdn from '../../lib/cdn';
// images
const facebook = "https://okmg-digital-assets.s3-ap-southeast-2.amazonaws.com/okmg/facebook.svg";
const instagram = "https://okmg-digital-assets.s3-ap-southeast-2.amazonaws.com/okmg/instagram.svg";
const youtube = "https://okmg-digital-assets.s3-ap-southeast-2.amazonaws.com/okmg/youtube.svg";
const linkedin = "https://okmg-digital-assets.s3-ap-southeast-2.amazonaws.com/okmg/linkedin.svg";

const okmg = "https://okmg-digital-assets.s3-ap-southeast-2.amazonaws.com/okmg/OKMG_MASTER+LOGO.png";
const CloseMenu = 'hello';
const OpenMenu = 'hello';



export default class ComponentHeader extends React.Component {


    constructor(props) {
    super(props);
    // State.

    this.state = {
    
      active_one: false,
      active_two: false,
      active_three: false,
      active_four: false,
      active_five: false,
      active_six: false,
      active_seven:false,
      hamburger: '',
      open: '',
      menu_mobile: '',
      mobile_off: '',
      modal: '',
      desktop_burger: '',
      cursor: '',
      left: 0,
      top: 0,
      blob_active: ""


    };
  }



   componentDidMount() {
    window.scrollTo(0, 0);
   
  }
   componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }


  activateModal(){
    this.setState({
      modal: !this.state.modal,
    })
  }

  activateLink_one(){
    this.setState({ 
      active_one: 'active',
      active_two: false,
      active_three: false, 
      active_four: false, 
      active_five: false, 
      active_six: false,
      active_seven:false,
      open: !this.state.open,
      menu_mobile: !this.state.menu_mobile 

    });

  }
  activateLink_two(){
     this.setState({ 
      active_one: false,
      active_two: true,
      active_three: false, 
      active_four: false, 
      active_five: false, 
      active_six: false,
      active_seven:false,
      open: !this.state.open,
      menu_mobile: !this.state.menu_mobile 

    });
  }
   activateLink_three(){
     this.setState({ 
      active_one: false,
      active_two: false,
      active_three: true, 
      active_four: false, 
      active_five: false, 
      active_six: false,
      active_seven:false,
      open: !this.state.open,
      menu_mobile: !this.state.menu_mobile 

    });
  }

    activateLink_four(){
     this.setState({ 
      active_one: false,
      active_two: false,
      active_three: false, 
      active_four: true, 
      active_five: false, 
      active_six: false,
      active_seven:false,
      open: !this.state.open,
      menu_mobile: !this.state.menu_mobile 

    });
  }

    activateLink_five(){
     this.setState({ 
      active_one: false,
      active_two: false,
      active_three: false, 
      active_four: false, 
      active_five: true, 
      active_six: false,
      active_seven:false,
      open: !this.state.open,
      menu_mobile: !this.state.menu_mobile 


    });
  }

    activateLink_six(){
     this.setState({ 
      active_one: false,
      active_two: false,
      active_three: false, 
      active_four: false, 
      active_five: false, 
      active_six: true, 
      active_seven:false,
      open: !this.state.open,
      menu_mobile: !this.state.menu_mobile

    });
  }

   activateLink_seven(){
     this.setState({ 
      active_one: false,
      active_two: false,
      active_three: false, 
      active_four: false, 
      active_five: false, 
      active_six: false, 
      active_seven:true,
      open: !this.state.open,
      menu_mobile: !this.state.menu_mobile

    });
  }
burgerClick() {

 
  this.setState({
      hamburger: !this.state.hamburger,
  })
  
}
pageChange(){
  this.setState({
    hamburger: !this.state.hamburger,
    desktop_burger: !this.state.desktop_burger,

  })
}

burgerClickDesktop(){
  this.setState({
    desktop_burger: !this.state.desktop_burger,
  })
 
}
handleClick() {
  this.setState({
    open: !this.state.open,
    menu_mobile: !this.state.menu_mobile
  });

}
updateBlobIn(){
  this.setState({
    blob_active: !this.state.blob_active
  }) 
}
updateBlobOut(){
  this.setState({
    blob_active: !this.state.blob_active
  }) 
}
componentDidunMount(){
  this.setState({
    desktop_burger: ""
  }) 
}

componentDidMount(){
   window.addEventListener('scroll', () => {
         let activeClass = 'scroll-active';
         if(window.scrollY === 0){
             activeClass = 'scroll-not-active';
         }
         this.setState({ activeClass });
      });
}
 

  render(){

   // const {
      
   // } = this.props;



  return (
    <div className="component-header">
   
        <div className="navigation-menu">
          <div className={`desktop ${this.state.activeClass}`} id={this.state.hamburger ? 'mobile-active' : 'mobile-closed'}>
            <div className="row logo-wrap">
              <Link to="/">
                <img src={cdn('okmg-master.svg')} alt="OKMG" style={{width:'70px',height:'16px'}}/>
              </Link>
            </div>
            <div className="nav-wrap hamburger">
              <button aria-label="Mobile Menu" className={this.state.hamburger ? 'hamburger hamburger--collapse is-active' : 'hamburger hamburger--collapse'} type="button" onClick={this.burgerClick.bind(this)}>
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
            <div className={this.state.desktop_burger ? "desktop-drop-menu is-active" : "desktop-drop-menu"} id="desktop-drop">
              <div className="desktop-drop-col-1">
                <ul className="desktop-drop-left">
                  <li>
                   <a 
                    href="https://okmg.com/work"
                    >
                      Work
                    </a>
                  </li>
                  <li>
                    <a
                    href="https://okmg.com/services"
                    >
                      Services
                    </a>
                  </li>
                  {/*<li>
                    <Link 
                    to="/our-agency"
                    onClick={this.pageChange.bind(this)}
                    onMouseEnter={this.updateBlobIn.bind(this)}
                    onMouseLeave={this.updateBlobOut.bind(this)}
                    >
                      Agency
                    </Link>
                  </li>*/}
                  <li>
                    <a
                    href="https://okmg.com/blog"
                    >
                      Knowledge
                    </a>
                  </li>
                  <li>
                   
                    <a 
                    href="https://okmg.com/contact"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
                </div>
                <div className="desktop-drop-col-2">
                  <ul className="desktop-drop-right">
                    <li><a href="https://okmg.com/terms-conditions">Terms & Conditions</a></li>
                    <li><a href="https://okmg.com/privacy-policy">Privacy</a></li>
                    <li><a href="https://okmg.com/disclaimer">Disclaimer</a></li>
                    <li><a href="https://okmg.com/payments">Payments</a></li>
                    <li><a href="https://okmg.com/careers">Careers</a></li>
                    <li><a href="mailto:info@okmg.com" className="special-contact">info@okmg.com</a></li>
                    <li><a href="tel:1300936564" className="special-contact-second">1300 93 6564</a></li>
                  </ul>
                </div>
                 <div className="desktop-drop-col-3">
                 <img src={okmg} alt="okmg" className="backdrop"/>
                  <ul className="desktop-drop-right">
                    {/*<li><Link to="/our-agency" className="team-work" onClick={this.pageChange.bind(this)} onMouseEnter={this.updateBlobIn.bind(this)} onMouseLeave={this.updateBlobOut.bind(this)}>Teamwork makes the dream work.</Link></li>*/}
                    <li><a href="https://www.facebook.com/OKMGAustralia" target="_blank" rel="noopener">Facebook</a></li>
                    <li><a href="https://www.instagram.com/okmg.agency/?hl=en" target="_blank" rel="noopener">Instagram</a></li>
                    <li><a href="https://www.youtube.com/channel/UC8CFbRXOKKu37F25p7aKhRA?feature=emb_subscribe_title" target="_blank" rel="noopener">Youtube</a></li>
                    <li><a href="https://www.linkedin.com/company/okmg/?originalSubdomain=au" target="_blank" rel="noopener">Linkedin</a></li>
                  </ul>

                </div>

            </div>
            <div className={this.state.desktop_burger ? "nav-wrap is-active" : "nav-wrap"} id="desktop-burger">
            <div className="nav-wrap-desktop hamburger">
              <button aria-label="Special Menu" className={this.state.desktop_burger ? 'hamburger hamburger--collapse is-active' : 'hamburger hamburger--collapse'} type="button" onClick={this.burgerClickDesktop.bind(this)} id="desktop-spin">
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
            
              <a 
              href="https://okmg.com/contact"
              
              >
                Contact
              </a>
              <a 
              href="https://okmg.com/blog"
              >
                Knowledge
              </a>
              {/*<Link 
              to="/our-agency"
              >
                Agency
              </Link>*/}
              <a 
              href="https://okmg.com/services"
              >
                Services
              </a>
              <a 
              href="https://okmg.com/work"
              >
                Work
              </a>

             
            </div>
          </div>
          <div className="mobile-pop" className={this.state.hamburger ? 'mobile-pop is-active' : 'mobile-pop'}>

            <div className="page-links-wrapper">
             <a
                href="https://okmg.com/work"
                >
                  Work
                </a>
                 <a 
                href="https://okmg.com/services"
                >
                  Services
                </a>
                {/*<Link 
                to="/our-agency"
                onClick={this.pageChange.bind(this)}
                >
                  Agency
                </Link>*/}
                <a 
                href="https://okmg.com/blog"
                >
                  Knowledge
                </a>
              <a 
                href="https://okmg.com/contact"
                >
                  Contact
                </a>
                
                
               
               
                 <a 
              href="https://okmg.com/terms-conditions" className="mini-link one"
              >
                Terms & Conditions
              </a>
               <a 
              href="https://okmg.com/privacy-policy" className="mini-link"
              >
               Privacy
              </a>
               <a
              href="https://okmg.com/disclaimer" className="mini-link"
              >
                Disclaimer
              </a>
               <a 
              href="https://okmg.com/payments" className="mini-link"
              >
                Payments
              </a>
              <a 
              href="https://okmg.com/careers" className="mini-link"
              >
                Careers
              </a>
              <a href="mailto:info@okmg.com" className="mob-contact one">
                info@okmg.com
              </a>
              <a href="tel:1300936564" className="mob-contact">
                1300 93 6564
              </a>
            </div>
            <div className="socials-wrapper-mob">
              <ul>
                <li>
                  <a href="https://www.facebook.com/OKMGAustralia" target="_blank" rel="noopener">
                    Facebook
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/okmg.agency/?hl=en" target="_blank" rel="noopener">
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UC8CFbRXOKKu37F25p7aKhRA?feature=emb_subscribe_title" target="_blank" rel="noopener">
                  Youtube
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/okmg/?originalSubdomain=au" target="_blank" rel="noopener">
                  Linkedin
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
     </div>
    );
  }
}