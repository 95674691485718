/*
  ComponentFullBleedToglgeImage.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentToggleImage.scss';
// images


export default class ComponentToggleImage extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      toggle:''
   
    };
  }

  handleClick(){
    this.setState({ toggle: !this.state.toggle });
  }

  render(){

   const {
    image_one,
    image_two,
    marginTop,
    marginBottom,
    backgroundSize,
    backgroundRepeat,
    width,
    left
      } = this.props;

  return (
      <div className="component-full-bleed-image" style={{marginTop:marginTop, marginBottom:marginBottom, backgroundSize: backgroundSize,backgroundRepeat:backgroundRepeat}}>
        
        <label className="switch">
          <span className="toggle-instruct">Toggle Me</span>
          <input type="checkbox" onClick={this.handleClick.bind(this)}/>
          <span className="slider round"><span>{this.state.toggle ? 'Trade Website' : 'Retail Website' }</span></span>
        </label>
        <img src={image_one} style={{width:width,left:left}} className={this.state.toggle ? 'toggle-no ' : 'toggle-yes' }/>
        <img src={image_two} style={{width:width,left:left}} className={this.state.toggle ? 'toggle-yes ' : 'toggle-no' }/>
      </div>
    );
  }
}