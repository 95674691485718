
import React, { Component } from 'react';  
import './ComponentParticles.scss';

class Particles extends React.Component {

  constructor(props) {
    super(props);

    this.width = 512;
    this.height = 512;
    this.count = 100;
    this.ref = React.createRef();

    // Particle properties.
    this.col = new Float32Array(this.count * 3);
    this.xy = new Float32Array(this.count * 2);
    this.vxy = new Float32Array(this.count * 2);
    this.xyOld = new Float32Array(this.count * 2);

    // Other properties to speed up access.
    this.g = new Float32Array(this.count);
    this.neighbours = new Uint32Array(this.count);
    this.len = new Float32Array(this.count);
  }

  componentDidMount() {

    // Init WebGL.
    const canvasEl = this.ref.current;
    if (!canvasEl) {
      return;
    }
    const gl = canvasEl.getContext('webgl', { depth: false, alpha: true });
    if (gl === null) {
      console.log('Unable to initialize WebGL.');
      return;
    }
    this._gl = gl;

    // Clear canvas.
    gl.disable(gl.DEPTH_TEST);  // Don't need this, we're not in 3D.
    gl.enable(gl.BLEND);
    gl.blendEquation(gl.FUNC_ADD);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    // Get shader code.

    const PASS1_VERT = `#ifdef GL_ES
precision mediump float;
#endif

uniform vec2 u_size;
attribute vec2 a_pos;
attribute vec3 a_col;

varying vec3 color;

void main() {
  gl_PointSize = 100.0;
  gl_Position = vec4(((a_pos / u_size) - 0.5) * vec2(2, -2), 0.0, 1.0);
  color = a_col;
}`;

    const PASS1_FRAG = `#ifdef GL_ES
precision mediump float;
#endif

varying vec3 color;

void main() {

  vec2 point = gl_PointCoord.xy - 0.5;
  float dist = length(point);

  // Fast square.
  if (dist > 0.5) {
    discard;
  }

  gl_FragColor = vec4(color, 1.0 - (dist * 2.0));
}`;

    const PASS2_VERT = `#ifdef GL_ES
precision mediump float;
#endif

attribute vec2 quad;

void main() {
  gl_Position = vec4(quad, 0, 1.0);
}`;

    const color1 = 'E62E2E'.match(/.{2}/g).map(a => parseInt(a, 16) / 255);
    const color2 = 'EBBD1D'.match(/.{2}/g).map(a => parseInt(a, 16) / 255);

    const PASS2_FRAG =  `#ifdef GL_ES
precision mediump float;
#endif

uniform sampler2D state;
uniform vec2 size;

void main() {

  vec2 coord = gl_FragCoord.xy / size;
  vec4 color = texture2D(state, coord);

  if (color.a < 0.1) {
    
    gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);

  } else if (color.r > 0.0 || color.g > 0.0) {

    if (color.r > color.g) {
      gl_FragColor = vec4(${color1.join(', ')}, 1.0);
    } else {
      gl_FragColor = vec4(${color2.join(', ')}, 1.0);
    }

  } else {

    gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);

  }

}`;

    // Create shader programs.
    this._pass1Program = this._loadProgram(PASS1_VERT, PASS1_FRAG);
    this._pass1uSizeLocation = gl.getUniformLocation(this._pass1Program, 'u_size');
    this._pass1aPosLocation = gl.getAttribLocation(this._pass1Program, 'a_pos');
    this._pass1aColLocation = gl.getAttribLocation(this._pass1Program, 'a_col');

    this._pass2Program = this._loadProgram(PASS2_VERT, PASS2_FRAG);
    this._pass2uSizeLocation = gl.getUniformLocation(this._pass2Program, 'size');
    this._pass2uStateLocation = gl.getUniformLocation(this._pass2Program, 'state');
    this._pass2aQuadLocation = gl.getAttribLocation(this._pass2Program, 'quad');

    // Create buffers.
    this._quadBuffer = this._createBuffer(new Float32Array([-1, -1, 1, -1, -1, 1, 1, 1]));

    // Create textures.
    this._midTexture = this._createTexture(this.width, this.height, null);

    // Create framebuffers.
    this._midFramebuffer = gl.createFramebuffer();

    // Init particles.
    this.resetParticles();
    this.play();

    // Init listeners.
    this._lastScrollY = window.scrollY;
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.stop();
    document.removeEventListener('scroll', this.handleScroll);
  }

  /**
   * Resets all particle positions.
   */
  resetParticles() {

    const w = this.width;
    const h = this.height;

    for (let i = 0; i < this.count; i += 1) {

      // Set random positions of particle inside container.
      this.xy[i * 2] = Math.random() * w;
      this.xy[(i * 2) + 1] = Math.random() * h;

      // Set color according tso location.
      const color = this.xy[i * 2] > w * 0.5 ? [1.0, 0.0, 0.0] : [0.0, 1.0, 0.0];
      this.col[i * 3] = color[0];
      this.col[(i * 3) + 1] = color[1];
      this.col[(i * 3) + 2] = color[2];

      // Set 0 velocity.
      this.vxy[i * 2] = 0;
      this.vxy[(i * 2) + 1] = 0;
    }
  }

  /**
   * Loads a WebGL shader program
   * @arg {String} vertSource - vertex shader string.
   * @arg {String} fragSource - fragment shader string.
   */
  _loadProgram(vertSource, fragSource) {
    const gl = this._gl;

    // Create vertex shader.
    const vertShader = gl.createShader(gl.VERTEX_SHADER);
    gl.shaderSource(vertShader, vertSource);
    gl.compileShader(vertShader);
    if (!gl.getShaderParameter(vertShader, gl.COMPILE_STATUS)) {
      throw new Error(gl.getShaderInfoLog(vertShader));
    }

    // Create fragment shader.
    const fragShader = gl.createShader(gl.FRAGMENT_SHADER);
    gl.shaderSource(fragShader, fragSource);
    gl.compileShader(fragShader);
    if (!gl.getShaderParameter(fragShader, gl.COMPILE_STATUS)) {
      throw new Error(gl.getShaderInfoLog(fragShader));
    }

    // Create program.
    const program = gl.createProgram();
    gl.attachShader(program, vertShader);
    gl.attachShader(program, fragShader);
    gl.linkProgram(program);
    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
      throw new Error(gl.getProgramInfoLog(program));
    }

    // Cleanup.
    gl.detachShader(program, vertShader);
    gl.detachShader(program, fragShader);
    gl.deleteShader(vertShader);
    gl.deleteShader(fragShader);

    // Return program ID.
    return program;
  }

  /**
   * Loads a WebGL texture.
   * @arg {Number} w - width of texture.
   * @arg {Number} h - height of texture.
   */
  _createTexture(w, h, data) {
    const gl = this._gl;

    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);  // CLAMP_TO_EDGE needed for non-power-of-2 textures.
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, w, h, 0, gl.RGBA, gl.UNSIGNED_BYTE, data);

    return texture;
  }

  /**
   * Creates a WebGL buffer.
   * @arg {TypedArray} data - buffer data.
   */
  _createBuffer(data) {
    const gl = this._gl;

    const buffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
    gl.bufferData(gl.ARRAY_BUFFER, data, gl.STATIC_DRAW);
    return buffer;
  }

  /**
   * Draws the scene.
   */
  draw() {

    const gl = this._gl;
    const width = this.width;
    const height = this.height;
    const count = this.count;

    /*
      PASS 1.
    */

    gl.bindFramebuffer(gl.FRAMEBUFFER, this._midFramebuffer);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this._midTexture, 0);
    gl.viewport(0, 0, width, height);
    gl.useProgram(this._pass1Program);

    // Set uniforms.
    gl.uniform2f(this._pass1uSizeLocation, width, height);

    // Bind vertex data.
    const buffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
    gl.bufferData(gl.ARRAY_BUFFER, this.xy, gl.STATIC_DRAW);
    gl.enableVertexAttribArray(this._pass1aPosLocation);
    gl.vertexAttribPointer(this._pass1aPosLocation, 2, gl.FLOAT, false, 0, 0);

    // Bind color data.
    const colBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, colBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, this.col, gl.STATIC_DRAW);
    gl.enableVertexAttribArray(this._pass1aColLocation);
    gl.vertexAttribPointer(this._pass1aColLocation, 3, gl.FLOAT, false, 0, 0);

    // Clear.
    gl.clearColor(0.0, 0.0, 0.0, 0.0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    // Blend.
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    // Draw points.
    gl.drawArrays(gl.POINTS, 0, count);

    /*
      PASS 2.
    */
    
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.useProgram(this._pass2Program);

    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, this._midTexture);
    gl.viewport(0, 0, width, height);

    // Set uniforms.
    gl.uniform2f(this._pass2uSizeLocation, width, height);
    gl.uniform1i(this._pass2uStateLocation, 0);

    // Bind vertex data.
    gl.bindBuffer(gl.ARRAY_BUFFER, this._quadBuffer);
    gl.enableVertexAttribArray(this._pass2aQuadLocation);
    gl.vertexAttribPointer(this._pass2aQuadLocation, 2, gl.FLOAT, false, 0, 0);

    // Blend.
    gl.disable(gl.BLEND);

    // Draw quad.
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  }

  play() {
    let lastT = null;
    const step = (t) => {
      if (lastT != null) {
        this.update((t - lastT) * 0.001);
        this.draw();
      }
      lastT = t;
      if (this.playing) {
        requestAnimationFrame(step);
      }
    }
    requestAnimationFrame(step);
    this.playing = true;
  }

  stop() {
    this.playing = false;
  }

  /**
   * Updates the scene.
   */
  update(dT) {

    // Check if updating.
    if (!dT) {
      return;
    }

    // Make sure dT isn't too large, or the physics breaks down.
    if (dT > 0.02) {
      dT = 0.02;
    }

    const INTERACTION_RADIUS = 100.0;
    const INTERACTION_RADIUS_SQ = INTERACTION_RADIUS * INTERACTION_RADIUS;
    const STIFFNESS = 10000.0;  // Attraction.
    const STIFFNESS_NEAR = 10000.0;  // Spread.
    const REST_DENSITY = 2.0;  // Attraction when idle.
    const GRAVITY = 1000;
    const RANDOM_MOTION = 20.0;

    const count = this.count;
    const width = this.width;
    const height = this.height;
    const dTSqu = dT * dT;
    const dTInv = 1 / dT;

    // Pass 1.
    for (let i = 0; i < count * 2; i += 1) {

      // Update old positions.
      this.xyOld[i] = this.xy[i];

      // Apply random motion.
      if (RANDOM_MOTION) {
        this.vxy[i] += (Math.random() - 0.5) * RANDOM_MOTION * dT * 2;
      }

      // Apply gravity to Y axis.
      if (GRAVITY && i % 2 > 0) {
        this.vxy[i] += GRAVITY * dT;
      }

      // Update positions.
      this.xy[i] += this.vxy[i] * dT;
    }

    // Pass 2.
    for (let i = 0; i < count; i += 1) {
      const ix = (i * 2);
      const iy = ix + 1;

      // Find close neighbours.
      let density = 0;
      let nearDensity = 0;
      let neighbourCount = 0;
      for (let k = 0; k < count; k += 1) {
        if (k === i)  {
          continue;
        }
        const kx = (k * 2);
        const ky = kx + 1;

        // Get difference.
        const xd = Math.abs(this.xy[ix] - this.xy[kx]);
        const yd = Math.abs(this.xy[iy] - this.xy[ky]);
        if (xd > INTERACTION_RADIUS || yd > INTERACTION_RADIUS) {
          continue;
        }

        // Get square difference.
        const sq = (xd * xd) + (yd * yd);
        if (sq > INTERACTION_RADIUS_SQ) {
          continue;
        }

        // Get ratio.
        // TODO: https://www.h3xed.com/programming/fast-unit-vector-calculation-for-2d-games

        // Get gradient.
        const len = Math.sqrt(sq);
        const g = 1 - (len / INTERACTION_RADIUS);
        if (g === 0 || Number.isNaN(g)) {
          continue;
        }

        // The particle is a neighbour at this point. Add up densities.
        density += g * g;
        nearDensity += g * g * g;
        this.g[k] = g;
        this.len[k] = len;

        // Store index to neighbour.
        this.neighbours[neighbourCount] = k;
        neighbourCount += 1;
      }

      // Get density.
      const p = STIFFNESS * (density - REST_DENSITY);
      const pNear = STIFFNESS_NEAR * nearDensity;

      // Apply relaxation.
      for (let n = 0; n < neighbourCount; n += 1) {
        const k = this.neighbours[n];
        const g = this.g[k];
        const len = this.len[k];
        const kx = k * 2;
        const ky = kx + 1;

        const m = ((p + (pNear * g)) * g * dTSqu) / len;

        const dx = (this.xy[kx] - this.xy[ix]) * m;
        const dy = (this.xy[ky] - this.xy[iy]) * m;

        this.xy[ix] += dx * -0.5;
        this.xy[iy] += dy * -0.5;

        this.xy[kx] += dx * 0.5;
        this.xy[ky] += dy * 0.5;
      }
    }

    // Pass 3.
    for (let i = 0; i < count; i += 1) {
      const ix = i * 2;
      const iy = ix + 1;

      // Constrain the particles to a container.
      const x = this.xy[ix];
      const y = this.xy[iy];
      if (x < 0) {
        this.xy[ix] = 0;
      } else if (x > width) {
        this.xy[ix] = width;
      }
      if (y < 0) {
        this.xy[iy] = 0; 
      } else if (y > height) {
        this.xy[iy] = height;
      }

      // Calculate new velocity.
      this.vxy[ix] = (this.xy[ix] - this.xyOld[ix]) * dTInv;
      this.vxy[iy] = (this.xy[iy] - this.xyOld[iy]) * dTInv;
    }
  }

  applyForceAt(x, y, dx, dy) {
    for (let i = 0; i < this.count; i += 1) {
      const ix = i * 2;
      const iy = ix + 1;

      const MOUSE_RADIUS = 50;

      const xd = x - this.xy[ix];
      const yd = y - this.xy[iy];
      const len = Math.sqrt((xd * xd) + (yd * yd));
      if (len === 0 || len > MOUSE_RADIUS) {
        continue;
      }

      this.vxy[ix] += dx;
      this.vxy[iy] += dy;
    }
  }

  handleMouseMove = (event) => {

    const mouseX = event.clientX;
    const mouseY = event.clientY;

    const canvasEl = this.ref.current;
    if (!canvasEl) {
      return;
    }

    const rect = canvasEl.getBoundingClientRect();

    const x = mouseX - rect.x;
    const y = mouseY - rect.y;
    const dx = event.movementX;
    const dy = event.movementY;

    if (x >= 0 && y >= 0 && x <= rect.width && y <= rect.width) {
      this.applyForceAt(x, y, dx * 10, dy * 10);
    }
  }

  applyForce(dx, dy) {
    for (let i = 0; i < this.count; i += 1) {
      this.vxy[i * 2] += dx;
      this.vxy[(i * 2) + 1] += dy;
    }
  }

  handleScroll = (event) => {

    const dY = window.scrollY - this._lastScrollY;
    this._lastScrollY = window.scrollY;

    this.applyForce(0, dY * -10);
  }

  render() {

    const { style, ...rest } = this.props;

    return (
      <canvas
        ref={this.ref}
        width="512px"
        height="512px"
        onMouseMove={this.handleMouseMove}
        style={{
          borderRadius: '100%',
          backgroundImage:'url("/static/media/okmg-master-logo.c4a6c0e3.png")',
          ...style,
        }}
        {...rest}
      />
    );
  }
}

export default Particles;
