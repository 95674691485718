/*
  Woodside.jsx
  @Component - Woodside Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './Woodside.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/Woodside/square-one-min.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Woodside/square-two.jpeg'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/Woodside/square-three.jpg'),
    background_size: 'cover',
  }

]


const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/Woodside/6developments.svg'),
    width:'calc(50% - 30px)',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Woodside/b-hover-4.jpeg'),
    width:'calc(50% - 30px)',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/Woodside/b-hover-3.jpg'),
    width:'calc(50% - 30px)',
    borderRadius: '32px',
    className:'special'
  },

{
    id: 4,
    image: cdn('/Case_studies/Woodside/50videos.svg'),
    width:'calc(50% - 30px)',
    borderRadius: '32px',
  },



];


export default class Woodside extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="Woodside-wrapper">
      <MetaTags>
        <title>Woodside - Australia’s largest natural gas producer. - OKMG</title>
        <meta name="description" content="Woodside - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/woodside'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Woodside" role="heading"  
        sub_content="Creative "
        service_info="Woodside is Australia’s leading natural gas producer with a global portfolio and has become one of WA’s most significant community advocates. OKMG was engaged to create content to communicate Woodside’s unique culture.  "
        hover_image_two={cdn('Case_studies/Woodside/square-one-min.jpg')}
        hover_image_one={cdn('/Case_studies/Woodside/2018.06.14 KARRATHA JUNE SHOOT-110-web.jpeg')}
        hover_image_three={cdn('/Case_studies/Woodside/20191015 -2019.10.15 REES WITHERS SHIP-11-min.jpg')}
        foot_image={cdn('/Case_studies/Woodside/Woodside-Primary-Vertical-1.png')}
        className="special"
        image_class="header-image"

        foot_image_width="170px"
        foot_image_left="0px"
        />
         <ComponentCaseCopy
         marginTop="100px"
        content={
          <div>
            <p>The social initiatives run by Woodside celebrate the WA community through the support of education, sporting, cultural development and the arts. Each program run by Woodside has a unique purpose in working towards a more united, inclusive and innovative society. </p>
            <p>OKMG has an ongoing working relationship with Woodside to deliver content that is on brand and in-line with their corporate identity. The scope of these projects to date have included: coverage of significant Woodside events, campaign treatment and video creation, animation, corporate photography, project plant documentation, drone imagery, key Woodside partnership coverage and editorial imagery for publication.</p>
            <p>OKMG has worked in collaboration with Juicebox and GPJ, to produce content for a diverse range of campaigns to depict the dedication of the organisation's upholding of core values, sharing the Woodside community through visually stunning storytelling.</p>
          </div>}
        />

       {/* <ComponentVideo
          video_url={cdn('/Case_studies/Woodside/THE-COVE-COMPLETION-VIDEO-V4-V4-1-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
          <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="100px"
          width="70%"
          />
          <ComponentVideo
          video_url={cdn('/Case_studies/Woodside/Enclave-Completion-Video-web-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
        */}
          <ComponentFullBleedImage
          image={cdn('/Case_studies/Woodside/WOODSIDE_STATS.jpg')}
          width="60%"
          marginTop="0px"
          marginTop="100px"
          />
          <ComponentFullBleedImage
          image={cdn('/Case_studies/Woodside/2018.06.14 KARRATHA JUNE SHOOT-110-web.jpeg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />
  
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}