/*
  ComponentFullBleedImage.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentReadMore.scss';
import cdn from '../../../../lib/cdn';
// images


export default class ComponentReadMore extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
      active:'',
      read_more: ''
   
    };
  }

  handleClick(e){
     this.setState({
      active: !this.state.active,
      read_more: !this.state.read_more
    })
  }

  render(){

   const {
      content
      } = this.props;

  return (
      <div className="" className={this.state.active ? 'component-read-more-wrapper active' : 'component-read-more-wrapper'}>
      <div className="read-more-wrapper">
        <button className="read-more" onClick={this.handleClick.bind(this)}>{this.state.read_more ? 'Read Less' : 'Read More'} <img src={cdn('/Case_studies/chevron.svg')} style={{maxWidth:'25px'}} alt="read more"/></button>
      </div>
          <div className="sub-wrapper">
            <div className="sub">
              {content}
            </div>
            
          </div>
      </div>
    );
  }
}