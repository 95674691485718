/*
  SlipSreamCyber.jsx
  @Component - Slip Stream Cyber Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './SlipStreamCyber.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import {Waypoint} from 'react-waypoint';
// import {useState} from 'react';
import Lottie from 'react-lottie';
import cdn from '../../../lib/cdn';
import Reveal from 'react-reveal/Reveal';


// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/SlipStream/jon-flobrant-2842-unsplash-min.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/SlipStream/slip-poster-mock.jpg'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/SlipStream/osman-rana-333226-unsplash-min.jpg'),
    background_size: 'cover',
  }

]
const images2 = [
   {
    id: 1,
    image: cdn('/Case_studies/SlipStream/LINKEDIN MOCKUP - NAVIGATE - 02.png'),
    className:'slip-img'
  },
  {
    id: 2,
    image: cdn('/Case_studies/SlipStream/LINKEDIN MOCKUP - PROTECT - 04.png'),
    background_repeat: 'no-repeat',
    className:'slip-img'
  },
  {
    id: 3,
    image: cdn('/Case_studies/SlipStream/LINKEDIN MOCKUP - RESCUE - 04.png'),
    className:'slip-img'
  }

]

const image_full = [
  {
    id: 1,
    image: cdn('/Case_studies/SlipStream/Slipstream_capstat mockup-min.jpg'),
    className:'slip-img'
  }
]


export default class SlipStreamCyber extends React.Component {


  constructor(props) {
    super(props);

    // State.

    this.state = {
      animationDataSlip: null,
      play: false
    };
  }

  // const [renderLottie, setRenderLottie] = useState(false);

  componentDidMount() {
    window.scrollTo(0, 0);
    fetch('../assets/animations/slipstream.json')
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          animationDataSlip: data
        })
      })
  };
  

  handleWaypointEnter = () => {
    this.setState({
      play: true
    });
  }

  render(){
    
    const {
      prevStudy,
      nextStudy
    } = this.props;
    const {
      animationDataSlip,
      play,
    } = this.state;

    const defaultOptionsSlipstream = {
      loop: false,
      autoplay: false,
      animationData: animationDataSlip,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

  return (
      <div className="slipstream-wrapper">
      <MetaTags>
        <title>Slipstream Cyber  - Managed Cyber Security - OKMG</title>
        <meta name="description" content="Slipstream Syber - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/waac'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Slipstream Cyber" role="heading"  
        sub_content="Strategy | Digital | Marketing | Creative"
        service_info="Slipstream is a managed cyber-security service provider and security product development enterprise. Slipstream approached OKMG to relaunch their sales and marketing strategy with a focus on outbound campaigns and partner sales."
        // image={cdn('/Case_studies/SlipStream/Slipstream_iMac_Mockup.png')}
        image_width="100%"
        hover_video_one={cdn('/Case_studies/SlipStream/slipstream-rz-min.mp4')}
        hover_videobg_one={cdn('/websiteScrolls/iMac_Mockup_v2.png')}
        // main_image_position_right="200px"
        // main_image_position_top="150px"
        />
        <ComponentCaseCopy
        position="relative"
        foot_image={cdn('/Case_studies/SlipStream/Slipstream_icon.svg')}
        foot_image_width="300px"

        content={
          <div>
            <p>Founded in 2017 to address a gap in the market for enterprise grade Security Operations delivered with a flexible customer-centric and humanised service layer, Slipstream has rapidly grown to be both one of Australia’s premier sovereign SOC operators and most active Digital Forensics Investigators.</p>
            <p>OKMG were initially approached by Slipstream to manage a website redesign and accompanying digital marketing campaign based around their Managed Detection and Response services. As the Slipstream team grew, OKMG were tasked with relaunching their sales and marketing strategy to accommodate a larger client base.</p>
            <p>Through a series of client workshops and strategic research, OKMG was able to develop a core brand position and voice for Slipstream, focusing on their pillars to innovate, adapt and empower.</p>
            <p>With the successful launch of this new strategic direction, OKMG are now focused on improving the outbound sales pipeline with a broad range of marketing materials, backed up by a state-of-the-art digital framework and content strategy.</p>
           
          </div>}
        />

      <Waypoint
        onEnter={this.handleWaypointEnter}
      />
      {/* <Waypoint onEnter={()=>setRenderLottie(true)} /> */}
      <Reveal effect="fadeInUp">
        {
          animationDataSlip != null &&
          <Lottie 
            options={defaultOptionsSlipstream}
            isStopped={!play}
            isPaused={false}
            height={'50%'}
            width={'60%'}
            className="blob-homer"
          /> 
        }
        {/* <Lottie options={defaultOptionsSlipstream}
          height={'50%'}
          width={'40%'}
          className="blob-homer"
        /> */}
      </Reveal> 
      
          <ComponentCaseImageGifs
            stats={image_full}
            marginTop="100px"
            special_class_wrapper="slip-img-full-wrap"
          />

          <ComponentCaseImageGifs
            stats={images2}
            marginTop="100px"
            className="slip-imgs"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/SlipStream/MDR_logo.svg')}
          marginTop="0px"
          marginTop="100px"
          className="mdr-logo"
          />

          <ComponentVideo
          video_url={cdn('/Case_studies/SlipStream/slipstream-rz-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          screenCast
          />
            
          <ComponentFullBleedImage
          image={cdn('/Case_studies/SlipStream/Slipstream_trifold-min.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="waac"
          />
     </div>
    );
  }
}