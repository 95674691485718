/*
  Ceri.jsx
  @Component - Ceri Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './Ceri.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/Ceri/ceri_box_img1.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/Ceri/ceri_box_img2.png'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/Ceri/ceri_box_img3.png'),
    background_size: 'cover',
  }

]


export default class ScotchCollege extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="Ceri-wrapper twin-fin-wrapper">
      <MetaTags>
        <title>CERI - OKMG</title>
        <meta name="description" content="CERI is a social enterprise offering programs that are curated to educate and empower early-stage entrepreneurs, PhD students and visionaries within the Western Australian community. OKMG was engaged in designing a website that accurately reflected CERI's progressive and dynamic vision." />
        <link rel='canonical' href='https://okmg.com/work/ceri'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="CERI" role="heading"
        sub_content="Creative"
        service_info="CERI is a social enterprise offering programs that are curated to educate and empower early-stage entrepreneurs, PhD students and visionaries within the Western Australian community. OKMG was engaged in designing a website that accurately reflected CERI's progressive and dynamic vision."
        image={cdn('/Case_studies/Ceri/ceri-mobiles.png')}
        hover_image_one={cdn('/Case_studies/Ceri/moon-orange.png')}
        hover_image_two={cdn('/Case_studies/Ceri/moon-blue.png')}
        image_class="header-image"
        image_width="85%"
        foot_image={cdn('/Case_studies/Ceri/head_logo.png')}
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>CERI's ethos is 'The Future Of Innovation Starts With You', with the mission to empower the next generation of Australia's entrepreneurs through mentorship, workplace residency and industry exposure across an extensive network of experts.</p>
            <p>CERI approached OKMG to evolve their brand identity and website to create deeper engagement with key target audiences and embody the direction of innovation and leaders to develop future-focused ideas.</p>
            <p>We drew inspiration from CERI's existing logomark, based on a sculpture that resides at the CERI headquarters, refining it to align with their progressive brand journey - including a vibrant new colour palette. We applied this throughout the website design, creating a more immersive experience as the logomark leads the user through the different sections on the website.</p>
            <p>CERI's new brand and website directly reflect this innovative organisation's vision, paving the way for Australia's next generation of entrepreneurs.</p>
          </div>}
        />

        <ComponentFullBleedImage
          image={cdn('/Case_studies/Ceri/ceri_brand_mockup.png')}
          marginTop="100px"
          width="80%"
          />
        <ComponentFullBleedImage
          image={cdn('/Case_studies/Ceri/ceri_palette.png')}
          marginTop="100px"
          width="100%"
          />

        <div className="video-with-btn">
          <ComponentVideo
            video_url={cdn('/Case_studies/Ceri/Ceri-webscroll-ceri-web-v3.mp4')}
            autoplay="autoplay"
            loop="loop"
            marginTop="100px"
            controls="true"
            controlsoff
            screenCast
          />
          <img src={cdn('/Case_studies/Ceri/ceri_moon_blue.png')} alt="blue moon" />

          <a href="https://www.ceri.org.au/" style={{fontSize:'16px'}}>Checkout the full website</a>
        </div>

        <ComponentFullBleedImage
        image={cdn('/Case_studies/Ceri/ceri_full_image.png')}
        marginTop="100px"
        width="100%"
        />
        <ComponentCaseImages
          stats={images}
          className="footer-images"
        />
        <ComponentCaseNav
        match="revo-fitness"
        />
     </div>
    );
  }
}