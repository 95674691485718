/*
  BusseltonSwim.jsx
  @Component - Busselton Swim Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './BusseltonSwim.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/BusseltonSwim/114A2891-web-v2.jpeg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/BusseltonSwim/0Y9A8396-web.jpeg'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/BusseltonSwim/0Y9A8087-web.jpeg'),
    background_size: 'cover',
  }

]


const images_two = [
  {
    id: 1,
    image: cdn('/Case_studies/BusseltonSwim/3386images.jpg'),
    // width:'30%',
    className:'img',
  },
  {
    id: 2,
    image: cdn('/Case_studies/BusseltonSwim/0Y9A8636-web.jpeg'),
    // width:'33%',
    borderRadius: '32px',
    className:'img',

  },
  {
    id: 3,
    image: cdn('/Case_studies/BusseltonSwim/0Y9A8924-web-v2.jpeg'),
    borderRadius: '32px',
    className:'img',

  },
  {
    id: 4,
    image: cdn('/Case_studies/BusseltonSwim/5years asponsor.jpg'),
    className:'img',
  },  

];

const stat_info = [
  
  
  {
    id: 1,
    amount: '"The Buzz has become a favourite among staff and students and this is thanks to the creativity of the OKMG team. They are very focused on the client and really take the time to understand the business/industry that we\'re in, to ensure the content is relevant and successful. I really enjoy working with them as they are very approachable and flexible!"',
    text: 'Sophie Waddell, Marketing Manager - South East Asia',
    color: 'rgb(10 78 132)',
  },
  {
    id:2,
    image_left:cdn('/Case_studies/ECC/ECC_PHONE MOCKUP.png'),
    has_image: true,
    image_width:'460px',
    color: 'rgb(10 78 132)',
  },

];

export default class ECC extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="busselton-swim-wrapper">
      <MetaTags>
        <title>Busselton Jetty Swim - An iconic event in WA - OKMG</title>
        <meta name="description" content="Busselton Swim - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/busselton-jetty-swim'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Busselton Jetty Swim" role="heading"  
        sub_content="Creative "
        service_info="The Busselton Jetty Swim is an iconic, annual swimming event that draws locals and tourists to the recently re-developed foreshore. OKMG has proudly participated as a media partner in this iconic event for the last 5 years."
        hover_image_two={cdn('/Case_studies/BusseltonSwim/210214_BJS_Drone Photos-25-web.jpeg')}
        hover_image_one={cdn('/Case_studies/BusseltonSwim/114A2617-web-v2.png')}
        hover_image_three={cdn('/Case_studies/BusseltonSwim/114A2573-web-v2.jpeg')}
        image_width="100%"
        className="special"
        image_class="header-image"
        foot_image={cdn('/Case_studies/HairSupplies/HAIR SUPPLIES_BRAND ASSET-01%402x.png')}
        foot_image={cdn('/Case_studies/BusseltonSwim/Mask-Group-38.png')}
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>Each year, the Busselton Jetty Swim attracts thousands of people eager to participate in the annual swim. The jetty itself is the longest wooden piled jetty in the Southern Hemisphere, bringing on logistical challenges for content, not to mention the multiple categories of the swimming events.</p>
            <p>With so much happening at once, it requires a prepared content team to be ready for the exposure to the elements and ensuring to be in the right place at the right time. </p>
            <p>Over the 5 years of capturing the monumental Busselton Jetty Swim, the OKMG team have perfected our approach to the event. Through meticulous planning and agility, we consistently capture the incredible atmosphere and inspirational moments of this event; down to the last detail, from the swims to interviews to the crowd. </p>
            <p>The content is then distributed both as TVCs and across social media platforms, creating further awareness of an event that generates massive community engagement.  </p>
          </div>}
        />

        <ComponentVideo
          video_url={cdn('/Case_studies/BusseltonSwim/busselton-swim-video-min.mp4')}
          marginTop="100px"
          />

          <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="60px"
          width="70%"
          special_class_wrapper="special-wrapper"
          special_image={cdn('/Case_studies/BusseltonSwim/MASTER-LOGO-01-2.png')}
          />

           <ComponentFullBleedImage
          image={cdn('/Case_studies/BusseltonSwim/210214_BJS_Drone Photos-15-web-v2.jpeg')}
          width="100%"
          marginTop="60px"
          />
  
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}