/*
  RevoFitness.jsx
  @Component - Revo Fitness Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './hivo.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const images_two = [
  
  {
    id: 1,
    image: cdn('/Case_studies/HIVO/HIVO_Partner Logos.png'),
    width:'60%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/HIVO/HIVO Mobile_Mockup.png'),
    width:'40%',
    borderRadius: '32px',
    className:'special'
  }

]

const images = [
   {
    id: 1,
    image: cdn('/Case_studies/HIVO/HIVO_SCREENSHOT-1.png'),
    background_repeat: 'no-repeat',
    background_size: 'contain',
    background_color: 'rgb(216 216 216)',
  },
  {
    id: 2,
    image: cdn('/Case_studies/HIVO/HIVO_BROCHURE.png'),
    background_repeat: 'no-repeat',
    background_size: 'contain',
    background_color: 'rgb(30 41 169)',
  },
  {
    id: 3,
    image: cdn('/Case_studies/HIVO/HIVO_SCREENSHOT-2.png'),
    background_repeat: 'no-repeat',
    background_size: 'contain',
    background_color: 'rgb(216 216 216)',
  }


]



export default class HIVO extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="hivo-wrapper">
      <MetaTags>
        <title>HIVO - Digital Asset Management - OKMG</title>
        <meta name="description" content="HIVO - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/hivo'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="HIVO" role="heading"
        sub_content="Strategy | Digital | Creative | Marketing"
        service_info="Built by OKMG for marketing and communications professionals, using AI technology to create an intelligent, custom-built digital asset management (DAM) system. HIVO revolutionised how OKMG works with clients. "
        intro_para="OKMG has been solely responsible for the development and ongoing operation of HIVO. OKMG has been solely responsible for the development and ongoing operation of HIVO."
        image={cdn('Case_studies/HIVO/hivo-ipad.jpg')}
        image_width="110%"
        image_class="header-image"
        foot_image={cdn('/Case_studies/HIVO/HIVO_icon.png')}
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>HIVO is the leading technology of digital asset management. The brainchild of the OKMG team, HIVO allows us to offer more seamless and efficient communications with our clients.</p>
            <p>What began as a need to internally manage our library of digital assets, quickly became an integral part of OKMG. Built from innovative and interactive technology, HIVO has grown to be a platform for the storage of assets, a space to communicate and collaborate with clients, job and task management as well as time tracking for our team ensuring we continue to maintain accountability across all projects.</p>
            <p>HIVO facilitates OKMG in helping brands to grow and navigate through a rapidly transforming digital landscape dominated by our continual advancements in innovative technology, ensuring your campaigns and brands create maximum impact.</p>
             <ComponentReadMore
             content={
              <span>
                  <p>HIVO was created from an initial challenge to internally manage scheduled photo and video shoots using a database of professional photographs. As the project progressed, focus shifted to facilitating complete digital management of all assets produced from the scheduled shoots, and allowing the delivery of assets to clients in a seamless way over the internet.</p>
                  <p>Initial intentions for HIVO was that it would serve as a resource database with end-to-end services for OKMG clients and job management tasks. As OKMG grew and the agency proactively engaged in discovery activities, other technology was integrated, creating numerous ways to locate required assets.</p>
                  <p>This involved the creation of multiple search filters such as by client, user, status, job, name, tag, type, date, colour, metadata.</p>
                  <p>HIVO isn’t just a system for managing files - it’s a platform built to ensure that your content, campaigns and brand create maximum impact.</p>
                  <p>A highly intelligent system which combines job management, time tracking, a resource database, a digital asset library and video collaboration, adobe integration, communication channels and auto-predicted tagging (Amazon Rekognition API), with future development for facial recognition capabilities.</p>
                  <p>HIVO aims to help brands grow in and navigate a rapidly transforming digital landscape dominated by continual advancement in social and mobile technology. Through HIVO you are ensured improved operational efficiency.</p>
              </span>
            }
             />
          </div>}
        />
         <ComponentFullBleedImage
          image={cdn('/Case_studies/HIVO/HIVO_Stats.svg')}
          width="350px"
          marginTop="0px"
          marginTop="100px"
          className="special-after-full-image"
          />

          <ComponentVideo
          video_url={cdn('/Case_studies/HIVO/hivo-website-screen-grab-trimmed.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="150px"
          className="video-special"
          screenCast
          />
          
           <ComponentVideo
          video_url={cdn('/Case_studies/HIVO/hivo-how-it-works.min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="150px"
          />

          <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="100px"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/HIVO/hivo-banner.jpg')}
          marginTop="0px"
          />
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />

          <ComponentCaseNav
          match="hivo"
          />
     </div>
    );
  }
}