/*
  CicerellosSeaCritters.jsx
  @Component - Cicerellos Sea Critters Case Study
*/

import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './CicerellosScentOfSummer.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';
import Lottie from 'react-lottie';
import Reveal from 'react-reveal/Fade';


// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';


const _anim = {};
function fetchAnimation(url) {
  return _anim[url] = _anim[url] || fetch(url).then(res => res.json());
}
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]
// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/CicerellosScentOfSummer/box1.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/CicerellosScentOfSummer/box2.png'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/CicerellosScentOfSummer/box3.png'),
    background_size: 'cover',
  }

]

const images_critters = [
   {
    id: 1,
    image: cdn('/Case_studies/CicerellosSeaCritters/sea-critters-blue-fish.jpg'),
    background_size: 'contain',
    background_repeat:'no-repeat',
    background_position:'bottom left',
    width:'33.33%',
    className:'sp-image-one'
  },
  {
    id: 2,
    image: cdn('/Case_studies/CicerellosSeaCritters/sea-critters-crab.jpg'),
    background_size: 'contain',
    background_repeat:'no-repeat',
    width:'33.33%',
    background_position:'bottom',
    className:'sp-image-two'
  },
  {
    id: 3,
    image: cdn('/Case_studies/CicerellosSeaCritters/sea-critters-yellow-fish.jpg'),
    background_size: 'contain',
    background_repeat:'no-repeat',
    width:'33.33%',
    background_position:'bottom',
    className:'sp-image-two'
  }

]

const images_gifs = [
   {
    id: 1,
    image: cdn('/Case_studies/CicerellosSeaCritters/image_1%402x.png'),
    width:'20%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/CicerellosSeaCritters/Swell Times IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/CicerellosSeaCritters/Cicerellos Sea Critters_INSTA%402x.png'),
    width:'30%',
  },
  {
    id: 4,
    image: cdn('/Case_studies/CicerellosSeaCritters/Open Now IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 6,
    image: cdn('/Case_studies/CicerellosSeaCritters/image_1%402x.png'),
    width:'20%',
  },


]


export default class CicerellosSeaCritters extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      animatonCrab: null,
      animationNemoFish: null,
      animationDoryFish: null
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

     fetchAnimation('/assets/animations/Crab.json').then(data => {
      this.setState({ animationCrab: data });
    });
     fetchAnimation('/assets/animations/NemoFish.json').then(data => {
      this.setState({ animationNemoFish: data });
    });
     fetchAnimation('/assets/animations/DoryFish.json').then(data => {
      this.setState({ animationDoryFish: data });
    });

  }
  render(){

    const defaultCrab = {
      loop: true,
      animationData: this.state.animationCrab,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    const defaultNemoFish = {
      loop: true,
      animationData: this.state.animationNemoFish,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    const defaultDoryFish = {
      loop: true,
      animationData: this.state.animationDoryFish,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="cicerellos-scent-of-summer-wrapper">
      <MetaTags>
        <title>Cicerello's Scent Of Summer - OKMG</title>
        <meta name="description" content="Cicerello's Scent Of Summer- Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/cicerellos-scent-of-summer'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Cicerello's - The Scent of Summer" role="heading"
        sub_content="Strategy | Digital | Creative | Marketing"
        service_info="The effect of COVID-19 on tourism has remained widespread, particularly across the hospitality sector. However, OKMG saw this as an opportunity to shine the light back on a local icon, Cicerello’s, creating a left of field content piece, reminding Australians of something that unites us all, our sense of humour."
        image={cdn('/Case_studies/CicerellosScentOfSummer/perfume.png')}
        image_class="header-image"
        image_width="90%"
        foot_image={cdn('/Case_studies/CicerellosScentOfSummer/side.png')}
        foot_image_width="250px"
        foot_image_left="0"
        />
        
        <ComponentCaseCopy
        content={
          <div>
            <p>There is nothing more quintessential to the West Australian summer than basking in the warmth of the sun as you tuck into a delicious batch of golden fish 'n' chips, as the refreshing breeze of the Freo Doctor caresses your skin. It's a bittersweet feeling when the long balmy days fade away, which lead us to ask; what if there was a way to keep this summer feeling with us always? Introducing the first fragrance line by Cicerello's, 'The Scent of Summer' by Cicerello's.</p>
            <p>A campaign of this nature has one specific time of the year where it can be executed, in the lead-up to April Fools'. To create content convincing the public that Cicerello's was releasing a fragrance line, we set to work producing a piece that emulated a genuine perfume ad. Recreating the heightened drama, romance and mystery that is so often seen in Chanel and Dior ads.</p>
            <p>Building up the anticipation further, we shifted the usual content of Cicerello's socials to mimic that of a high-end fashion social media account, alerting Cicerello's audience that something big was about to drop.</p>
            <p>We finally released the faux perfume ad the day before April Fools. We invited people to register to receive a limited release perfume bottle of Cicerello's Scent of Summer' The Freo Doctor' via a custom built landing page, curated with sophisticated product imagery, further fuelling the hoax. Unfortunately, there is not an actual Cicerello's perfume. However, the audience interaction was frenzied enough that there is current consideration towards releasing what could be the world's first fish 'n' chip perfume.</p>
          </div>}
        />

          <Reveal up>
          <div className="video-with-special">
            <img src={cdn('/Case_studies/CicerellosScentOfSummer/corner-img.png')}  alt="side image" />
            <ComponentVideo
            video_url="https://okmg-digital-assets.s3-ap-southeast-2.amazonaws.com/Cicerellos/2021_CICERELLO'S_SCENT+OF+SUMMER_PERFUME_AD_FINAL_16_9-web.mp4"
            autoplay="autoplay"
            loop="loop"
            marginTop="100px"
            />
          </div>
          </Reveal>
         
          <ComponentVideo
            video_url={cdn('/Case_studies/CicerellosScentOfSummer/cic-scroll.mp4')}
            autoplay="autoplay"
            loop="loop"
            marginTop="10px"
            frameImg={cdn('/websiteScrolls/iMac_Mockup_v2.png')}
          />           

          <ComponentFullBleedImage
          image={cdn('/Case_studies/CicerellosScentOfSummer/box-big.png')}
          marginTop="100px"
          width="100%"
          />
          
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}