/*
  BlobOne.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ComponentCaseImages.scss';
import Reveal from 'react-reveal/Reveal';


export default class ComponentCaseImages extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  render(){

   const {
      stats,
      marginTop,
      width,
      wrap,
      left,
      background_position,
      className
      } = this.props;

  return (
      <div className={className + " component-case-images-wrapper"} style={{marginTop:marginTop,width:width,flexWrap:wrap ? 'wrap' : '',left:left}}>
        {
          stats.map(images =>
            <div className={images.className ? images.className + ' stat-wrap' : 'stat-wrap'} key={images.id} style={{backgroundPosition:images.background_position,backgroundImage:`url(${images.image})`,backgroundSize:images.background_size,backgroundColor:images.background_color,backgroundRepeat:images.background_repeat,width:images.width}}>
              
            </div>
          )
        }
      </div>
  );
  }
}