/*
  WAAC.jsx
  @Component - WAAC Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './WAAC.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/WAAC/5O3A8423-min.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/WAAC/IMG_0790-min.jpg'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/WAAC/5O3A8412-min.jpg'),
    background_size: 'cover',
  }

]


export default class WAAC extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="waac-wrapper">
      <MetaTags>
        <title>WAAC - WA Aids Council - OKMG</title>
        <meta name="description" content="WAAC - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/waac'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="WAAC" role="heading"  
        sub_content="Strategy | Creative "
        service_info="The Western Australian AIDS Council was founded in 1985 during the height of the HIV / AIDS pandemic and has been serving WA’s community ever since."
        image={cdn('/Case_studies/WAAC/210310_WAAC_BRAND MOODBOARD.svg')}
        image_width="160%"
        main_image_position_right="200px"
        main_image_position_top="150px"
        foot_image={cdn('/Case_studies/WAAC/WAAC_Logo.png')}
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>Since its foundation, the organisation has evolved to encompass a wide scope of health services and community programs to support WA’s queer community and at risk populations. In order to better represent the breadth and diversity of the organisation, OKMG were engaged to undergo a research and development project to strategically execute a name change and updated visual identity.</p>
            <p>OKMG led an in-depth and highly involved research and discovery process to engage the broader WA community, interviewing and surveying all stakeholders to ensure the brief for the new brand development would satisfy the community’s diverse requirements.</p>
            <p>The brand evolution, from WA Aids Council to WAAC, and updated visual identity was strongly supported by evidence and community feedback so that all stakeholders provided input and had a sense of ownership.</p>
            <p>For an organisation that has meant so much to so many over the past 40 years, WAAC is now future ready.</p>
          </div>}
        />

         <ComponentFullBleedImage
          image={cdn('/Case_studies/WAAC/Screen Shot 2021-03-25 at 9.29.25 am.png')}
          width="600px"
          marginTop="0px"
          marginTop="100px"
          left="54px"
          />

          <ComponentVideo
          video_url={cdn('/Case_studies/WAAC/WAAC_Naming-Video_EDIT-V5-web-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />

          

         
           <ComponentFullBleedImage
          image={cdn('/Case_studies/WAAC/WAAC_FLYER MOCKUP-min.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />
  
          <ComponentCaseImages
            stats={images}
          />
          <ComponentCaseNav
          match="waac"
          />
     </div>
    );
  }
}