/*
  RonFarris.jsx
  @Component - Murdoch Uni Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './RonFarris.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]
// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/RonFarris/ron-farris-small-image.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/RonFarris/ron-farris-small-image-two.jpg'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/RonFarris/ron-farris-small-image-three.jpg'),
    background_size: 'cover',
  }

]

const images_squares = [
   {
    id: 1,
    image: cdn('/Case_studies/RonFarris/ron-farris-original-logo.jpg'),
    background_size: 'calc(100% - 50px)',
    background_repeat:'no-repeat',
    background_position:'bottom left',
    width:'340px',
    className:'sp-image-one'
  },
  {
    id: 2,
    image: cdn('/Case_studies/RonFarris/ron-farris-new-logo.jpg'),
    background_size: 'calc(100% - 50px)',
    background_repeat:'no-repeat',
    width:'400px',
    background_position:'bottom',
    className:'sp-image-two'
  },

]

const images_gifs = [
   {
    id: 1,
    image: cdn('/Case_studies/RonFarris/image_1%402x.png'),
    width:'20%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/RonFarris/Swell Times IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/RonFarris/Ron Farris_INSTA%402x.png'),
    width:'30%',
  },
  {
    id: 4,
    image: cdn('/Case_studies/RonFarris/Open Now IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 6,
    image: cdn('/Case_studies/RonFarris/image_1%402x.png'),
    width:'20%',
  },


]


export default class RonFarris extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="ron-farris-wrapper">
      <MetaTags>
        <title>Ron Farris- Real Estate Agents Commercial Property Management - OKMG</title>
        <meta name="description" content="Ron Farris Real Estate Agents Commercial Property Management Rural Sales Perth WA" />
        <link rel='canonical' href='https://okmg.com/work/ron-farris-real-estate'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Ron Farris Real Estate" role="heading"
        sub_content="Strategy | Digital | Creative"
        service_info="Ron Farris Real Estate has been established within the WA property scene for over 30 years. OKMG was engaged to relaunch the brand to reflect the Ron Farris team's leading property knowledge and expertise with a more refined and sophisticated visual language, elevating their position in the real estate market."
        image={cdn('/Case_studies/RonFarris/ron-farris-header-image.jpg')}
        image_class="header-image"
        image_width="100%"
        foot_image={cdn('/Case_studies/RonFarris/ron-farris-logo.jpg')}
        foot_image_width="100px"
        foot_image_left="0"
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>The recent shift of ownership within the company from the founder, Ron Farris, to his two children Anna-Maree and Paul, opened itself up as an opportunity to take the Ron Farris brand into a more modern space.</p>
            <p>As the Ron Farris name and brand were highly recognisable within the property industry, it was requested that the name remain and the colour palette not be steered too far from the original.</p>
            <p>We refined the colour scheme into subtle shades, separated it into primary and secondary palettes, and implemented a balanced colour ratio. We retained the brand name 'Ron Farris', applying a combination of low and high contrast san serif typefaces, drawing focus to 'Farris', as a nod to the renewed direction of the company. Using the 'RFRE' letters, a brand mark was introduced, allowing for more practical applications across collateral.</p>
            <p>The refreshed Ron Farris brand was launched, accompanied with their new tagline ‘Your Partner in Property’ across the entire business, including digital and print content, office signage fit-out and a custom built website. With this renewed modern direction, the brand's visual language accurately reflected their reputable property portfolio, reestablishing their position as leaders in the property market.</p>
          
          </div>}
        />

          <ComponentCaseImages
            stats={images_squares}
            className="square-block-images"

          />

          <ComponentVideo
          video_url={cdn('/Case_studies/RonFarris/ron-farris-screen-recording.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          screenCast
          />
 
          <ComponentFullBleedImage
          image={cdn('/Case_studies/RonFarris/ron-farris-flyer.jpg')}
          marginTop="100px"
          width="70%"
          />
      

          <ComponentFullBleedImage
          image={cdn('/Case_studies/RonFarris/ron-farris-main-image.jpg')}
          marginTop="100px"
          width="100%"
          />
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}