/*
  BoomersProtein.jsx
  @Component - Boomers Protein Case Study
*/

import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './BoomersProtein.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]

//stat images
const images_stat = [
  {
   id: 1,
   image: cdn('/Case_studies/BlackBurne/6developments.jpg'),
   width:'calc(50% - 30px)',
 },
 {
   id: 2,
   image: cdn('/Case_studies/BoomersProtein/BOOMERS-GIF-04.gif'),
   width:'calc(50% - 30px)',
   borderRadius: '32px',
   className:'special'
 },
 {
   id: 3,
   image: cdn('/Case_studies/BoomersProtein/BOOMERS_QUIZ_ANIMATED AD.gif'),
   width:'calc(50% - 30px)',
   borderRadius: '32px',
   className:'special'
 },

{
   id: 4,
   image: cdn('/Case_studies/BlackBurne/50videos.jpg'),
   width:'calc(50% - 30px)',
   borderRadius: '32px',
 },
];


// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/BoomersProtein/boomers-small-image-one.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/BoomersProtein/boomers-small-image-two.jpg'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/BoomersProtein/boomers-small-image-three.jpg'),
    background_size: 'cover',
  }

]


const images_gifs = [
   {
    id: 1,
    image: cdn('/Case_studies/BoomersProtein/image_1%402x.png'),
    width:'20%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/BoomersProtein/Swell Times IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/BoomersProtein/Boomers Protein_INSTA%402x.png'),
    width:'30%',
  },
  {
    id: 4,
    image: cdn('/Case_studies/BoomersProtein/Open Now IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 6,
    image: cdn('/Case_studies/BoomersProtein/image_1%402x.png'),
    width:'20%',
  },


]


export default class BoomersProtein extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="boomers-protein-wrapper cicerellos-sea-critters-wrapper">
      <MetaTags>
        <title>Boomers Protein - OKMG</title>
        <meta name="description" content="Boomers Protein - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/boomers-protein'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Boomers Protein" role="heading"
        sub_content="Strategy | Digital | Creative | Marketing"
        service_info="Boomers Protein is Australia’s longest running protein powder company. Having successfully established a dedicated following, Boomers engaged OKMG to implement a revised marketing strategy to continue to grow brand awareness and welcome a new customer base."
        image={cdn('/Case_studies/BoomersProtein/boomers-header-image.jpg')}
        image_class="header-image"
        image_width="90%"
        foot_image={cdn('/Case_studies/BoomersProtein/boomers-crumb.jpg')}
        foot_image_width="250px"
        foot_image_left="0"
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>With their extensive background in the fitness and health industries, the team at Boomers have a comprehensive understanding of the role that protein plays to ensure a healthy body. Recognising a gap in the market, the Boomers team created a solution for people looking to add protein into their diet, through their natural and flavour free protein powders.</p>
            <p>As Boomers largely sold their product online, there was a barrier for consumers who wanted to try the protein before they buy. We saw an opportunity to generate content that would create a direct link between the audience and product usage, emphasising the value of Boomers Proteinas as a supplement, instead of just a post workout protein powder. </p>
            <p>We created content with a focus on education, encouraging users to learn more before hitting them with sales messaging. Content included recipes informing consumers on the best way to consume Boomers Protein, plus a Protein Quiz that would curate the most effective protein for specific individuals. Whether you’re lactose free, gluten free or new to protein powders, we assisted Boomers to match their consumers to the perfect product for them.</p>
            <p>We complemented the online quiz with remarketing through social media, capturing our audience’s attention at a time where they would be scrolling and shopping for protein products to effectively converting to sales.</p>
          </div>}
        />

         <ComponentVideo
          video_url={cdn('/Case_studies/BoomersProtein/BOOMERS_PROTEIN RECIPES_GEORGIA INTRO_PRODUCTS_EDIT V1-web.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
         
        <ComponentFullBleedImage
          image={cdn('/Case_studies/BoomersProtein/boomers-quiz-screen-grab.jpg')}
          marginTop="100px"
          width="100%"
          />
 
          {/* <ComponentCaseImageGifs 
          stats={images_stat}
          marginTop="100px"
          width="70%"
          /> */}

          <ComponentFullBleedImage
          image={cdn('/Case_studies/BoomersProtein/boomers-main-image.jpg')}
          marginTop="100px"
          width="100%"
          />
          
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}