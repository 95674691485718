/*
  RevoFitness.jsx
  @Component - Revo Fitness Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './FoodiesMarket.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]

//stat images
const images_two = [
  {
   id: 1,
   image: cdn('/Case_studies/FoodiesMarket/foodies-57.png'),
   width:'calc(50% - 30px)',
 },
 {
   id: 2,
   image: cdn('/Case_studies/FoodiesMarket/foodies-rec-2.jpg'),
   width:'calc(50% - 30px)',
   borderRadius: '32px',
   className:'special'
 },
 {
   id: 3,
   image: cdn('/Case_studies/FoodiesMarket/foodies-rec-3.jpg'),
   width:'calc(45% - 30px)',
   borderRadius: '32px',
   className:'special'
 },

{
   id: 4,
   image: cdn('/Case_studies/FoodiesMarket/foodies-click-collect.png'),
   width:'calc(55% - 30px)',
   borderRadius: '32px',
 },
];

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/FoodiesMarket/foodies-market-small-image-one.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/FoodiesMarket/foodies-market-small-image-two.jpg'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/FoodiesMarket/foodies-market-image-small-three.jpg'),
    background_size: 'cover',
  }

]

const images_squares = [
   {
    id: 1,
    image: cdn('/Case_studies/FoodiesMarket/foodies-square-one.jpg'),
    background_size: '316px 316px',
    background_repeat:'no-repeat',
    background_position:'bottom',
    width:'316px',
    className:'sp-image-one'
  },
  {
    id: 2,
    image: cdn('/Case_studies/FoodiesMarket/foodies-square-two.jpg'),
    background_size: '316px 316px',
    background_repeat:'no-repeat',
    width:'316px',
    background_position:'bottom',
    className:'sp-image-two'
  },
  {
    id: 3,
    image: cdn('/Case_studies/FoodiesMarket/foodies-square-three.jpg'),
    background_size: '316px 316px',
    background_repeat:'no-repeat',
    width:'316px',
    background_position:'bottom',
    className:'sp-image-three'

  },
  {
    id: 4,
    image: cdn('/Case_studies/FoodiesMarket/foodies-square-four.jpg'),
    background_size: '316px 316px',
    background_repeat:'no-repeat',
    width:'316px',
    background_position:'top',
    className:'sp-image-four'
  },
  {
    id: 5,
    image: cdn('/Case_studies/FoodiesMarket/foodies-square-five.jpg'),
    background_size: '316px 316px',
    background_repeat:'no-repeat',
    width:'316px',
    background_position:'top',
    className:'sp-image-five'
  },
  {
    id: 6,
    image: cdn('/Case_studies/FoodiesMarket/foodies-square-six.jpg'),
    background_size: '316px 316px',
    background_repeat:'no-repeat',
    width:'316px',
    background_position:'top',
    className:'sp-image-six'
  },


]

const images_gifs = [
   {
    id: 1,
    image: cdn('/Case_studies/FoodiesMarket/image_1%402x.png'),
    width:'20%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/FoodiesMarket/Swell Times IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/FoodiesMarket/Foodies Market_INSTA%402x.png'),
    width:'30%',
  },
  {
    id: 4,
    image: cdn('/Case_studies/FoodiesMarket/Open Now IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 6,
    image: cdn('/Case_studies/FoodiesMarket/image_1%402x.png'),
    width:'20%',
  },


]


export default class FoodiesMarket extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="foodies-market-wrapper">
      <MetaTags>
        <title>Foodies Market IGA - Fast-casual fish & chips - OKMG</title>
        <meta name="description" content="Foodies Market is an oasis for gourmet enthusiasts, filled with locally sourced produce, international delicacies, and an assortment of organic goods." />
        <link rel='canonical' href='https://okmg.com/work/foodies-market-iga'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Foodies Market IGA" role="heading"
        sub_content="Strategy | Marketing | Creative | Digital"
        service_info="Foodies Market is an oasis for gourmet enthusiasts, filled with locally sourced produce, international delicacies, and an assortment of organic goods."
        image={cdn('/Case_studies/FoodiesMarket/foodies-laptop.jpg')}
        image_class="header-image"
        image_width="90%"
        foot_image={cdn('/Case_studies/FoodiesMarket/foodies-crumb.jpg')}
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>OKMG played a pivotal role in branding, marketing, and digital advertising for Foodies Market in Claremont, Perth. In terms of branding, the goal was to encapsulate Foodie’s Market ethos of supporting local producers and high-quality international suppliers by creating a colourful and approachable brand identity that echoes the market's dedication to quality and diversity. This was manifested in a vibrant logo design, evocative food related iconography, and an intuitive layout in all collateral materials.</p>

            <p>Foodies Market marketing strategy was designed to establish a strong presence in local communities through targeted initiatives, activations, collaborations, and generating a buzz that amplified the market's reputation as the go-to place for food lovers. On the digital advertising front, OKMG leveraged various platforms, especially social media, to reach wider audiences. Content was strategically curated to showcase the vibrant, fresh produce and unique products available at the market, making sure every web page, graphic, or video resonated with the market's branding.</p> 

            <p>We also launched targeted campaigns that capitalized on SMM and SEM channels, effectively boosting Foodies Market's online visibility to the local community and those in neighbouring suburbs. Foodies Market now has a digitally connected community that's just as vibrant and diverse as the offerings in-store. Foodies Market has not only experienced significant growth in foot traffic since opening, but also established a strong and distinctive brand and digital presence.</p>
          </div>}
        />

          <ComponentCaseImages
            stats={images_squares}
            className="square-block-images"

          />
 
          <ComponentFullBleedImage 
          image={cdn('/Case_studies/FoodiesMarket/foodies-screen-grab.jpg')}
          width="100%"
          marginTop="100px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/FoodiesMarket/foodies-market-full-banner.jpg')}
          width="100%"
          marginTop="100px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          />

          <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="100px"
          width="70%"
          />

          <ComponentFullBleedImage
          image={cdn('/Case_studies/FoodiesMarket/foodies-market-main-image.jpg')}
          marginTop="100px"
          width="100%"
          />
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}