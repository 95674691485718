/*
  ComponentHeader.jsx
*/

import React, { Component } from 'react';  
import { useLocation } from 'react-router-dom';
import TextLoop from "react-text-loop";
import './ComponentInnerPageHeader.scss';
import BlobOne from './../blobOne';
import ComponentSplitSpan from './../ComponentSplitSpan';
import Lottie from 'react-lottie';
import Basket from '../../Components/Basket';
import AnimatedBlob from '../../Components/AnimatedBlob';

const animationDataAgency = '/assets/animations/blob_agency.json';
const animationDataContact = '/assets/animations/contact_blob.json';
const animationDataWork = '/assets/animations/work_blob.json';
const animationDataStrategy = '/assets/animations/strategy_blob.json';
const animationDataDigital = '/assets/animations/digital_blob.json';
const animationDataCreative = '/assets/animations/creative_blob.json';
const animationDataServices = '/assets/animations/blob_services_general.json';
const animationDataVideoAnimation = '/assets/animations/animation-video.json';


const _anim = {};

function fetchAnimation(url) {
  return _anim[url] = _anim[url] || fetch(url).then(res => res.json());
}


export default class ComponentInnerPageHeader extends React.Component {


    constructor(props) {
    super(props);
    // State.

    this.state = {
      animationDataHome: null,
      animationDataWork:null,
      animationDataStrategy:null,
      animationDataDigital:null,
      animationDataCreative:null,
      animationDataServices: null,
      animationDataVideoAnimation: null
    };
  }


 

 componentDidMount() {
    this.fetchAnimationBasedOnPage();
  }

  componentDidUpdate(prevProps) {
        this.fetchAnimationBasedOnPage(prevProps);

  }


  fetchAnimationBasedOnPage(prevProps) {

      const prev = prevProps || {};

      if(prev.home !== this.props.home && this.props.home){
          // fetch home animation
          fetchAnimation('/assets/animations/blob_home.json').then(data => {
          this.setState({ animationDataHome: data });
        });
      }
     
      else if(prev.strategy !== this.props.strategy && this.props.strategy){
            // fetch strategy animation
         fetchAnimation('/assets/animations/strategy_blob.json').then(data => {
          this.setState({ animationDataStrategy: data });
        });
      }
      else if(prev.digital !== this.props.digital && this.props.digital){
           // fetch digital animation

          fetchAnimation('/assets/animations/digital_blob.json').then(data => {
          this.setState({ animationDataDigital: data });
        });
      }
      else if(prev.creative !== this.props.creative && this.props.creative){
            // fetch creative animation
         fetchAnimation('/assets/animations/creative_blob.json').then(data => {
          this.setState({ animationDataCreative: data });
        });
      }
       else if (prev.marketing !== this.props.marketing && this.props.marketing){
           // fetch work
         fetchAnimation('/assets/animations/work_blob.json').then(data => {
          this.setState({ animationDataWork: data });
        });
      }
      else if(prev.services !== this.props.services && this.props.services){
             // fetch services animation
          fetchAnimation('/assets/animations/blob_services_general.json').then(data => {
          this.setState({ animationDataServices: data });
        });
      }
      else if(prev.animationVideo !== this.props.animationVideo && this.props.animationVideo){
             // fetch services animation
          fetchAnimation('/assets/animations/animation-video.json').then(data => {
          this.setState({ animationDataVideoAnimation: data });
        });
      }

 
  }


render(){

   const defaultOptionsHome = {
      loop: true,
      animationData: this.state.animationDataHome,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const defaultOptionsAgency = {
          loop: false,
          animationData: this.state.animationDataAgency,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        };

    const defaultOptionsWork = {
          loop: true,
          animationData: this.state.animationDataWork,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        };

      const defaultOptionsStrategy = {
          loop: true,
          animationData: this.state.animationDataStrategy,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        };

        const defaultOptionsDigital = {
          loop: true,
          animationData: this.state.animationDataDigital,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        };

    const defaultOptionsCreative = {
          loop: true,
          animationData: this.state.animationDataCreative,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        };

 const defaultOptionsServices = {
          loop: true,
          animationData: this.state.animationDataServices,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        };

  const defaultOptionsAnimationVideo = {
          loop: true,
          animationData: this.state.animationDataVideoAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        };


  const {
      heading,
      sub_content,
      maxWidth,
      blob_color,
      home,
      agency,
      contact,
      strategy,
      digital,
      creative,
      marketing,
      services,
      animationVideo,
      special
   } = this.props;

  return (


    

     <div className={special ? 'component-header-inner home' : 'component-header-inner'}>
      <span className="header-content">
        <span className="sub-one">
        <h1 style={{maxWidth: maxWidth}}>
        <ComponentSplitSpan
        copy={heading} role="heading"
        />
        </h1>
        <p>{sub_content}</p>
        </span>
        <span className="sub-two">
        {home ? 

          <Lottie options={defaultOptionsHome}
              height={'100%'}
              width={'100%'}
              className="blob-homer"
              
              />
          : agency ?
          <div className="agency-blob">
            <Basket />
            <BlobOne
             colour={blob_color}
             bottom="auto"
            />
          </div>

          : marketing ?

           <Lottie options={defaultOptionsWork}
              height={'100%'}
              width={'100%'}
              className="blob-homer"
            />

          : strategy ? 

          <Lottie options={defaultOptionsStrategy}
              height={'100%'}
              width={'100%'}
              className="blob-homer"
              
              />

          : digital ?

          <Lottie options={defaultOptionsDigital}
              height={'100%'}
              width={'100%'}
              className="blob-homer"
              
              />

          : creative ?

          <Lottie options={defaultOptionsCreative}
              height={'100%'}
              width={'100%'}
              className="blob-homer"
              
              />

          : services ?

           <Lottie options={defaultOptionsServices}
              height={'100%'}
              width={'100%'}
              className="blob-homer"
              
              />


          : animationVideo ?


           <Lottie options={defaultOptionsAnimationVideo}
              height={'100%'}
              width={'100%'}
              className="blob-homer"
              
              />


          :
          <BlobOne
           colour={blob_color}
           bottom="auto"
          />
        }
         
        </span>
      </span>
     </div>
    );

  }
}