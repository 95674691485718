/*
  ScotchCollege.jsx
  @Component - ScotchCollege Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './ScotchCollege.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]


// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/ScotchCollege/scotch_box_img1.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/ScotchCollege/scotch_box_img2.png'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/ScotchCollege/scotch_box_img3.png'),
    background_size: 'cover',
  }

]

const images_gifs = [
  {
   id: 1,
   image: cdn('/Case_studies/ScotchCollege/scotch_gif3.gif'),
   width:'30%',
 },
 {
   id: 2,
   image: cdn('/Case_studies/ScotchCollege/scotch_gif2.gif'),
   width:'30%',
 },
 {
   id: 3,
   image: cdn('/Case_studies/ScotchCollege/scotch_gif1.gif'),
   width:'30%',
 },

]

export default class ScotchCollege extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="ScotchCollege-wrapper twin-fin-wrapper">
      <MetaTags>
        <title>Scotch College - OKMG</title>
        <meta name="description" content="Founded in 1897, Scotch College is one of Australia's leading independent private schools." />
        <link rel='canonical' href='https://okmg.com/work/scotch-college'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Scotch College" role="heading"
        sub_content="Creative | Strategy | Marketing"
        service_info="Founded in 1897, Scotch College is one of Australia's leading independent private schools. Wanting to expand upon their brand promise of 'Preparing Boys For Life', OKMG was engaged to develop and implement a campaign to instill confidence that Scotch College is the right school for their son."
        image={cdn('/Case_studies/ScotchCollege/SCOTCH_COLLEGE_Head.png')}
        image_class="header-image"
        image_width="85%"
        // foot_image={cdn('/Case_studies/FunkyMonkey/funky_monkey_small_logo.png')}
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>Located in Perth's coastal suburb of Swanbourne, Scotch College has consistently delivered a prestigious academic experience to students for over 170 years. We were approached to cement the Scotch offering through a brand campaign that focused on preparing boys for the future without losing the legacy of the school.</p>
            <p>This brand campaign needed to directly engage with prospective students and parents by positioning Scotch College as the beginning of a successful future.</p>
            <p>We took a highly collaborative approach with local graphic designer and Scotch College alumni, Toby O’Keeffe in developing the 'Undiscovered' campaign; Our key objective was to position Scotch College as an environment where students are encouraged to determine their path rather than prescribing a preconceived idea of success. We also wanted to reinforce that Scotch College is a partnership between teachers and parents, building a strong community, where boys learn to be their best selves.</p>
            <p>We executed this concept of multi-dimensional learning through a combination of photographic and graphic elements. We highlighted the expanse of opportunities at Scotch College by demonstrating its dedication to supporting what drives its students to realise their full potential.</p>
            <p>This campaign was launched across multiple media channels through a two-phase implementation, including programmatic, social, and out-of-home placements. These out-of-home ads were strategically positioned in areas of high commute, close to Scotch College, ensuring a place at the front of our audiences’ mind. We further optimised engagement with the digital ads to engage with a broader audience, expanding the reach of the campaign messaging, dramatically increasing enquiry rates on the custom designed landing page. </p>
            <p>The vibrant, dynamic creative, and multi-channel rollout generated awareness and activated existing and newly developed audiences for enrollment inquiries with an overwhelming response. The "Undiscovered" campaign successfully reinforced Scotch College's position as one of the leading educational facilities in Australia. </p>
          </div>}
        />

        <ComponentFullBleedImage
          image={cdn('/Case_studies/ScotchCollege/scotch_banner.gif')}
          marginTop="100px"
          width="100%"
          />

        <ComponentCaseImageGifs 
          stats={images_gifs}
          marginTop="60px"
          width="80%"
          className="gif-wrap"
        />

        {/* <ComponentVideo
          video_url={cdn('/Case_studies/ScotchCollege/scotch-webscroll.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          /> */}

          <ComponentFullBleedImage
          image={cdn('/Case_studies/ScotchCollege/scotch_full_bleed.png')}
          marginTop="100px"
          width="100%"
          />
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}