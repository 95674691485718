/*
  MKDC.jsx
  @Component - MKDC Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './MKDC.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/MKDC/square-one-min.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/MKDC/square-two-min.jpg'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/MKDC/square-three-min.jpg'),
    background_size: 'cover',
  }

];

const stat_info = [
  
  
  {
    id: 1,
    amount: '"The new website is absolutely great and we are all very proud to share and direct people to it. Many thanks to you and your team and you have a great group of people working with you. We are so thrilled with the result."',
    text: 'Kathy Kuthsinski - Director',
    color: 'rgb(211 40 26)',
  },
  {
    id:2,
    image_left:cdn('/Case_studies/MKDC/MKDC_Conference Video_2 min_EDIT V1-FINAL.00_00_52_06.Still001-web.jpeg'),
    has_image: true,
    image_width:'700px',
  },
  {
    id:3,
    image_left:cdn('/Case_studies/MKDC/Group 1330.svg'),
    has_image: true,
    image_width:'160px',
  },

];



export default class MKDC extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="mkdc-wrapper">
      <MetaTags>
        <title>MKDC - Commercial Interior Design & Aged Care Specialists - OKMG</title>
        <meta name="description" content="MKDC - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/mkdc'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="MKDC" role="heading"  
        sub_content="Strategy | Digital | Creative"
        service_info="A full-service agency engagement requiring in-depth content and a re-designed digital presence."
        hover_image_one={cdn("/Case_studies/MKDC/mkdc-cropped.png")}
        hover_image_two={cdn("/Case_studies/MKDC/mkdc-ipad-cropped.png")}
        className="special"
        image_class="header-image"
        foot_image_className="special-foot-image"
        foot_image={cdn('/Case_studies/MKDC/Group 1331.svg')}
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>MKDC have established themselves as leaders within the Perth interior design industry. Combining the niche pairing of commercial interior design and aged care specialists, the team at MKDC are building towards a future where every business values the impact that interior design has on its people. </p>
            <p>The MKDC team required a digital presence that accurately reflected their passion for their industry, team, clients and their overall ethos of elevating human potential through empathetic design approaches. Diving deep into the people and processes, we communicated the MKDC strategy visually through both a custom interactive infographic and video content, capturing the culture and human aspects, to ultimately show the people behind the work.</p>
            <p>An entirely collaborative process, this website has provided MKDC a platform to effectively show how their team co-designs with clients to deliver award-winning levels of design excellence and client satisfaction.</p>
          </div>}
        />

         <ComponentVideo 
          video_url={cdn('/Case_studies/MKDC/OKMG_MKDC-CASESTUDY_FINAL-web-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
         />

         <ComponentVideo 
          video_url={cdn('/websiteScrolls/mkdc2-rz-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          className="webscroll"
          marginTop="100px"
          screenCast
         />

          <ComponentCaseStats
            stats={stat_info}
            has_image='true'
          />
          <ComponentFullBleedImage
          image={cdn('/Case_studies/MKDC/200520_MKDC_INFOGRAPHIC-01.svg')}
          width="100%"
          marginTop="200px"
          />
          
          <ComponentFullBleedImage
          image={cdn('/Case_studies/MKDC/2020.06.26 MKDC SIGNAGE -2-min.jpg')}
          width="100%"
          marginTop="100px"
          />
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="mkdc"
          />
     </div>
    );
  }
}