/*
  BusseltonSwim.jsx
  @Component - Busselton Swim Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import BlobOne from '../../blobOne';
import './CurtinUniDestinationAustralia.scss';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/CurtinDiscover/discover-two-image.png'),
    background_size: 'cover',
  },


]


const images_two = [
  {
    id: 1,
    image: cdn('/Case_studies/CurtinDiscover/discover-1.jpg'),
    // width:'30%',
    className:'img',
  },
  {
    id: 2,
    image: cdn('/Case_studies/CurtinDiscover/discover-2.jpg'),
    // width:'33%',
    borderRadius: '32px',
    className:'img',

  },
  {
    id: 3,
    image: cdn('/Case_studies/CurtinDiscover/discover-3.jpg'),
    borderRadius: '32px',
    className:'img',

  },

];



export default class CurtinDestination extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="curtin-uni-destination">
      <MetaTags>
        <title>Curtin University - Destination Australia - OKMG</title>
        <meta name="description" content="Curtin University - Destination Australia" />
        <link rel='canonical' href='https://okmg.com/work/curtin-university-destination-austraia'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Curtin University Destination Australia" role="heading"  
        sub_content="Strategy | Creative"
        service_info="Curtin University developed a regional program targeted at domestic and international students looking to study full-time degrees at Curtin’s regional campus at Kalgoorlie (WASM)"
        image_width="70%"
        image={cdn('/Case_studies/CurtinDiscover/curtin-destination-banner.jpg')}
        image_class="header-image"
        foot_image={cdn('/Case_studies/CurtinDiscover/curtin-breaker.png')}
        foot_image_width="300px"
        foot_image_left="0"
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>Destination Australia funds eligible tertiary education providers to offer scholarships to domestic and international students to study and live in regional Australia. The Destination Australia Program aims to attract and support international and domestic students to study in regional Australia and to grow and develop regional Australian tertiary education providers and offer students a high-quality learning experience.</p>

            <p>From 2019-2021, Curtin University awarded 25 scholarships, and in 2022 allocated a further 26 2-year scholarships.</p>

            <p>OKMG was brought in to develop a destination-led creative campaign that promoted the benefits of student experience / living in regional Australia, built awareness and drove applications for eligible courses at Curtin Kalgoorlie to increase the pool of eligible candidates for the Destination Australia Program.</p>

            <p>Our creative campaign covered the development of strategic insights, audience personas, messaging, creative concepts, on-location content production and comprehensive campaign execution.</p>

            <p>Curtin’s Global Recruitment team then leveraged the Destination Australia Program as a conversion tool to influence students applying for eligible courses at the regional campus to accept their offer from Curtin.</p>

          </div>}
        />

        <ComponentVideo
          video_url={cdn('/Case_studies/CurtinDiscover/destination-australia-min.mp4')}
          marginTop="100px"
          />

          <ComponentCaseImages 
          marginTop="60px"
          width="70%"
          className="case-image"
          stats={images}
          />

           <ComponentFullBleedImage
          image={cdn('/Case_studies/CurtinDiscover/discover-banner.jpg')}
          width="100%"
          marginTop="60px"
          />
  
          <ComponentCaseImages
            className="footer-images"
            stats={images_two}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}