/*
  RevoFitness.jsx
  @Component - Revo Fitness Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './WiTi.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
// for stats
const stat_info = [
  {
    id: 1,
    amount: '58%',
    text: 'CTR',
    color: 'rgb(230, 46, 46)',
  },
  {
    id: 2,
    amount: '134%',
    text: 'Increased Sign Up Rate',
    color: '#2C2B2D',
  },
  {
    id: 3,
    amount: '426k',
    text: 'Impressions',
    color: 'rgb(230, 46, 46)',
  }
]



// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/WiTi/witi_box_image_1.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/WiTi/witi_box_image_2.png'),
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/WiTi/witi_box_image_3.png'),
    background_size: 'cover',
  }

]


const images_gifs = [
   {
    id: 1,
    image: cdn('/Case_studies/HoneyForLife/image_1%402x.png'),
    width:'20%',
  },
  {
    id: 2,
    image: cdn('/Case_studies/HoneyForLife/Swell Times IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 3,
    image: cdn('/Case_studies/HoneyForLife/Honey for Life_INSTA%402x.png'),
    width:'30%',
  },
  {
    id: 4,
    image: cdn('/Case_studies/HoneyForLife/Open Now IG (1).gif'),
    width:'20%',
    borderRadius: '32px',
    className:'special'
  },
  {
    id: 6,
    image: cdn('/Case_studies/HoneyForLife/image_1%402x.png'),
    width:'20%',
  },


]


export default class HoneyForLife extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="WiTi-wrapper twin-fin-wrapper">
      <MetaTags>
        <title>WiTi - OKMG</title>
        <meta name="description" content="WiTi is a fast-growing, industry leading Anti-Theft solution for your caravan, camper trailer or tradie trailer." />
        <link rel='canonical' href='https://okmg.com/work/witi'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="WiTi" role="heading"
        sub_content="Digital | Creative | Marketing"
        service_info="WiTi is a fast-growing, industry leading Anti-Theft solution for your caravan, camper trailer or tradie trailer. With a focus on facilitating an effortless customer experience, the company has grown to be a security must-have on every adventure."
        image={cdn('/Case_studies/WiTi/witi-anti-theft-gps.png')}
        image_class="header-image"
        image_width="85%"
        foot_image={cdn('/Case_studies/WiTi/witi_signal.png')}
        />
        <ComponentCaseCopy
        content={
          <div>
            <p>WiTi has developed the world's first Anti-Theft wireless towing technology, allowing travellers to 'Journey Simply', focusing on their adventure and carefree from worries around the security of their vehicle.</p>
            <p>OKMG was engaged to assist WiTi in rolling out their brand in the lead up to launch their products in a favourite Australian fishing and camping store. Through the collaborative Brand Discovery workshop, we discussed the increase of travel in the Australian outback, primarily due to Covid-19 restricting holiday plans.</p>
            <p>We advised a refinement of their existing brand to ensure that WiTi would stand out when competing against other brands in-store, owning their position in the travel security market. Maintaining original elements of their brand, we elevated their visual identity to a clean and mature brand that accurately reflects their innovative technology.</p>
            <p>We applied the updated WiTi brand across both their website and packaging, one that is instantaneously recognisable in-store, backed by a strong e-commerce presence as they embark on this next chapter of growth. </p>
          </div>}
        />

        <ComponentFullBleedImage 
        image={cdn('/Case_studies/WiTi/witi_antitheft_packaging.png')}
        width="100%"
        marginTop="100px"
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        className="marginLeftZero"
        />

        <ComponentFullBleedImage 
        image={cdn('/Case_studies/WiTi/Anti_theft_product.png')}
        width="100%"
        marginTop="100px"
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        />


        <ComponentVideo
          video_url={cdn('/Case_studies/WiTi/witi-webscroll.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          frameImg={cdn('/websiteScrolls/iMac_Mockup_v2.png')}
          />

        <ComponentFullBleedImage 
          image={cdn('/Case_studies/WiTi/WITI_FLYER.png')}
          width="100%"
          marginTop="0px"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          />
{/* 
          <ComponentVideo
          video_url={cdn('/Case_studies/HoneyForLife/HFL_HOWITWORKS_WIDE_EDIT V2-webv1.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />      */}

          <ComponentFullBleedImage
          image={cdn('/Case_studies/WiTi/witi_full_bleed_img_1.png')}
          marginTop="100px"
          width="100%"
          />
          <ComponentCaseImages
            stats={images}
            className="footer-images"
          />
          <ComponentCaseNav
          match="revo-fitness"
          />
     </div>
    );
  }
}