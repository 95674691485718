/*
  SourceEnergy.jsx
  @Component - SourceEnergy Case Study
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './SourceEnergy.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/SourceEnergy/source-energy-panels-min.png'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/SourceEnergy/[sourceenergyco.com.au][361]Sundance-min.jpg'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/SourceEnergy/source-energy-panels-v2-min.png'),
    background_size: 'cover',
  }

]


const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/SourceEnergy/SourceEnergy_Pull+Up+Banner-2.png'),
    width:'calc(30% - 30px)',
  },
  {
    id: 2,
    image: cdn('/Case_studies/SourceEnergy/SourceEnergy_website+page+animation.png'),
    width:'calc(60% - 30px)',
    borderRadius: '32px',
    className:'special'
  },
];


export default class SourceEnergy extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="SourceEnergy-wrapper">
      <MetaTags>
        <title>Source Energy - Energy provider of choice - OKMG</title>
        <meta name="description" content="SourceEnergy - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/source-energy'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Source Energy" role="heading"  
        sub_content="Strategy | Digital | Creative | Marketing"
        service_info="A Western Australian-based company dedicated to providing cleaner, innovative and more affordable energy solutions."
        // image={cdn('/Case_studies/SourceEnergy/Source+Energy_iMac_Mockup.png')}
        hover_video_one={cdn('/Case_studies/SourceEnergy/source-rz-min.mp4')}
        hover_videobg_one={cdn('/websiteScrolls/iMac_Mockup_v2.png')}

        foot_image={cdn('/Case_studies/SourceEnergy/Battery.gif')}
        className="special"
        image_class="header-image"
        foot_image_width="250px"
        foot_image_left="0px"

        />
         <ComponentCaseCopy
        content={
          <div>
            <p>Source Energy is pioneering the use of grid energy in Australia, installing solar panels and batteries into an Integrated Energy Network, producing cleaner and more sustainable energy.</p>
            <p>There has never been a more imperative time to make a radical shift towards renewable energy, ensuring it is both accessible and affordable. After strategically developing the revised user journey of the existing Source Energy website, we determined the most appropriate way to engage with Source Energy’s audience in a direct, meaningful way. </p>
            <p>Distilling the high-level technology and processes of Source Energy into more concise educational pieces, through videos and interactive infographics, OKMG facilitated a more fluid user journey experience with a clear call to action. The refreshed digital presence gave Source Energy the platform to accurately reflect their progressive company purpose, to confidently lead the industry forward.</p>
          </div>}
          // foot_image={cdn('/Case_studies/SourceEnergy/Light.gif')}

        />

       {/* <ComponentVideo
          video_url={cdn('/Case_studies/SourceEnergy/THE-COVE-COMPLETION-VIDEO-V4-V4-1-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
          
         
        */}
        <ComponentVideo
          video_url={cdn('/Case_studies/SourceEnergy/OKMG_SOURCEENERGY-CASE-STUDY_FINAL-web-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
        <ComponentFullBleedImage
          image={cdn('/Case_studies/SourceEnergy/source-island-v2.png')}
          width="70%"
          marginTop="0px"
          marginTop="100px"
          />
        <ComponentVideo
          video_url={cdn('/Case_studies/SourceEnergy/source-energy-final.min-min.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />
        {/*
          <ComponentVideo
            video_url={cdn('/Case_studies/SourceEnergy/source-screen-cast-min.mp4')}
            autoplay="autoplay"
            loop="loop"
            marginTop="100px"
            screenCast
          />

        */} 
          
         
          <ComponentFullBleedImage
          image={cdn('/Case_studies/SourceEnergy/[sourceenergyco.com.au][934]Park_Avenue-min.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="100px"
          />

  
          <ComponentCaseImages
            className="footer-images"
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}