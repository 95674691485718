/*
  HomePage.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import {Parallax, ParallaxLayer} from 'react-spring/renderprops-addons';
import Plx from 'react-plx'; 
import './HomePage.scss';
import MetaTags from 'react-meta-tags';
// components
import ComponentHomeCaseStudies from './../ComponentHomeCaseStudies';
import ComponentFooter from '../../Components/ComponentFooter';
import ComponentHeader from './../ComponentHeader';
import ComponentInnerPageHeader from './../ComponentInnerPageHeader';
import ComponentCtaFooter from '../../Components/ComponentCtaFooter';
import ComponentServicesBox from '../../Components/ComponentServicesBox';
import ComponentInternalBigLink from '../../Components/ComponentInternalBigLink';
import ComponentInternalFullWidthLink from './../ComponentInternalFullWidthLink';
import ComponentReviewSlider from '../../Components/ComponentReviewSlider';

// images

import cdn from '../../lib/cdn';



const parallaxDataHeader = [
  {
    start: 0,
    end: 500,
    properties: [
    {
        startValue: 1,
        endValue: 0.1,
        property: "opacity"
      },

    ],
  },
]

const parallaxCTA = [
   {
    start: 500,
    end: 1500,
    properties: [
    {
        startValue: 0,
        endValue: -50,
        property: "translateY"
      },
      
    ],
  },
];

// okmg acess signatures
const okmg_signatures = [
    {
      name: "OKMG: Access Signature",
      link: "https://apps.okmg.com/signatures"
    },
     {
      name: "OKMG Christmas: Access Signature",
      link: "https://apps.okmg.com/signature-christmas/"
    },
    {
      name: "HIVO: Access Signature",
      link: "https://apps.okmg.com/hivo-signatures/"
    },
     {
      name: "More Alive: Access Signature",
      link: "https://apps.okmg.com/client_signatures/morealive/"
    }
];

// okmg active client signatures
const active_client_signatures = [
    {
      name: "Chase Residential: Access Signature",
      link: "https://apps.okmg.com/client_signatures/chase_residential"
    },
    {
      name: "Capital Property Advisory: Access Signature",
      link: "https://apps.okmg.com/client_signatures/cpa2/"
    },
    {
      name: "Dancing Ledge: Access Signature",
      link: "https://apps.okmg.com/client_signatures/dancingledge/"
    },
    {
      name: "Fortified: Access Signature",
      link: "https://apps.okmg.com/fortifiedsupport/"
    },
    {
      name: "Heirloom Honey Co: Access Signature",
      link: "https://apps.okmg.com/client_signatures/heirloomhoney/"
    },
    {
      name: "Pilbara Minerals: Access Signature",
      link: "https://apps.okmg.com/client_signatures/pls"
    },
    {
      name: "PYC: Access Signature",
      link: "https://pyctx.com/signatures/"
    },
    {
      name: "Medelife: Access Signature",
      link: "https://apps.okmg.com/client_signatures/medelife/"
    },
    {
      name: "Ron Farris Real Estate: Access Signature",
      link: "https://apps.okmg.com/client_signatures/ronfarris/"
    },
    {
      name: "Spot on Group: Access Signature",
      link: "https://apps.okmg.com/spotongroup/"
    },
];

const inctive_client_signatures = [
   {
      name: "Majime: Access Signature",
      link: "https://apps.okmg.com/client_signatures/majime/"
    },
    {
      name: "Distinguished Homes: Access Signature",
      link: "https://apps.okmg.com/client_signatures/distinguished_homes/"
    },
    {
      name: "Femma: Access Signature",
      link: "https://apps.okmg.com/client_signatures/femma/"
    },
    {
      name: "Winthrop Gardens IGA: Access Signature",
      link: "https://apps.okmg.com/client_signatures/winthrop/"
    },
    {
      name: "Megara: Access Signature",
      link: "https://apps.okmg.com/client_signatures/megara/"
    },
];

export default class HomePage extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      active_one: false,
   
    };

    

  }


  componentDidMount() {
    window.scrollTo(0, 0);
    //
      fetch('/assets/animations/blob_home.json')
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          animationDataHome: data
        })
      })
  }


  render(){


   

    return (

      <div className="home-page">
         <MetaTags>
          <title>Brand Strategy & Digital Marketing Agency - Perth, Melbourne</title>
          {/* <meta name="description" content="OKMG is a full service agency obsessed with crafting big ideas; driving impact to change the game." /> */}
          <meta name="description" content="OKMG is a next generation content marketing agency that combines strategic thinking with high impact creative execution and integrated digital solutions." />
          <meta name="keywords" content="digital marketing agency"/>
          <link rel='canonical' href='https://client-signatures.okmg.com'/>
        </MetaTags>
        <Plx
            className='paralax-case'
            parallaxData={ parallaxDataHeader }
          >
             
              <ComponentInnerPageHeader
              heading="Email Signatures."
              blob_color="rgb(235, 189, 29)"
              />
              
        </Plx>
        <Plx parallaxData={parallaxCTA}
        >
         <div className="content-wrapper" style={{textAlign:"left"}}>
          <h2>Centralised Access for Seamless Branding</h2>
          <p>This page serves as a central repository for all custom email signatures we've developed for our clients, powered by mesig.io. Navigate through the list to access, or review any client's email signature. For any updates or issues related to these email signatures, please contact <a href="mailto:support@okmg.com">support@okmg.com</a></p>
          <h2>Group Businesses</h2>
          <p>Below are the email signatures we've created for our OKMG group of businesses. Click on the business name to access the custom mesig.io email signature generator.</p>
          <ul className="signature-client-list internal-list">
           {okmg_signatures.map((signature, index) => (
              <li key={index}>
                <a href={signature.link} target="_blank" rel="noopener noreferrer">
                  {signature.name}
                </a>
              </li>
            ))}
          </ul>
          <h2>Active Clients</h2>
          <p>Below are the email signatures we've created for our active clients. Click on the client name to access their custom mesig.io email signature generator.</p>
          <ul className="signature-client-list">
            {active_client_signatures.map((signature, index) => (
              <li key={index}>
                <a href={signature.link} target="_blank" rel="noopener noreferrer">
                  {signature.name}
                </a>
              </li>
            ))}
          </ul>

          <h2>Inactive Clients</h2>
          <p>Below are the email signatures we've created for our past or inactive clients. Click on the client name to access their custom mesig.io email signature generator.</p>
          <ul className="signature-client-list">
            {inctive_client_signatures.map((signature, index) => (
              <li key={index}>
                <a href={signature.link} target="_blank" rel="noopener noreferrer">
                  {signature.name}
                </a>
              </li>
            ))}
          </ul>
         </div>
         </Plx>
         
          <ComponentCtaFooter
            heading="Can't see a position?"
            content=""
            blob_color="#43b7e9"
          />
        <Plx
          className='paralax-cta'
          parallaxData={ parallaxCTA }
        >
       
        </Plx>
      </div>
      );
    }
}