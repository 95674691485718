/*
  DDC.jsx
  @Component - Edith Cowan College Case Study
*/

import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import './DDC.scss';
import BlobOne from '../../blobOne';
import MetaTags from 'react-meta-tags';
import ComponentHeaderbanner from '../_CaseComponents/ComponentHeaderBanner';
import ComponentFullBleedImage from '../_CaseComponents/ComponentFullBleedImage';
import ComponentCaseCopy from '../_CaseComponents/ComponentCaseCopy';
import ComponentCtaFooter from '../../ComponentCtaFooter';
import ComponentVideo from '../_CaseComponents/ComponentVideo';
import ComponentCaseStats from '../_CaseComponents/ComponentCaseStats';
import ComponentCaseImages from '../_CaseComponents/ComponentCaseImages';
import ComponentCaseNav from '../_CaseComponents/ComponentCaseNav';
import ComponentReadMore from '../_CaseComponents/ComponentReadMore';
import ComponentCaseImageGifs from '../_CaseComponents/ComponentCaseImageGifs';
import cdn from '../../../lib/cdn';

// case studies reference for nav
import CaseStudies from '../../../CaseStudies/case_studies.js';
import { canUseDOM } from 'react-helmet';

// for images
const images = [
   {
    id: 1,
    image: cdn('/Case_studies/DDC/DDC-4-web.jpg'),
    background_size: 'cover',
  },
  {
    id: 2,
    image: cdn('/Case_studies/DDC/DDC-5-web.jpg'),
    background_repeat: 'no-repeat',
    background_size: 'cover',
  },
  {
    id: 3,
    image: cdn('/Case_studies/DDC/DDC-7-web.jpg'),
    background_size: 'cover',
  }

]


const images_two = [
   {
    id: 1,
    image: cdn('/Case_studies/DDC/58animateddisplays.svg'),
    // width:'30%',
    className:'img-svg',
  },
  {
    id: 2,
    image: cdn('/Case_studies/DDC/documentation.jpg'),
    // width:'33%',
    borderRadius: '32px',
    className:'img',

  },
  {
    id: 3,
    image: cdn('/Case_studies/DDC/40minimmersiveaudio.svg'),
    className:'img-svg',
  },
  {
    id: 4,
    image: cdn('/Case_studies/DDC/2018.12.20 - DDC (2 of 14)-v2.jpg'),
    borderRadius: '32px',
    className:'img',

  },
  {
    id: 5,
    image: cdn('/Case_studies/DDC/13Audio visualexhibits.svg'),
    borderRadius: '32px',
    className:'img-svg',
  },
  {
    id: 6,
    image: cdn('/Case_studies/DDC/DDC-7-web.jpg'),
    borderRadius: '32px',
    className:'img',
  },

];

const images_three = [
  {
    id: 1,
    image: cdn('/Case_studies/DDC/DDC_LOGO-1-1-300x80.png'),
    className:'img-logo',
  },
  {
    id: 2,
    image: cdn('/Case_studies/DDC/DDC_LOGO-2-300x154.png'),
    className:'img-logo',
  },
  {
    id: 3,
    image: cdn('/Case_studies/DDC/DDC_LOGO0-3-300x139.png'),
    className:'img-logo',
  },
];

const stat_info = [
  
  
  {
    id: 1,
    amount: '"The Buzz has become a favourite among staff and students and this is thanks to the creativity of the OKMG team. They are very focused on the client and really take the time to understand the business/industry that we\'re in, to ensure the content is relevant and successful. I really enjoy working with them as they are very approachable and flexible!"',
    text: 'Sophie Waddell, Marketing Manager - South East Asia',
    color: 'rgb(10 78 132)',
  },
  {
    id:2,
    image_left:cdn('/Case_studies/DDC/DDC_PHONE MOCKUP.png'),
    has_image: true,
    image_width:'460px',
    color: 'rgb(10 78 132)',
  },

];

export default class DDC extends React.Component {


    constructor(props) {
    super(props);

    // State.

    this.state = {
    
      
   
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(){

    const {
      prevStudy,
      nextStudy
    } = this.props;


  return (
      <div className="DDC-wrapper">
      <MetaTags>
        <title>Dolphin Discovery Centre - Australia's Premier Wild Dolphin Experience  - OKMG</title>
        <meta name="description" content="Dolphin Discovery Centre - Brand strategy, ongoing marketing, communications and engagement development across all digital channels" />
        <link rel='canonical' href='https://okmg.com/work/dolphin-discovery-centre'/>
      </MetaTags>

        <ComponentHeaderbanner
        heading="Dolphin Discovery Centre" role="heading"  
        sub_content="Strategy | Creative | Digital"
        service_info="Located in Bunbury, Western Australia, the Dolphin Discovery Centre breathes new life into the historic marine conservation and tourist centre. OKMG furthered this cultural experience by creating a completely immersive audio and visual environment."
        hover_image_two={cdn('/Case_studies/DDC/hover-3.jpg')}
        hover_image_one={cdn('/Case_studies/DDC/hover-4.jpg')}
        hover_image_three={cdn('/Case_studies/DDC/hover-5.jpg')}
        image_width="100%"
        className="special"
        image_class="header-image"
        foot_image={cdn('/Case_studies/DDC/bg-l-light.svg')}
        foot_image_width="350px"
        foot_image={cdn('/Case_studies/DDC/bg-l-light.svg')}
        />
         <ComponentCaseCopy
        content={
          <div>
            <p>Aiming to protect local dolphins and the surrounding marine environment through plunging visitors into an underwater experience, the Dolphin Discovery Centre is one of Western Australia's premier tourist attractions.</p>
            <p>Following the acquisition of a significant State Government grant, the DDC contracted Perkins Builders to complete a multimillion-dollar renovation and expansion of the centre, complete with a cafe, restaurant, function space and a multi-media Interpretive Centre.</p>
            <p>OKMG were asked to design and create audio and visual elements for the Interpretive Centre, which includes interactive exhibits, 15 marine aquariums, a touch pool and a theatrette. Through community engagement and collaboration an experience unlike any other was curated to be enjoyed by visitors of all ages.</p>
             <ComponentReadMore
             content={
              <span>
                <p>This fully immersive Interpretative Centre needed to educate and inspire local and international visitors to protect local dolphins and the surrounding marine environment. </p>
                <p>After an extensive research and discovery phase of exploring the diversity of the Dolphin Discovery Centre’s audience, marine research, the technological capabilities of the centre and the history of the Bunbury region, each recognised as necessary components in developing this fully immersive experience.</p>
                <p>Ensuring that each aspect and audience was accounted for during this project through facilitated workshops, consultations and by collaborating closely with the local community, specifically taking into consideration the Aboriginal community and Noongar Elders to maintain a high level of integrity and respect to the land throughout the project.</p>
                <p>OKMG produced and recorded a unique and varied library of content from the Bunbury region to create a distinctly local experience for the AV exhibits. Audio recordings from the Bunbury region were incorporated along with existing resources from the Dolphin Discovery Centre and Murdoch University's Cetacean Research Unit.</p>
                <p>From this campaign we were able to create a fully immersive atmospheric sound experience unlike any other, taking audiences on a journey into the unique ecosystem of Koombana Bay.</p>
              </span>
            }
             />
          </div>}
        />

        <ComponentVideo
          video_url={cdn('/Case_studies/DDC/DDC-final.min3.mp4')}
          autoplay="autoplay"
          loop="loop"
          marginTop="100px"
          />

          <ComponentCaseImageGifs 
          stats={images_two}
          marginTop="100px"
          special_class_wrapper="special-wrapper"
          special_image={cdn('/Case_studies/DDC/bg-tl-dark-resize.svg')}
          />

          {/* <ComponentCaseStats
            stats={stat_info}
            has_image='true'
          /> */}
          
           <ComponentFullBleedImage
          image={cdn('/Case_studies/DDC/DDC_infographic.png')}
          marginTop="0px"
          marginTop="200px"
          className="infographic"
          special_class_wrapper="special-wrapper2"
          special_image={cdn('/Case_studies/DDC/bg-l-light2.svg')}
          />

          <div class="logo-section">
          <ComponentCaseImageGifs 
          stats={images_three}
          marginTop="200px"
          />
          </div>

           <ComponentFullBleedImage
          image={cdn('/Case_studies/DDC/2018.12.20 - DDC (5 of 14)-web-v2.jpg')}
          width="100%"
          marginTop="0px"
          marginTop="200px"
          />
  
          <ComponentCaseImages
            stats={images}
          />
          <ComponentCaseNav
          match="pilbara-minerals"
          />
     </div>
    );
  }
}